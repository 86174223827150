import * as React from 'react'
import { mvs } from 'react-native-size-matters'
import Svg, { Circle, Path, Ellipse, G, SvgProps } from 'react-native-svg'

type Props = SvgProps

function IconTeacherLarge(props: Props): JSX.Element {
  return (
    <Svg width={mvs(80, 1.1)} height={mvs(80, 1.1)} viewBox="0 0 130 130">
      <Circle data-name="Ellipse 409" cx={65} cy={65} r={65} fill="#9c99b0" />
      <Circle
        data-name="Ellipse 410"
        cx={63.796}
        cy={63.796}
        r={63.796}
        transform="translate(1.204 1.204)"
        fill="#444155"
      />
      <Circle
        data-name="Ellipse 411"
        cx={60.579}
        cy={60.579}
        r={60.579}
        transform="translate(4.421 4.421)"
        fill="#c7c5d2"
      />
      <Circle
        data-name="Ellipse 412"
        cx={60.579}
        cy={60.579}
        r={60.579}
        transform="translate(4.421 4.421)"
        fill="#c7c5d2"
      />
      <Path
        data-name="Path 944"
        d="M33.094 16.411l-5.084.742c-.551 0 .855-.866 1.3-1.192a60.989 60.989 0 0168.75-1.811c.4.259 1.665 1.125 1.665 1.125l-4.668-.36C80.347 4.421 50.1 2.048 33.094 16.411z"
        fill="#e3e3e5"
      />
      <Path
        data-name="Path 945"
        d="M56.824 5.523a59.684 59.684 0 018.27-.54 58.818 58.818 0 017.559.495"
        fill="none"
        stroke="#efeff4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        data-name="Path 946"
        d="M113.767 28.629a61.083 61.083 0 01-14.052 84.838 60.142 60.142 0 01-84.263-14.151z"
        fill="#b3b2bf"
      />
      <Path
        data-name="Path 947"
        d="M84.268 103.641l-.776 3.1a.146.146 0 01-.281 0l-.776-3.1-3.1-.787a.135.135 0 110-.27l3.1-.787.787-3.1a.135.135 0 01.27 0l.787 3.1 3.1.787a.135.135 0 110 .27z"
        fill="#fff"
      />
      <G data-name="Group 214">
        <Path
          data-name="Path 948"
          d="M80.805 100.351a.127.127 0 01.18-.18l1.125.889-.09.326-.315.079zm1.215 3.723l.079.315-1.125.889a.127.127 0 11-.18-.18l.889-1.125zm2.688-2.7l-.09-.315 1.125-.889a.127.127 0 01.18.18l-.889 1.125zm1.2 3.723a.127.127 0 11-.18.18l-1.125-.889.09-.326.315-.079z"
          fill="#fff"
          opacity={0.44}
        />
      </G>
      <Path
        data-name="Path 949"
        d="M77.682 98.904l-.45 1.8a.079.079 0 01-.157 0l-.45-1.8-1.788-.442a.09.09 0 010-.169l1.788-.45.45-1.788c0-.079.146-.079.157 0l.45 1.788 1.788.45a.09.09 0 010 .169z"
        fill="#fff"
      />
      <G data-name="Group 215">
        <Path
          data-name="Path 950"
          d="M75.682 97.019c0-.067 0-.157.112-.1l.641.506v.191h-.18zm.709 2.137v.191l-.641.506a.072.072 0 01-.1-.1l.529-.641zm1.541-1.541v-.191l.641-.506a.072.072 0 01.1.1l-.506.641zm.7 2.137a.072.072 0 01-.1.1l-.641-.506v-.191h.191z"
          fill="#fff"
          opacity={0.44}
        />
      </G>
      <Path
        data-name="Rectangle 1498"
        fill="#fff"
        opacity={0.5}
        d="M50.93 43.288h49.381V84.75H50.93z"
      />
      <Path
        data-name="Path 951"
        d="M102.93 44.01v39.831a3.622 3.622 0 01-3.622 3.611H66.485a10.877 10.877 0 01.236 2.115v4.229a4.5 4.5 0 01-4.5 4.5H35.034a4.5 4.5 0 01-4.5-4.5v-4.219a10.81 10.81 0 011.821-6.063 10.382 10.382 0 018.7-4.421c3.161 0 3.948 1.125 7.559 1.125s4.421-1.125 7.559-1.125a10.416 10.416 0 018.706 4.421c.067.112.124.225.191.326h34.24V44.01H52.233v11.53a10.753 10.753 0 11-3.622-.709V44.01a3.622 3.622 0 013.622-3.622h47.075a3.622 3.622 0 013.622 3.622zM73.976 83.841h14.479"
        fill="#444155"
      />
      <Path
        data-name="Path 952"
        d="M65.697 50.145h2.891l3.375 10.652H69.34l-1.372-5.4c-.27-1.057-.6-2.25-.844-3.375h-.067a100.331 100.331 0 01-.832 3.375l-1.384 5.4h-2.464zm-1.125 6.243h5.1v1.923h-5.1z"
        fill="#444155"
      />
      <Path
        data-name="Path 953"
        d="M73.181 50.145h3.588c2.25 0 3.9.607 3.9 2.643a2.34 2.34 0 01-1.822 2.25v.067a2.432 2.432 0 012.317 2.508c0 2.16-1.777 3.138-4.094 3.138h-3.889zm3.476 4.274c1.125 0 1.654-.495 1.654-1.294s-.518-1.12-1.632-1.12h-1.125v2.4zm.214 4.5c1.316 0 1.946-.472 1.946-1.451s-.619-1.316-1.946-1.316h-1.283v2.767z"
        fill="#444155"
      />
      <Path
        data-name="Path 954"
        d="M82.615 55.533c0-3.532 2.25-5.624 5.084-5.624a4.5 4.5 0 013.172 1.35l-1.26 1.541a2.632 2.632 0 00-1.856-.765c-1.507 0-2.621 1.282-2.621 3.375s1.125 3.465 2.711 3.465a2.553 2.553 0 001.923-.945l1.271 1.507a4.409 4.409 0 01-3.5 1.564c-2.641-.012-4.924-1.88-4.924-5.468z"
        fill="#444155"
      />
      <Path
        data-name="Path 955"
        d="M43.27 36.241l-1.215 4.882a.225.225 0 01-.439 0l-1.214-4.882-4.871-1.215a.225.225 0 010-.439l4.871-1.215 1.226-4.874a.214.214 0 01.427 0l1.226 4.871 4.871 1.215a.225.225 0 010 .439z"
        fill="#fff"
      />
      <G data-name="Group 216">
        <Path
          data-name="Path 956"
          d="M37.832 31.081a.2.2 0 01.281-.281l1.744 1.395-.124.506-.506.124zm1.9 5.827l.124.506-1.71 1.395a.2.2 0 01-.281-.281l1.406-1.743zm4.207-4.207l-.169-.506 1.744-1.395a.2.2 0 01.281.281l-1.35 1.744zm1.9 5.815a.203.203 0 11-.281.292l-1.788-1.395.124-.506.506-.124z"
          fill="#fff"
          opacity={0.44}
        />
      </G>
      <Path
        data-name="Path 957"
        d="M96.226 114.038l5.624-1.192c.562 0-1.384 1.372-1.833 1.7a61.011 61.011 0 01-68.773.81c-.4-.27-2-1.035-1.53-1.125l4.5.439c10.011 9.964 44.802 13.474 62.012-.632z"
        fill="#afafc6"
      />
      <Circle
        data-name="Ellipse 413"
        cx={50.096}
        cy={50.096}
        r={50.096}
        transform="translate(14.904 14.904)"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={0.92}
      />
      <Circle
        data-name="Ellipse 414"
        cx={54.483}
        cy={54.483}
        r={54.483}
        transform="translate(10.517 10.517)"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
      />
    </Svg>
  )
}

export default IconTeacherLarge
