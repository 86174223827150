import { auth } from '~providers/firebase'
import { clientUnauthenticated as axios } from '~utils/fetch'

export default async function onGoogleButtonPress(idToken: string): Promise<void> {
  try {
    // Send ID token to api
    const res = await axios({
      url: '/api/v1/login/google',
      method: 'POST',
      data: {
        token: idToken,
      },
    })

    if (res.status === 200) {
      await auth().signInWithCustomToken(res.data.token)
    }
  } catch (error) {
    console.log(error)
  }
}
