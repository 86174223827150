import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { CompositeNavigationProp, RouteProp } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { AnyAction } from '@reduxjs/toolkit'
import React from 'react'
import { initialState, Provider, reducer, State } from '~context/ClassTabsContext'
import ClassRepliesScreen from '~screens/ClassRepliesScreen'
import ClassScreen from '~screens/ClassScreen'
import { ClassesStackParamList, RootStackParamList } from '~types'

const Navigator = createMaterialTopTabNavigator()

type Props = {
  navigation: CompositeNavigationProp<
    StackNavigationProp<RootStackParamList>,
    StackNavigationProp<ClassesStackParamList, 'Class'>
  >
  route: RouteProp<ClassesStackParamList, 'Class'>
}

export default function ClassTabsNavigator({ route }: Props): JSX.Element {
  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState,
    classId: route.params.id,
    name: route.params.name ?? '',
  })
  const value: [State, React.Dispatch<AnyAction>] = [state, dispatch]

  return (
    <Provider value={value}>
      <Navigator.Navigator screenOptions={{ lazy: true, swipeEnabled: true }}>
        <Navigator.Screen
          name="ClassChats"
          component={ClassScreen}
          options={{ title: 'Class Chats' }}
        />
        <Navigator.Screen
          name="ClassChatsReplies"
          component={ClassRepliesScreen}
          options={{ title: 'My Replies' }}
        />
      </Navigator.Navigator>
    </Provider>
  )
}
