import { CompositeNavigationProp, RouteProp } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { AnyAction, createAction, createReducer } from '@reduxjs/toolkit'
import moment from 'moment'
import React from 'react'
import { TouchableOpacity } from 'react-native'
import { ScaledSheet } from 'react-native-size-matters'
import { useDispatch, useSelector } from 'react-redux'
import {
  ChatLayoutOneByOne,
  ChatLayoutOneByThree,
  ChatLayoutOneByTwo,
  ChatLayoutTwoByThree,
  ChatLayoutTwoByTwo,
} from '~components/ChatLayoutImages'
import { FormButton } from '~components/FormButton'
import { FormInput } from '~components/FormInput'
import ModalScreen, { getModalStyleDefinition, ModalFooter } from '~components/ModalScreen'
import Progress from '~components/Progress'
import { Text, View } from '~components/Themed'
import { EVENT_CHAT_CREATED } from '~constants/Analytics'
import { analytics } from '~providers/firebase'
import { ApplicationState } from '~redux'
import { setChat } from '~redux/chat/actions'
import ChatService from '~services/chat'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import {
  ChatLayoutOption,
  ChatTemplate,
  CreateChatType,
  MainStackParamList,
  RootStackParamList,
} from '~types'

type Props = {
  navigation: CompositeNavigationProp<
    StackNavigationProp<RootStackParamList, 'NewChatModal'>,
    StackNavigationProp<MainStackParamList>
  >
  route: RouteProp<RootStackParamList, 'NewChatModal'>
}

type ViewType = 'details' | 'layout'

export interface State {
  view: ViewType
  loading: boolean
  name: string
  classId: string
  grade: string
  layout?: { rows: number; columns: number }
  template?: ChatTemplate
}

export const initialState: State = {
  view: 'details',
  loading: false,
  name: '',
  classId: '',
  grade: '',
}

export const setView = createAction<ViewType, 'setView'>('setView')
export const setLoading = createAction<boolean, 'setLoading'>('setLoading')
export const setName = createAction<string, 'setName'>('setName')
export const setLayout = createAction<ChatLayoutOption, 'setLayout'>('setLayout')
export const setTemplate = createAction<ChatTemplate | undefined>('setTemplate')
export const setClassId = createAction<string, 'setClassId'>('setClassId')

const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setView, (state, action) => {
      state.view = action.payload
    })
    .addCase(setLoading, (state, action) => {
      state.loading = action.payload
    })
    .addCase(setName, (state, action) => {
      state.name = action.payload
    })
    .addCase(setLayout, (state, action) => {
      state.layout = {
        rows: action.payload.rows,
        columns: action.payload.columns,
      }
    })
    .addCase(setTemplate, (state, action) => {
      state.template = action.payload
    }),
)
const store = React.createContext<[State, React.Dispatch<AnyAction>]>([initialState, () => {}])
const { Provider } = store
const useNewChatContext = (): [State, React.Dispatch<AnyAction>] => React.useContext(store)

const progress = (step: number) => <Progress labels={['Details', 'Layout']} step={step} />

type ChildProps = { create: () => Promise<void> }

export default function NewChatScreen({ navigation, route: { params } }: Props): JSX.Element {
  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState,
    classId: params?.classId ?? '',
    template: params?.template,
  })
  const value: [State, React.Dispatch<AnyAction>] = [state, dispatch]
  const globalDispatch = useDispatch()

  const { user, profile } = useSelector((state: ApplicationState) => state.auth)
  const create = async () => {
    if (!user || !state.classId || !(state.layout || state.template)) {
      return
    }

    dispatch(setLoading(true))

    const props: CreateChatType = {
      name: state.name.length > 0 ? state.name : moment().format('MMMM Do YYYY, h:mm:ss a'),
      level: '',
      layout: state.template?.layout ?? state.layout ?? { rows: 1, columns: 1 },
      sections: state.template?.sections,
      author_id: user.claims.sub,
      author_name: profile.displayName,
      template_id: state.template?.id,
    }

    const chat = await ChatService.createChat(`classes/${state.classId}`, props)
    await analytics().logEvent(EVENT_CHAT_CREATED)
    globalDispatch(setChat(chat))
    dispatch(setLoading(false))
    navigation.navigate('Main', {
      screen: 'Classes',
      params: {
        screen: 'ChatScreen',
        initial: false,
        params: {
          path: chat.path,
          role: 'teacher',
        },
      },
    })
  }

  return (
    <ModalScreen>
      <Provider value={value}>
        {state.view === 'details' ? <Details create={create} /> : <Layout create={create} />}
      </Provider>
    </ModalScreen>
  )
}

const Details = ({ create }: ChildProps) => {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const [state, stateDispatch] = useNewChatContext()

  const next = async () => {
    if (state.template) {
      await create()
    } else {
      stateDispatch(setView('layout'))
    }
  }

  return (
    <View style={styles.inner}>
      <View style={styles.content}>
        {!state.template && progress(0)}

        <View style={styles.titleContainer}>
          <Text style={styles.title}>New Chat</Text>
          <Text style={styles.subtitle}>A few details to start</Text>
        </View>

        <View>
          <FormInput
            label="Name of chat"
            value={state.name}
            onChangeText={(text) => stateDispatch(setName(text))}
            maxLength={128}
          />
          <FormInput
            label="Add a grade level to the chat?"
            value={state.grade}
            editable={false}
            maxLength={128}
          />
        </View>
      </View>

      <ModalFooter>
        <View></View>
        <FormButton onPress={next} title={'Continue'} style={styles.button} />
      </ModalFooter>
    </View>
  )
}

const Layout = ({ create }: ChildProps) => {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const [state, stateDispatch] = useNewChatContext()

  const back = () => stateDispatch(setView('details'))
  const layout = (layout: ChatLayoutOption) => stateDispatch(setLayout(layout))

  const layouts: ChatLayoutOption[][] = [
    [
      { rows: 1, columns: 1, image: <ChatLayoutOneByOne /> },
      { rows: 1, columns: 2, image: <ChatLayoutOneByTwo /> },
      { rows: 1, columns: 3, image: <ChatLayoutOneByThree /> },
    ],
    [
      { rows: 2, columns: 2, image: <ChatLayoutTwoByTwo /> },
      { rows: 2, columns: 3, image: <ChatLayoutTwoByThree /> },
    ],
  ]

  return (
    <View style={styles.inner}>
      <View style={styles.content}>
        {progress(1)}

        <View style={styles.titleContainer}>
          <Text style={styles.title}>Blank layout</Text>
          <Text style={styles.subtitle}>Choose from one of the layouts below</Text>
        </View>

        {layouts.map((items, row) => (
          <View style={styles.layoutsContainer} key={`layout-row-${row}`}>
            {items.map((item, index) => (
              <TouchableOpacity onPress={() => layout(item)} key={`layout-${row}-${index}`}>
                <View
                  style={[
                    styles.listItem,
                    item.rows === state.layout?.rows &&
                      item.columns === state.layout.columns &&
                      styles.listItemSelected,
                  ]}>
                  {item.image}
                </View>
              </TouchableOpacity>
            ))}
          </View>
        ))}
      </View>

      <ModalFooter>
        <FormButton
          onPress={back}
          title={'Back'}
          style={styles.backButton}
          textStyle={styles.backButtonText}
          disabled={state.loading}
        />
        <FormButton
          onPress={create}
          title={'Create Chat'}
          style={styles.button}
          disabled={state.layout === undefined || state.loading}
        />
      </ModalFooter>
    </View>
  )
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    ...getModalStyleDefinition(theme),
    listItem: {
      marginVertical: '5@mvs',
      padding: '5@ms',
      backgroundColor: theme.white,
    },
    listItemSelected: {
      backgroundColor: theme.pink[200],
    },
    layoutsContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
  })
