import * as React from 'react'
import { mvs } from 'react-native-size-matters'
import Svg, { Circle, Path, Ellipse, G, SvgProps } from 'react-native-svg'

type Props = SvgProps

function IconJoinClassLarge(props: Props): JSX.Element {
  return (
    <Svg
      data-name="Icon_Join a class"
      width={mvs(90, 0.8)}
      height={mvs(90, 0.8)}
      viewBox="0 0 124.9 124.9"
      {...props}>
      <Circle data-name="Ellipse 222" cx={62.45} cy={62.45} fill="#9c99b0" r={62.45} />
      <Circle
        data-name="Ellipse 223"
        cx={61.3}
        cy={61.3}
        transform="translate(1.15 1.15)"
        fill="#444155"
        r={61.3}
      />
      <Circle
        data-name="Ellipse 224"
        cx={58.227}
        cy={58.227}
        transform="translate(4.223 4.223)"
        fill="#c7c5d2"
        r={58.227}
      />
      <Circle
        data-name="Ellipse 225"
        cx={58.227}
        cy={58.227}
        transform="translate(4.223 4.223)"
        fill="#c7c5d2"
        r={58.227}
      />
      <Path
        data-name="Path 561"
        d="M31.956 15.677l-4.857.709c-.527 0 .817-.827 1.246-1.139a58.259 58.259 0 0165.674-1.734c.387.247 1.59 1.074 1.59 1.074l-4.459-.344C77.091 4.223 48.203 1.956 31.956 15.677z"
        fill="#d8d7dd"
      />
      <Path
        data-name="Path 562"
        d="M54.638 5.276a57.013 57.013 0 017.9-.516 56.186 56.186 0 017.221.473"
        fill="none"
        stroke="#efeff4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path data-name="Path 563" d="M109.319 28.066A58.1 58.1 0 1114.76 95.59z" fill="#b3b2bf" />
      <Path
        data-name="Path 564"
        d="M92.289 109.653l5.373-1.139c.537 0-1.322 1.311-1.751 1.623a58.281 58.281 0 01-65.7.774c-.387-.258-1.913-.989-1.461-1.075l4.3.419c9.565 9.52 42.799 12.872 59.239-.602z"
        fill="#8d8ba0"
      />
      <Ellipse
        data-name="Ellipse 226"
        cx={52.403}
        cy={52.404}
        rx={52.403}
        ry={52.404}
        transform="translate(10.047 10.047)"
        fill="none"
        stroke="#dcdbe3"
        strokeMiterlimit={10}
      />
      <Circle
        data-name="Ellipse 227"
        cx={48.213}
        cy={48.213}
        transform="translate(14.237 14.237)"
        fill="none"
        stroke="#dcdbe3"
        strokeMiterlimit={10}
        strokeWidth={0.92}
        r={48.213}
      />
      <Path
        data-name="Path 565"
        d="M94.599 38.317l-1.16 4.653a.215.215 0 01-.419 0l-1.16-4.653-4.7-1.16a.215.215 0 010-.419l4.7-1.15 1.16-4.663a.215.215 0 01.419 0l1.16 4.663 4.663 1.16a.215.215 0 010 .419z"
        fill="#fff"
      />
      <G data-name="Group 124">
        <Path
          data-name="Path 566"
          d="M89.398 33.438a.194.194 0 01.279-.269l1.666 1.343-.129.484-.473.118zm1.827 5.566l.118.473-1.666 1.335a.193.193 0 01-.269-.269l1.322-1.73zm4.019-4.029l-.13-.463 1.719-1.386a.194.194 0 11.279.269l-1.354 1.665zm1.816 5.566a.194.194 0 01-.279.269l-1.667-1.377.129-.484.516-.14z"
          fill="#fff"
          opacity={0.44}
        />
      </G>
      <Path
        data-name="Path 567"
        d="M33.724 86.143l-.741 2.976a.14.14 0 01-.269 0l-.741-2.976-2.976-.741a.14.14 0 010-.269l2.976-.741.741-2.976a.14.14 0 01.269 0l.741 2.976 2.976.741a.14.14 0 010 .269z"
        fill="#fff"
      />
      <G data-name="Group 125">
        <Path
          data-name="Path 568"
          d="M30.408 82.994c-.1-.107 0-.258.172-.172l1.075.849-.075.312-.312.075zm1.16 3.557l.075.312-1.075.849a.122.122 0 11-.172-.172l.849-1.075zm2.568-2.611l-.075-.312 1.075-.849a.122.122 0 01.172.172l-.849 1.075zm1.16 3.557a.122.122 0 11-.172.172l-1.075-.86.075-.3.312-.075z"
          fill="#fff"
          opacity={0.44}
        />
      </G>
      <Path
        data-name="Path 569"
        d="M27.4 81.598l-.408 1.708c0 .075-.14.075-.15 0l-.43-1.708-1.719-.43c-.075 0-.075-.14 0-.15l1.718-.434.43-1.708a.075.075 0 01.15 0l.43 1.708 1.708.43c.075 0 .075.129 0 .15z"
        fill="#fff"
      />
      <G data-name="Group 126">
        <Path
          data-name="Path 570"
          d="M25.483 79.782a.07.07 0 01.1-.1l.612.494v.172h-.183zm.666 2.009v.172l-.612.494c-.064 0-.15 0-.1-.107l.484-.612zm1.472-1.483v-.167l.617-.5a.07.07 0 01.1.1l-.484.612zm.666 2.042a.075.075 0 01-.1.107l-.612-.494v-.182h.183z"
          fill="#fff"
          opacity={0.44}
        />
      </G>
      <Path
        data-name="Rectangle 1484"
        fill="#fff"
        opacity={0.5}
        d="M65.77 43.474h20.244v41.852H65.77z"
      />
      <Path
        data-name="Path 571"
        d="M68.714 62.783a1.451 1.451 0 010 2.042L49.008 84.456a1.418 1.418 0 01-2.031 0l-.849-.849a1.418 1.418 0 010-2.031l15.806-15.73H28.42a1.451 1.451 0 01-1.429-1.44v-1.2a1.44 1.44 0 011.44-1.429h33.5l-15.8-15.741a1.44 1.44 0 010-2.031l.86-.853a1.429 1.429 0 012.02 0z"
        fill="#444155"
      />
      <Path
        data-name="Path 572"
        d="M82.548 86.798h-15.8a1.44 1.44 0 01-1.44-1.44v-.956a1.44 1.44 0 011.44-1.44h15.8a1.913 1.913 0 001.908-1.912V46.569a1.923 1.923 0 00-1.913-1.919H66.748a1.44 1.44 0 01-1.44-1.44v-.96a1.44 1.44 0 011.44-1.43h15.8a5.749 5.749 0 015.749 5.749V81.05a5.759 5.759 0 01-5.749 5.748z"
        fill="#444155"
      />
    </Svg>
  )
}

export default IconJoinClassLarge
