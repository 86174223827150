import { createStackNavigator, TransitionPresets } from '@react-navigation/stack'
import React from 'react'
import { Platform } from 'react-native'
import { SWRConfig, SWRConfiguration } from 'swr'
import { Fetcher } from 'swr/dist/types'
import { SearchProvider } from '~components/ChattaCommunitySearch'
import { SelectableProvider } from '~context/SelectableProvider'
import ClassSettingsScreen from '~screens/modals/ClassSettingsScreen'
import CreateClassScreen from '~screens/modals/CreateClassScreen'
import EditChatScreen from '~screens/modals/EditChatScreen'
import ImageSearchScreen from '~screens/modals/ImageSearchScreen'
import MediaSourceScreen from '~screens/modals/MediaSourceScreen'
import NewChatScreen from '~screens/modals/NewChatScreen'
import PermissionDeniedScreen from '~screens/modals/PermissionDeniedScreen'
import ReportCommunityChatScreen from '~screens/modals/ReportCommunityChatScreen'
import { RootStackParamList } from '~types'
import fetch from '~utils/fetch'
import MainNavigator from './navigators/MainNavigator'

const RootStack = createStackNavigator<RootStackParamList>()

export default function AuthenticatedStack(): JSX.Element {
  const swrConfig: Partial<SWRConfiguration<any, any, Fetcher<any>>> = {
    fetcher: fetch,
    shouldRetryOnError: false,
  }

  return (
    <SWRConfig value={swrConfig}>
      <SelectableProvider>
        <SearchProvider>
          <RootStack.Navigator
            screenOptions={{
              headerShown: false,
            }}>
            <RootStack.Screen name="Main" component={MainNavigator} />
            <RootStack.Group
              screenOptions={{
                cardOverlayEnabled: true,
                ...Platform.select({
                  android:
                    Platform.Version >= 28
                      ? TransitionPresets.RevealFromBottomAndroid
                      : TransitionPresets.FadeFromBottomAndroid,
                  default: TransitionPresets.DefaultTransition,
                }),
                cardStyleInterpolator: ({ current: { progress } }) => ({
                  cardStyle: {
                    opacity: progress.interpolate({
                      inputRange: [0, 0.5, 0.9, 1],
                      outputRange: [0, 0.25, 0.7, 1],
                    }),
                  },
                  overlayStyle: {
                    opacity: progress.interpolate({
                      inputRange: [0, 1],
                      outputRange: [0, 0.5],
                      extrapolate: 'clamp',
                    }),
                  },
                }),
                presentation: 'transparentModal',
              }}>
              <RootStack.Screen name="CreateClass" component={CreateClassScreen} />
              <RootStack.Screen name="NewChatModal" component={NewChatScreen} />
              <RootStack.Screen name="EditChatModal" component={EditChatScreen} />
              <RootStack.Screen name="ClassSettingsModal" component={ClassSettingsScreen} />
              <RootStack.Screen
                name="ReportCommunityChatModal"
                component={ReportCommunityChatScreen}
              />
              <RootStack.Screen name="MediaSourceModal" component={MediaSourceScreen} />
              <RootStack.Screen name="ImageSearchModal" component={ImageSearchScreen} />
              <RootStack.Screen name="PermissionDeniedModal" component={PermissionDeniedScreen} />
            </RootStack.Group>
          </RootStack.Navigator>
        </SearchProvider>
      </SelectableProvider>
    </SWRConfig>
  )
}
