import { FirebaseFirestoreTypes } from '@react-native-firebase/firestore'
import { firestore } from '~providers/firebase'

const listClasses = (userId: string): FirebaseFirestoreTypes.Query =>
  firestore()
    .collection('classes')
    .where('members', 'array-contains', userId)
    .orderBy('name', 'asc')

const listClassesTeacher = (userId: string): FirebaseFirestoreTypes.Query =>
  firestore()
    .collection('classes')
    .where('teachers', 'array-contains', userId)
    .orderBy('name', 'asc')

const listChats = (classId: string): FirebaseFirestoreTypes.Query =>
  firestore().collection('classes').doc(classId).collection('chats').orderBy('updated_at', 'desc')

const listReplies = (userId: string, classId: string): FirebaseFirestoreTypes.Query =>
  firestore()
    .collection('users')
    .doc(userId)
    .collection('chats')
    .where('parent_class_id', '==', classId)
    .orderBy('updated_at', 'desc')

export default {
  listClasses,
  listClassesTeacher,
  listChats,
  listReplies,
}
