import * as React from 'react'

import { Text, SwitchProps, Switch } from 'react-native'
import { ColorSchemeName } from 'react-native-appearance'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import { ScaledSheet } from 'react-native-size-matters'
import { View } from '~components/Themed'

export interface Description {
  enabled?: string
  disabled?: string
}

export interface FormSwitchProps extends SwitchProps {
  description?: Description
  label?: (() => React.ReactNode) | string
  error?: (() => React.ReactNode) | string | string[]
}

export function FormSwitch({ description, label, error, ...props }: FormSwitchProps): JSX.Element {
  const { mode, theme } = useTheme()
  const styles = getStyles(mode, theme)
  const { value, ...rest } = props

  const descriptionNode = React.useMemo(
    () =>
      description ? (
        <Text style={styles.description}>{value ? description.enabled : description.disabled}</Text>
      ) : null,
    [description, styles.description, value],
  )

  const labelNode = React.useMemo(
    () =>
      label ? (
        typeof label === 'string' ? (
          <Text style={styles.label}>{label}</Text>
        ) : (
          label()
        )
      ) : null,
    [label, styles.label],
  )

  const errorNode = React.useMemo(() => {
    return error ? (
      typeof error === 'string' || Array.isArray(error) ? (
        <Text style={styles.error}>{[error].flat().join('\n')}</Text>
      ) : (
        error()
      )
    ) : null
  }, [error, styles.error])

  return (
    <>
      {labelNode}
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Switch
          {...props}
          trackColor={{ false: theme[100], true: theme.pink[100] }}
          thumbColor={value ? theme.primary : theme.white}
        />
        {descriptionNode}
      </View>
      {errorNode}
    </>
  )
}

const getStyles = (mode: ColorSchemeName, theme: Theme) =>
  ScaledSheet.create({
    description: {
      fontSize: '12@ms0.3',
      fontWeight: '500',
      color: theme[500],
      marginStart: '4@ms',
    },
    label: {
      fontSize: '10@ms0.3',
      fontWeight: '500',
      marginBottom: '3@mvs',
      color: theme[500],
    },
    error: {
      fontSize: '10@ms0.3',
      fontWeight: '500',
      marginBottom: '4@mvs',
      color: theme.pink[400],
    },
  })
