import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { CompositeNavigationProp, RouteProp, useFocusEffect } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useCallback, useState } from 'react'
import { FlatList, TouchableOpacity } from 'react-native'
import { ScaledSheet } from 'react-native-size-matters'
import { useDispatch, useSelector } from 'react-redux'
import { ChatNavigationItem } from '~components/ChatNavigationItem'
import { View } from '~components/Themed'
import { setName, useClassTabsContext } from '~context/ClassTabsContext'
import useColumns from '~hooks/useColumns'
import usePermission from '~hooks/usePermission'
import { firestore } from '~providers/firebase'
import { ApplicationState } from '~redux'
import { addRecentClass, removeRecentClass } from '~redux/recent/actions'
import ChatService from '~services/chat'
import ClassService from '~services/class'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import { ClassesStackParamList, RootStackParamList } from '~types'
import { usePagination } from '~utils/react-firebase-pagination-hooks'

type Props = {
  navigation: CompositeNavigationProp<
    StackNavigationProp<RootStackParamList>,
    StackNavigationProp<ClassesStackParamList, 'Class'>
  >
  route: RouteProp<ClassesStackParamList, 'Class'>
}

export default function ClassScreen({ navigation }: Props): JSX.Element {
  const { theme } = useTheme()
  const dispatch = useDispatch()
  const styles = getStyles(theme)
  const [teachers, setTeachers] = useState<string[]>([])
  const [chatsCount, setChatsCount] = useState(0)
  const user_id = useSelector((state: ApplicationState) => state.auth.user?.claims.sub)
  const permission_create_chat = usePermission('restrictions_max_chats_per_class', chatsCount)
  const columns = useColumns()

  const [{ classId, name }, dispatchClassTabs] = useClassTabsContext()

  const [items, { loaded, hasMore, loadingMore, loadMore }, error] = usePagination(
    ClassService.listChats(classId),
    {
      limit: 20,
    },
  )

  React.useEffect(() => {
    if (error) {
      dispatch(removeRecentClass(classId))
      navigation.goBack()
    }
  }, [navigation, error, dispatch, classId])

  React.useLayoutEffect(() => {
    const showCreateChat = () => {
      if (permission_create_chat.status === 'allowed') {
        navigation.navigate('NewChatModal', {
          classId: classId,
        })
      } else {
        navigation.navigate('PermissionDeniedModal', {
          message: permission_create_chat.message,
        })
      }
    }

    navigation.getParent()?.setOptions({
      headerRight: () => (
        <View style={styles.navIconsContainer}>
          {user_id && teachers.includes(user_id) && (
            <TouchableOpacity
              onPress={() => {
                navigation.navigate('ClassSettingsModal', {
                  id: classId,
                })
              }}
              style={styles.navIconsButton}>
              <FontAwesomeIcon color={theme.primary} size={24} icon={['far', 'wrench']} />
            </TouchableOpacity>
          )}
          <TouchableOpacity onPress={showCreateChat} style={styles.navIconsButton}>
            <FontAwesomeIcon color={theme.primary} size={24} icon={['far', 'plus']} />
          </TouchableOpacity>
        </View>
      ),
    })
  }, [
    navigation,
    classId,
    theme,
    styles,
    user_id,
    teachers,
    permission_create_chat.status,
    permission_create_chat.message,
  ])

  useFocusEffect(
    useCallback(() => {
      let didCancel = false

      const subscriber = firestore()
        .collection('classes')
        .doc(classId)
        .onSnapshot(
          (snapshot) => {
            if (!didCancel) {
              if (!snapshot.exists) {
                dispatch(removeRecentClass(classId))
                navigation.goBack()
              } else {
                const data = snapshot.data()

                const id = snapshot.id
                const name = data?.name ?? ''
                navigation.getParent()?.setOptions({
                  title: name,
                })
                dispatchClassTabs(setName(name))
                setTeachers(data?.teachers ?? [])
                setChatsCount(data?.chats_count ?? 0)
                if (!didCancel) {
                  dispatch(
                    addRecentClass({
                      id,
                      name,
                      role:
                        user_id && (data?.teachers ?? []).includes(user_id) ? 'teacher' : 'student',
                    }),
                  )
                }
              }
            }
          },
          (error) => {
            dispatch(removeRecentClass(classId))
            navigation.goBack()
          },
        )

      return () => {
        didCancel = true
        subscriber()
      }
    }, [dispatch, navigation, classId, user_id, dispatchClassTabs]),
  )

  const renderItem = ({ item }) => {
    return (
      <ChatNavigationItem
        chat={item}
        role={user_id && teachers.includes(user_id) ? 'teacher' : 'student'}
      />
    )
  }

  const data = React.useMemo(() => {
    const list = items.map(ChatService.chatFromSnapshot)
    list.sort((a, b) => b.updated_at.localeCompare(a.updated_at))
    return list
  }, [items])

  return (
    <>
      <FlatList
        key={`class-${columns}`}
        contentContainerStyle={styles.listContainer}
        data={data}
        numColumns={columns}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
        ItemSeparatorComponent={() => <View style={styles.itemSeparator} />}
        onEndReached={() => {
          if (loaded && hasMore && !loadingMore) {
            loadMore()
          }
        }}
        onEndReachedThreshold={0.5}
      />
    </>
  )
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    listContainer: {
      paddingVertical: '20@mvs',
      paddingHorizontal: '10@ms',
    },
    navIconsContainer: {
      flexDirection: 'row',
      paddingEnd: 10,
    },
    navIconsButton: {
      marginLeft: 15,
      padding: 10,
    },
    itemSeparator: {
      height: '16@ms',
    },
  })
