import { HeaderBackButton } from '@react-navigation/elements'
import { useNavigation } from '@react-navigation/native'
import React from 'react'

export function DefaultHeaderLeft(props: {
  pressColor?: string
  pressOpacity?: number
  tintColor?: string
}): JSX.Element {
  const navigation = useNavigation()

  return navigation.isFocused() && navigation.canGoBack() ? (
    <HeaderBackButton onPress={navigation.goBack} />
  ) : (
    <></>
  )
}
