// https://github.com/CSFrequency/react-firebase-hooks/blob/master/firestore/helpers/index.ts
import { FirebaseFirestoreTypes } from '@react-native-firebase/firestore'

export const snapshotToData = (
  snapshot: FirebaseFirestoreTypes.DocumentSnapshot,
  idField?: string,
) => {
  if (!snapshot.exists) {
    return undefined
  }

  return {
    ...snapshot.data(),
    ...(idField ? { [idField]: snapshot.id } : null),
  }
}
