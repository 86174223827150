import React, { useRef, useState } from 'react'
import {
  FlatList,
  FlatListProps,
  StyleProp,
  StyleSheet,
  TextInput,
  TextInputProps,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native'
import { FormInput } from '~components/FormInput'
import Suggestion from './Suggestion'
import Tag from './Tag'

export type AutocompleteTagsProps<Tag = any, Suggestion = any> = {
  /** array of tags to render */
  tags: Tag[]

  /** array of all possible suggestions that the autocomplete pulls from */
  suggestions?: Suggestion[]

  /** function called when tags needs to be updated */
  onChangeTags: (newTags: Tag[]) => void

  /** function called when text changed */
  onChangeText?: (text: string) => void

  /** given a tag, returns the string that should be rendered in a Tag */
  labelExtractor: (tag: Tag) => string

  /** called when a tag is pressed, instead of calling `onChangeTags` with the pressed tag removed */
  onTagPress?: (tag: Tag) => void

  /** an array of characters that should trigger a new tag and clear the TextInput
   * @default [',', ' ', ';', '\n']  */
  parseChars?: string[]

  /** called when the user types a character in parseChars and should therefore add a new tag
   * if undefined, will call `onChangeTags` with `[...tags, userInputText]`
   */
  onAddNewTag?: (userInput: string) => void

  /** whether or not to allow the user to create a Tag that doesn't come from `suggestions`
   * @default true
   */
  allowCustomTags?: boolean

  /** called when a suggestion is pressed
   * defaultly calls `onChangeTags` with `[...tags, pressedSuggestion]`
   */
  onSuggestionPress?: (suggestion: Suggestion) => void

  /** given a Suggestion, returns a string that can be compared to the user's search */
  suggestionExtractor?: (suggestion: Suggestion) => string

  /** a function for filtering suggestions based on the TextInput value */
  filterSuggestions?: (text: string, suggestions?: Suggestion[]) => Suggestion[]

  /** a function that returns a custom tag component */
  renderTag?: (tag: Tag, onPress: (tag: Tag) => void) => JSX.Element

  /** a function that returns a custom suggestion component */
  renderSuggestion?: (suggestion: Suggestion, onPress: (tag: Suggestion) => void) => JSX.Element

  /** any custom TextInputProps */
  inputProps?: Partial<TextInputProps>

  /** any additional FlatListProps */
  flatListProps?: Partial<FlatListProps<any>>

  /** style for the outer-most View that houses both the tagContainer and suggestion list */
  containerStyle?: StyleProp<ViewStyle>

  /** styles for the container View that houses the tags and the input */
  tagContainerStyle?: StyleProp<ViewStyle>

  /** styles for the TextInput component */
  inputStyle?: StyleProp<TextStyle>

  /** styles for the FlatList that renders suggestions */
  flatListStyle?: StyleProp<ViewStyle>

  /** focus on text field when a selection is pressed
   * @default true
   */
  focusOnSuggestionPress?: boolean
}

export const AutocompleteTags = <Tag, Suggestion extends Tag>({
  tags,
  suggestions = [],
  labelExtractor,
  suggestionExtractor,
  onChangeTags,
  onChangeText,
  onTagPress,
  parseChars = [',', ' ', ';', '\n'],
  allowCustomTags = true,
  onAddNewTag,
  onSuggestionPress,
  filterSuggestions,
  renderTag,
  renderSuggestion,
  containerStyle,
  tagContainerStyle,
  inputStyle,
  flatListStyle,
  inputProps,
  flatListProps,
  focusOnSuggestionPress = true,
}: AutocompleteTagsProps<Tag, Suggestion>): JSX.Element => {
  const [text, setText] = useState('')
  const inputRef = useRef<TextInput | null>(null)
  const extractor = suggestionExtractor || labelExtractor

  const handleTagPress = (tag: Tag) => {
    if (onTagPress) {
      onTagPress(tag)
    } else {
      onChangeTags(tags.filter((t) => labelExtractor(t) !== labelExtractor(tag)))
    }
  }

  const handleSuggestionPress = (suggestion: Suggestion) => {
    setText('')
    if (onSuggestionPress) {
      onSuggestionPress(suggestion)
    } else {
      onChangeTags([...tags, suggestion])
    }
    if (focusOnSuggestionPress) {
      inputRef.current?.focus()
    }
  }

  const handleTextChange = (input: string) => {
    let value = input

    const lastTyped = input.charAt(input.length - 1)
    if (parseChars && parseChars.indexOf(lastTyped) > -1) {
      value = ''
      if (allowCustomTags) {
        const label = input.slice(0, -1)
        if (onAddNewTag) {
          onAddNewTag(label)
        } else {
          onChangeTags([...tags, label])
        }
      }
    }

    setText(value)

    if (onChangeText) {
      onChangeText(value)
    }
  }

  const renderTagComponent = (tag: Tag) => {
    const onPress = () => handleTagPress(tag)
    if (renderTag) {
      return renderTag(tag, onPress)
    }
    return <Tag label={labelExtractor(tag)} key={labelExtractor(tag)} onPress={onPress} />
  }

  const renderSuggestionComponent = (item: Suggestion) => {
    const onPress = () => handleSuggestionPress(item)
    if (renderSuggestion) {
      return renderSuggestion(item, onPress)
    }
    return <Suggestion label={extractor(item)} onPress={onPress} />
  }

  const onKeyPress = ({ nativeEvent: { key } }: { nativeEvent: { key: string } }) => {
    if (text !== '' || key !== 'Backspace' || tags.length < 1) {
      return
    }
    const updatedTags = [...tags]
    updatedTags.pop()
    onChangeTags(updatedTags)
  }

  const getSuggestions = () => {
    if (filterSuggestions) {
      return filterSuggestions(text, suggestions)
    }
    if (!text || text === '') {
      return []
    }
    const regex = new RegExp(`${text.trim()}`, 'i')
    return suggestions?.filter((item) => extractor(item).search(regex) >= 0)
  }

  return (
    <View style={[styles.container, containerStyle]}>
      <View style={[styles.tagContainer, tagContainerStyle]}>
        {tags.map((a) => renderTagComponent(a))}
      </View>
      <FormInput
        value={text}
        onKeyPress={onKeyPress}
        ref={inputRef}
        onChangeText={handleTextChange}
        style={[styles.input, inputStyle]}
        {...inputProps}
      />
      <View style={[styles.tagContainer, tagContainerStyle]}>
        {getSuggestions()?.map((a) => renderSuggestionComponent(a))}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    zIndex: 2000,
    width: '100%',
  },
  tagContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  input: {
    flex: 1,
    minWidth: 100,
  },
})

export default AutocompleteTags
