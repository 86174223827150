import * as React from 'react'

import { ScaledSheet } from 'react-native-size-matters'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import { TextInput, TextInputProps } from '~components/Themed'
import { Text } from 'react-native'
import { TextInput as DefaultTextInput } from 'react-native'
export interface FormInputProps extends TextInputProps {
  label?: (() => React.ReactNode) | string
  error?: (() => React.ReactNode) | string | string[]
}

export const FormInput = React.forwardRef<DefaultTextInput, FormInputProps>(
  ({ label, error, ...props }, ref) => {
    const { theme } = useTheme()
    const styles = getStyles(theme)

    const labelNode = React.useMemo(
      () =>
        label ? (
          typeof label === 'string' ? (
            <Text style={styles.label}>{label}</Text>
          ) : (
            label()
          )
        ) : null,
      [label, styles.label],
    )

    const errorNode = React.useMemo(() => {
      return error ? (
        typeof error === 'string' || Array.isArray(error) ? (
          <Text style={styles.error}>{[error].flat().join('\n')}</Text>
        ) : (
          error()
        )
      ) : null
    }, [error, styles.error])

    return (
      <>
        {labelNode}
        <TextInput
          {...props}
          ref={ref}
          style={[styles.input, error ? styles.inputError : null, props.style]}
        />
        {errorNode}
      </>
    )
  },
)

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    input: {
      paddingVertical: '5@mvs0.8',
      paddingHorizontal: '16@ms',
      marginBottom: '16@mvs',
      fontSize: '12@ms0.4',
      borderRadius: '5@ms',
      borderColor: theme[200],
      borderWidth: 1,
    },
    inputError: {
      marginBottom: '1@mvs',
    },
    label: {
      fontSize: '10@ms0.3',
      fontWeight: '500',
      marginBottom: '3@mvs',
      color: theme[500],
    },
    error: {
      fontSize: '10@ms0.3',
      fontWeight: '500',
      marginBottom: '4@mvs',
      color: theme.pink[400],
    },
  })
