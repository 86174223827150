import React from 'react'
import { AnyAction } from 'redux'
import { initialState, State, useSelectableReducer } from '~reducers/selectable'

const SelectableContext = React.createContext<[State, React.Dispatch<AnyAction>]>([
  initialState,
  () => {},
])

export const SelectableProvider = (props: { children: any }): JSX.Element => {
  const [state, dispatch] = useSelectableReducer()

  const value: [State, React.Dispatch<AnyAction>] = [state, dispatch]

  return <SelectableContext.Provider value={value}>{props.children}</SelectableContext.Provider>
}

export const useSelectableContext = (): [State, React.Dispatch<AnyAction>] =>
  React.useContext(SelectableContext)
