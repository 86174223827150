import React from 'react'
import { View, Text } from 'react-native'
import { useTheme } from '~theme/ThemeManager'
import { ScaledSheet } from 'react-native-size-matters'
import { Theme } from '~theme'
import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login'
import onGoogleButtonPress from './onGoogleButtonPress.web'
import onMicrosoftButtonPress from './onMicrosoftButtonPress.web'
import MicrosoftLogin from 'react-microsoft-login'
import { AuthResponse, AuthError, UserAgentApplication } from 'msal'

const clientId = process.env.EXPO_WEB_AUTHENTICATION_GOOGLE_CLIENT_ID || ''

type Props = {
  color?: string
  seperatorText?: string
  seperator?: boolean
}

export default function SocialSignIn(props: Props): JSX.Element {
  const { mode, theme } = useTheme()
  const styles = getStyles(theme)
  const { color = theme.white, seperatorText = 'OR', seperator = false } = props

  const responseGoogleFailure = (error: any) => {
    console.log(error)
  }

  const responseGoogleSuccess = async (
    response: GoogleLoginResponse | GoogleLoginResponseOffline,
  ) => {
    if ('tokenId' in response) {
      const token = response.tokenId
      await onGoogleButtonPress(token)
    }
  }

  const responseMicrosoft = async (error: AuthError | null, result?: AuthResponse) => {
    if (error === null && result !== undefined) {
      const token = result.idToken.rawIdToken
      await onMicrosoftButtonPress(token)
    }
  }

  return (
    <>
      {seperator && (
        <View style={styles.seperatorContainer}>
          <View style={[styles.rule, { backgroundColor: color }]}></View>
          <Text style={[styles.seperatorText, { color }]}>{seperatorText}</Text>
          <View style={[styles.rule, { backgroundColor: color }]}></View>
        </View>
      )}

      <View style={styles.button}>
        <GoogleLogin
          clientId={clientId}
          onSuccess={responseGoogleSuccess}
          onFailure={responseGoogleFailure}
          cookiePolicy={'single_host_origin'}
        />
      </View>

      <View style={[styles.button, styles.buttonLast]}>
        <MicrosoftLogin
          clientId={process.env.EXPO_AZURE_CLIENT_ID}
          graphScopes={['openid', 'profile']}
          prompt={'select_account'}
          authCallback={responseMicrosoft}
        />
      </View>
    </>
  )
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    seperatorContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      marginVertical: '10@mvs',
    },
    seperatorText: {
      color: theme.white,
      marginHorizontal: 5,
    },
    rule: {
      height: 1,
      backgroundColor: theme.white,
      flexGrow: 1,
    },
    button: {
      width: 215,
      alignSelf: 'center',
      marginBottom: '5@mvs',
    },
    buttonLast: {
      marginBottom: 0,
    },
  })
