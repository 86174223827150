import { FirebaseFirestoreTypes } from '@react-native-firebase/firestore'
import { firestore, storage } from '~providers/firebase'
import { ChatSection, ChatTemplate, CreateChatType, ExistingChat, IChat } from '~types'
import parseChatPath from '~utils/parseChatPath'

export const initialChatSection: ChatSection = {
  image: { src: '' },
  audio: {
    teacher: { src: '' },
    student: { src: '' },
  },
}

const chatFromSnapshot = (
  snapshot: FirebaseFirestoreTypes.QueryDocumentSnapshot | FirebaseFirestoreTypes.DocumentSnapshot,
): ExistingChat => {
  const {
    ref: { path },
    id,
  } = snapshot
  const {
    name,
    level,
    layout,
    sections,
    created_at,
    updated_at,
    author_id,
    author_name,
    teacher_id,
    template_id,
    parent_class_id,
  } = snapshot.data()

  const currentSections = sections || {}
  const newSections: Record<number, ChatSection> = {
    ...Array.from(Array(layout.rows * layout.columns).keys()).map((index) => ({
      ...initialChatSection,
      audio: {
        ...initialChatSection.audio,
        ...currentSections[index]?.audio,
      },
      image: {
        ...initialChatSection.image,
        ...currentSections[index]?.image,
      },
      ...(currentSections[index]?.order !== undefined && { order: currentSections[index].order }),
    })),
  }

  // Allow sharing of chats and group chats
  const regex = /^classes\/(?<classId>[\da-zA-Z]+)\/chats\/(?<chatId>[\da-zA-Z]+)$/
  const matches = path.match(regex)

  const classId = matches ? matches.groups?.classId : parent_class_id

  if (!classId) {
    throw new Error(`classId not found for ${path}`)
  }

  return {
    path,
    id,
    name,
    level,
    layout,
    sections: newSections,
    created_at: (created_at ?? firestore.Timestamp.now()).toDate().toISOString(),
    updated_at: (updated_at ?? firestore.Timestamp.now()).toDate().toISOString(),
    author_id,
    author_name: author_name || 'Unknown',
    teacher_id,
    template_id,
    classId,
    parent_class_id,
  }
}

const listChats = (path: string): FirebaseFirestoreTypes.Query =>
  firestore().doc(path).collection('chats').orderBy('updated_at', 'desc')

const createChat = (
  parent: string, // Parent document path
  chat: CreateChatType,
): Promise<ExistingChat> => {
  const regex = /^classes\/(?<classId>[\da-zA-Z]+)$/
  const matches = parent.match(regex)

  const classId = matches ? matches.groups?.classId : undefined

  if (!classId) {
    throw new Error(`classId not found for ${parent}`)
  }

  const currentSections = chat.sections || {}
  const sections: Record<number, ChatSection> = {
    ...Array.from(Array(chat.layout.rows * chat.layout.columns).keys()).map((index) => ({
      ...initialChatSection,
      audio: {
        ...initialChatSection.audio,
        ...currentSections[index]?.audio,
      },
      image: {
        ...initialChatSection.image,
        ...currentSections[index]?.image,
      },
      ...(currentSections[index]?.order !== undefined && { order: currentSections[index].order }),
    })),
  }

  const values = {
    ...chat,
    sections,
  }

  if (values.template_id === undefined) {
    delete values.template_id
  }

  return firestore()
    .doc(parent)
    .collection('chats')
    .add({
      ...values,
      created_at: firestore.FieldValue.serverTimestamp(),
      updated_at: firestore.FieldValue.serverTimestamp(),
    })
    .then((doc) => ({
      ...values,
      path: doc.path,
      id: doc.id,
      classId,
      created_at: firestore.Timestamp.now().toDate().toISOString(),
      updated_at: firestore.Timestamp.now().toDate().toISOString(),
    }))
}

const updateChat = (path: string, updates: Partial<Omit<IChat, 'id' | 'path'>>): Promise<void> => {
  if (!path) {
    throw new Error('Path is not set')
  }

  return firestore()
    .doc(path)
    .update({
      ...updates,
      updated_at: firestore.FieldValue.serverTimestamp(),
    })
}

const deleteChat = (path: string): Promise<void> => {
  if (!path) {
    throw new Error('Path is not set')
  }

  return firestore().doc(path).delete()
}

const duplicateChat = async (
  authorId: string,
  authorName: string,
  classId: string,
  chat: Required<ChatTemplate>,
): Promise<ExistingChat> => {
  if (!authorId || !classId || !chat) {
    throw new Error('Invalid arguments')
  }

  const path = `users/${authorId}/chats/${chat.id}`

  const required = (({ id, name, layout, sections, author_id }) => ({
    id,
    name,
    layout,
    sections: {
      ...Object.entries(sections).map(([, section]) => ({
        ...initialChatSection,
        audio: {
          ...initialChatSection.audio,
          ...section?.audio,
        },
        image: {
          ...initialChatSection.image,
          ...section?.image,
        },
        ...(section.order !== undefined && { order: section.order }),
      })),
    },
    teacher_id: author_id,
  }))(chat)

  const values = {
    ...required,
    level: '',
    parent_chat_id: chat.id,
    parent_class_id: classId,
    author_id: authorId,
    author_name: authorName,
    created_at: firestore.FieldValue.serverTimestamp(),
    updated_at: firestore.FieldValue.serverTimestamp(),
  }

  await firestore().doc(path).set(values)

  return {
    ...values,
    path,
    id: chat.id,
    created_at: firestore.Timestamp.now().toDate().toISOString(),
    updated_at: firestore.Timestamp.now().toDate().toISOString(),
    classId,
  }
}

const upload = (uri: string, path: string, filename: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const ref = storage().ref(path).child(filename)

    return ref
      .putFile(uri)
      .then((snapshot) => {
        return ref.getDownloadURL()
      })
      .then((url) => {
        resolve(url)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const listGroups = (customerId: string, userId: string): FirebaseFirestoreTypes.Query =>
  firestore()
    .collectionGroup('groups')
    .where('teachers', 'array-contains', userId)
    .orderBy('name', 'asc')

const listDependants = (
  customerId: string,
  userId: string,
): FirebaseFirestoreTypes.DocumentReference =>
  firestore().collection('customers').doc(customerId).collection('users').doc(userId)

const listChatReplies = (path: string): FirebaseFirestoreTypes.Query => {
  const { classId, chatId } = parseChatPath(path)

  return firestore()
    .collectionGroup('chats')
    .where('parent_chat_id', '==', chatId)
    .where('parent_class_id', '==', classId)
    .orderBy('updated_at', 'desc')
}

export default {
  listChats,
  createChat,
  updateChat,
  deleteChat,
  upload,
  chatFromSnapshot,
  duplicateChat,
  listChatReplies,
}
