import React, { useEffect, useState } from 'react'
import { Dimensions, TouchableOpacity } from 'react-native'
import { View, Text } from '~components/Themed'
import { FormInput } from '~components/FormInput'
import { FormButton } from '~components/FormButton'
import ChatService from '~services/chat'
import { useTheme } from '~theme/ThemeManager'
import { Theme } from '~theme'
import { CompositeNavigationProp, RouteProp, useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { ExistingChat, MainStackParamList, RootStackParamList } from '~types'
import ModalScreen, { getModalStyleDefinition, ModalFooter } from '~components/ModalScreen'
import { firestore } from '~providers/firebase'
import { mvs, ScaledSheet } from 'react-native-size-matters'
import { AnyAction, createAction, createReducer } from '@reduxjs/toolkit'
import TabbedMenu from '~components/TabbedMenu'
import { CacheableImage } from '~components/CacheableImage'
import SortableGrid from '~components/SortableGrid'
import Loading from '~components/Loading'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

type Props = {
  navigation: CompositeNavigationProp<
    StackNavigationProp<RootStackParamList, 'EditChatModal'>,
    StackNavigationProp<MainStackParamList>
  >
  route: RouteProp<RootStackParamList, 'EditChatModal'>
}

interface LevelProps {
  value: string
  colour: string
  level: string
  setLevel: (value: string) => void
}

const LevelIcon = ({ value, colour, level, setLevel }: LevelProps) => {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const isSelected = value === level

  const select = () => {
    if (isSelected) {
      setLevel('')
    } else {
      setLevel(value)
    }
  }

  return (
    <TouchableOpacity
      style={[
        styles.levelIcon,
        { backgroundColor: colour },
        isSelected ? styles.levelIconSelected : {},
      ]}
      onPress={select}>
      <Text style={styles.levelIconText}>{value}</Text>
    </TouchableOpacity>
  )
}

export type EditChatViewType = 'details' | 'order'

export interface State {
  path: string
  view: EditChatViewType
  isLoading: boolean
  chat?: ExistingChat
}

export const initialState: State = {
  path: '',
  view: 'details',
  isLoading: true,
  chat: undefined,
}

export const setView = createAction<EditChatViewType>('setView')
export const setLoading = createAction<boolean>('setLoading')
export const setChat = createAction<ExistingChat>('setChat')

const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setView, (state, action) => {
      state.view = action.payload
    })
    .addCase(setLoading, (state, action) => {
      state.isLoading = action.payload
    })
    .addCase(setChat, (state, action) => {
      state.chat = action.payload
      state.isLoading = false
    }),
)
const store = React.createContext<[State, React.Dispatch<AnyAction>]>([initialState, () => {}])
const { Provider } = store
const useEditChatContext = (): [State, React.Dispatch<AnyAction>] => React.useContext(store)

export default function EditChatScreen({ navigation, route: { params } }: Props): JSX.Element {
  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState,
    path: params.path,
  })
  const value: [State, React.Dispatch<AnyAction>] = [state, dispatch]

  const labels: EditChatViewType[] = ['details', 'order']

  const renderActiveTab = () => {
    switch (state.view) {
      case 'order':
        return <Order />
      case 'details':
      default:
        return <Details />
    }
  }

  useEffect(() => {
    dispatch(setLoading(true))
    let cancel = false

    const subscriber = firestore()
      .doc(params.path)
      .onSnapshot((snapshot) => {
        if (!snapshot.exists) {
          navigation.goBack()
        } else if (!cancel) {
          const chat = ChatService.chatFromSnapshot(snapshot)
          dispatch(setChat(chat))
        }
      }, navigation.goBack)

    return () => {
      cancel = true
      subscriber()
    }
  }, [dispatch, navigation, params.path])

  return (
    <Provider value={value}>
      <ModalScreen
        ContainerComponent={state.view === 'details' ? KeyboardAwareScrollView : React.Fragment}>
        <TabbedMenu<EditChatViewType>
          labels={labels}
          active={state.view}
          onPress={(index: number) => dispatch(setView(labels[index]))}
        />
        {!state.chat ? <Loading /> : renderActiveTab()}
      </ModalScreen>
    </Provider>
  )
}

const Details = () => {
  const { theme } = useTheme()
  const [{ isLoading, path, chat }, dispatch] = useEditChatContext()
  const [name, setName] = useState('')
  const [level, setLevel] = useState('')
  const styles = getStyles(theme)
  const navigation = useNavigation()

  useEffect(() => {
    setName(chat?.name ?? '')
    setLevel(chat?.level ?? '')
  }, [chat])

  const editChat = async () => {
    dispatch(setLoading(true))

    await ChatService.updateChat(path, {
      name,
      level,
    })

    navigation.goBack()
  }

  const levels: Pick<LevelProps, 'value' | 'colour'>[] = [
    { value: '1', colour: '#9088a2' },
    { value: '2', colour: '#f5afc0' },
    { value: '3', colour: '#a0d1b2' },
    { value: '4', colour: '#f5ba9c' },
  ]

  return (
    <View style={styles.inner}>
      <View style={styles.content}>
        <View style={styles.titleContainer}>
          <Text style={styles.title}>Edit Chat</Text>
        </View>

        <View>
          <FormInput
            label="Name of chat"
            value={name}
            onChangeText={(text) => setName(text)}
            maxLength={128}
          />

          <View style={styles.levelContainer}>
            <Text style={styles.label}>Add a grade level to the chat?</Text>
            <View style={styles.levelIcons}>
              {levels.map((item) => (
                <LevelIcon key={item.value} level={level} setLevel={setLevel} {...item} />
              ))}
            </View>
          </View>
        </View>
      </View>

      <ModalFooter>
        <View></View>
        <FormButton
          title="Update Chat"
          onPress={editChat}
          disabled={isLoading || name.length === 0}
        />
      </ModalFooter>
    </View>
  )
}

const Order = () => {
  const { theme } = useTheme()
  const [{ chat }, dispatch] = useEditChatContext()
  const styles = getStyles(theme)

  const sections = Object.entries(chat?.sections ?? {})
    .sort(([, a], [, b]) => (a.order ?? 0) - (b.order ?? 0))
    .map(([idx, section]) => ({
      key: idx,
      source: section.image.src ? { uri: section.image.src } : undefined,
    }))

  const onDragRelease = async ({
    itemOrder,
  }: {
    itemOrder: {
      key: string
      order: number
    }[]
  }) => {
    if (!chat) {
      return
    }

    // Skip if the same order
    const original = sections.map((o) => o.key)
    const updated = itemOrder.map((o) => o.key)
    if (JSON.stringify(original) === JSON.stringify(updated)) {
      return
    }

    const updates = itemOrder.reduce((accumulator: Record<string, number>, item) => {
      accumulator[`sections.${item.key}.order`] = item.order
      return accumulator
    }, {})

    await firestore().doc(chat.path).update(updates)
  }

  return (
    <View style={styles.inner}>
      <View style={styles.content}>
        <Text style={styles.subtitle}>Drag chat frames to reorder</Text>
        <View style={styles.orderContainer}>
          <Text></Text>
          <SortableGrid itemsPerRow={chat?.layout.columns} onDragRelease={onDragRelease}>
            {sections.map(({ key, source }) => {
              return (
                <View key={key} style={styles.chatSectionContainer}>
                  <View style={styles.chatSection}>
                    {source && (
                      <CacheableImage
                        source={source}
                        style={{ width: '100%', height: '100%' }}
                        resizeMode="contain"
                      />
                    )}
                  </View>
                </View>
              )
            })}
          </SortableGrid>
        </View>
      </View>
    </View>
  )
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    ...getModalStyleDefinition(theme),
    label: {
      fontSize: '10@ms0.3',
      fontWeight: '500',
      marginBottom: '3@mvs',
      color: theme[500],
    },
    labelLevel: {
      textAlign: 'center',
      color: theme.text,
    },
    levelContainer: {
      marginBottom: 10,
    },
    levelIcons: {
      flexDirection: 'row',
      flexGrow: 1,
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    levelIcon: {
      width: 48,
      height: 48,
      borderRadius: 24,
      justifyContent: 'center',
      alignItems: 'center',
    },
    levelIconSelected: {
      borderColor: theme.primary,
      borderWidth: 2,
    },
    levelIconText: {
      fontSize: 16,
      color: 'black',
    },
    orderContainer: {
      width: Math.floor(Math.max(Dimensions.get('window').width / 3.5, mvs(280))),
      alignSelf: 'center',
    },
    chatSectionContainer: {
      flex: 1,
      padding: '2@mvs',
    },
    chatSection: {
      flex: 1,
      borderColor: theme[200],
      borderWidth: 1,
      backgroundColor: theme.white,
    },
  })
