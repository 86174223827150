import React from 'react'
import { View, Text, TouchableOpacity, TouchableOpacityProps } from 'react-native'
import { ScaledSheet } from 'react-native-size-matters'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'

export interface TagProps extends TouchableOpacityProps {
  title: string
}

export const Tag = ({ title, onPress }: TagProps): JSX.Element => {
  const { theme } = useTheme()
  const styles = getStyles(theme)

  const inner = (
    <View style={styles.container}>
      <Text style={styles.text}>{title}</Text>
    </View>
  )

  return onPress ? <TouchableOpacity onPress={onPress}>{inner}</TouchableOpacity> : inner
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    container: {
      backgroundColor: theme[200],
      paddingVertical: '2@mvs',
      paddingHorizontal: '5@ms',
      marginEnd: '4@ms',
      marginVertical: '2@ms',
      borderRadius: '10@ms',
    },
    text: {
      fontSize: '10@ms0.2',
      color: theme[500],
    },
  })
