import { Platform } from 'react-native'
import Rollbar, { LogArgument } from 'rollbar'

export const RollbarErrorTracking = (() => {
  if (
    process.env.EXPO_ROLLBAR_POST_CLIENT_ITEM === undefined ||
    process.env.EXPO_ROLLBAR_POST_CLIENT_ITEM.length === 0
  ) {
    console.warn('Rollbar token missing')

    return {
      error: () => {},
    }
  }

  const RollbarObj = new Rollbar({
    accessToken: process.env.EXPO_ROLLBAR_POST_CLIENT_ITEM,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: Platform.OS,
    },
  })

  const error = (obj: LogArgument) => {
    RollbarObj.error(obj)
  }

  return { error }
})()

export default RollbarErrorTracking
