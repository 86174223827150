import React, { useContext } from 'react'
import { ColorSchemeName, Appearance } from 'react-native-appearance'
import { getTheme, Theme } from './index'

type ThemeState = {
  mode: ColorSchemeName
  theme: Theme
}

// set default colour scheme from OS
const osTheme = Appearance.getColorScheme()

// initiate context
export const ManageThemeContext: React.Context<ThemeState> = React.createContext<ThemeState>({
  mode: osTheme,
  theme: getTheme(osTheme),
})

// define useTheme hook for functional components
export const useTheme = (): ThemeState => React.useContext(ManageThemeContext)

// initiate context provider
export function ThemeManager(props: { children: any }): JSX.Element {
  const { mode } = useContext(ManageThemeContext)

  const theme = {
    mode: mode,
    theme: getTheme(mode),
  }

  return <ManageThemeContext.Provider value={theme}>{props.children}</ManageThemeContext.Provider>
}

export default ThemeManager
