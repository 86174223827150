import { createAction } from '@reduxjs/toolkit'
import { RemovePayload, UploadPayload } from './types'

export const upload = createAction(
  '@@storage/UPLOAD',
  (payload: UploadPayload, key: string | undefined = undefined) => {
    return {
      payload,
      key,
    }
  },
)

export const remove = createAction<RemovePayload>('@@storage/DELETE')
