import { Keyboard } from 'react-native'
import { useState, useEffect } from 'react'

const useKeyboardState = (): boolean => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    const keyboardDidShow = () => setIsOpen(true)
    const keyboardDidHide = () => setIsOpen(false)

    Keyboard.addListener('keyboardDidShow', keyboardDidShow)
    Keyboard.addListener('keyboardDidHide', keyboardDidHide)

    return () => {
      Keyboard.removeListener('keyboardDidShow', keyboardDidShow)
      Keyboard.removeListener('keyboardDidHide', keyboardDidHide)
    }
  }, [])

  return isOpen
}

export default useKeyboardState
