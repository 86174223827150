import { FirebaseAuthTypes } from '@react-native-firebase/auth'
import {
  DarkTheme as DefaultDarkTheme,
  DefaultTheme,
  LinkingOptions,
  NavigationContainer,
  useNavigationContainerRef,
} from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { Platform, View } from 'react-native'
import { AppearanceProvider, useColorScheme } from 'react-native-appearance'
import { OverflowMenuProvider } from 'react-navigation-header-buttons'
import { connect, useDispatch } from 'react-redux'
import { analytics, auth, firestore, remoteConfig } from '~providers/firebase'
import { ApplicationState } from '~redux'
import { getAuth, setProfile } from '~redux/auth'
import { defaultColors } from '~theme'
import { ThemeManager } from '~theme/ThemeManager'
import { Profile, RootStackParamList } from '~types'
import defaultConfig from '../config'
import AuthenticatedStack from './AuthenticatedStack'
import TermsAndConditionsStack from './TermsAndConditionsStack'
import UnauthenticatedStack from './UnauthenticatedStack'

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace ReactNavigation {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface RootParamList extends RootStackParamList {}
  }
}

const LightTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: defaultColors.primary,
    background: defaultColors['white'],
  },
}

const DarkTheme = {
  ...DefaultDarkTheme,
  colors: {
    ...DefaultDarkTheme.colors,
    primary: defaultColors.primary,
    card: '#000000',
  },
}

interface PropsFromState {
  user_id: string | undefined
  isAuthenticated: boolean
  termsAndConditionsVersion: string
}

const linking: LinkingOptions<ReactNavigation.RootParamList> = {
  prefixes: [],
  config: {
    initialRouteName: 'Main',
    screens: {
      Main: {
        path: '',
        screens: {
          Dashboard: '',
          Classes: {
            path: 'classes',
            screens: {
              MyClasses: '',
              Class: {
                path: ':id',
                screens: {
                  ClassChats: '',
                  ClassChatsReplies: 'myreplies',
                },
              },
              ChatScreen: 'chat',
              ChatReplies: 'replies',
              JoinClass: 'join',
            },
          },
          Community: {
            path: 'community',
            screens: {
              ChattaCommunityCategories: '',
              ChattaCommunityTemplateScreen: 'template/:id',
              ChattaCommunity: ':id',
            },
          },
          Settings: 'settings',
        },
      },
      Login: 'login',
      Register: 'register',
      // Modals
      CreateClass: 'classes/new',
      NewChatModal: 'classes/:classId/new',
      EditChatModal: 'classes/chat/settings',
      ClassSettingsModal: 'classes/:id/settings',
      ReportCommunityChatModal: 'community/template:id/report',
      MediaSourceModal: 'media-source/:target/choose',
      ImageSearchModal: 'media-source/:target/:type',
    },
  },
}

const AppWithNavigationState = ({
  user_id,
  isAuthenticated,
  termsAndConditionsVersion,
}: PropsFromState) => {
  const navigationRef = useNavigationContainerRef()
  const routeNameRef = React.useRef<string>(null)
  const scheme = useColorScheme()
  const [isConfigLoading, setIsConfigLoading] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    const subscriber = !isConfigLoading
      ? auth().onAuthStateChanged((user: FirebaseAuthTypes.User | null) => dispatch(getAuth(user)))
      : () => {}

    return subscriber // unsubscribe on unmount
  }, [dispatch, isConfigLoading])

  useEffect(() => {
    if (isAuthenticated && user_id !== undefined) {
      let cancel = false
      const subscription = firestore()
        .collection<Profile>('users')
        .doc(user_id)
        .onSnapshot((snapshot) => {
          if (!cancel) {
            dispatch(setProfile(snapshot.data()))
          }
        })

      return () => {
        cancel = true
        subscription()
      }
    }
  }, [dispatch, isAuthenticated, user_id])

  useEffect(() => {
    async function fetchConfig() {
      const config = remoteConfig()
      if (Platform.OS === 'web') {
        config.defaultConfig = defaultConfig
      } else {
        config.setDefaults(defaultConfig)
      }
      await config.fetchAndActivate()
      setIsConfigLoading(false)
    }

    fetchConfig()
  }, [])

  if (isConfigLoading) {
    return <View />
  }

  const acceptedTermsAndConditions =
    termsAndConditionsVersion === remoteConfig().getString('terms_and_conditions_version')

  return (
    <AppearanceProvider>
      <ThemeManager>
        <NavigationContainer
          linking={linking}
          theme={scheme === 'dark' ? DarkTheme : LightTheme}
          ref={navigationRef}
          onReady={() => (routeNameRef.current = navigationRef?.current?.getCurrentRoute()?.name)}
          onStateChange={async () => {
            const previousRouteName = routeNameRef.current
            const currentRouteName = navigationRef?.current?.getCurrentRoute()?.name

            if (previousRouteName !== currentRouteName) {
              await analytics().logEvent('screen_view', {
                screen_name: currentRouteName,
                screen_class: currentRouteName,
              })
            }

            // Save the current route name for later comparision
            routeNameRef.current = currentRouteName
          }}>
          <OverflowMenuProvider>
            {isAuthenticated ? (
              acceptedTermsAndConditions ? (
                <AuthenticatedStack />
              ) : (
                <TermsAndConditionsStack />
              )
            ) : (
              <UnauthenticatedStack />
            )}
          </OverflowMenuProvider>
        </NavigationContainer>
      </ThemeManager>
    </AppearanceProvider>
  )
}

const mapStateToProps = ({ auth }: ApplicationState): PropsFromState => ({
  user_id: auth.user?.claims.sub,
  isAuthenticated: auth.isAuthenticated,
  termsAndConditionsVersion: auth.termsAndConditions,
})

export default connect(mapStateToProps)(AppWithNavigationState)
