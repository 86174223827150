import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import Clipboard from '@react-native-clipboard/clipboard'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { AnyAction, createAction, createReducer } from '@reduxjs/toolkit'
import React, { useEffect, useState } from 'react'
import { Dimensions, TouchableOpacity } from 'react-native'
import { ms, ScaledSheet } from 'react-native-size-matters'
import { CreatedStudent, GroupResource } from '~api/types'
import AddStudentsForm from '~components/AddStudentsForm'
import { FormButton } from '~components/FormButton'
import { FormInput } from '~components/FormInput'
import GroupPDFButton from '~components/GroupPDFButton'
import ModalScreen, { getModalStyleDefinition, ModalFooter } from '~components/ModalScreen'
import Progress from '~components/Progress'
import { Text, View } from '~components/Themed'
import { ForbiddenError } from '~errors/ForbiddenError'
import { ValidationError } from '~errors/ValidationError'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import { DeepRequired, RootStackParamList } from '~types'
import { useAxios } from '~utils/fetch'
import { FormInputButton } from '../../components/FormInputButton'

type NavigationProp = StackNavigationProp<RootStackParamList, 'CreateClass'>

export type CreateClassPopupProps = {
  navigation: NavigationProp
}

type ViewType = 'details' | 'students' | 'code' | 'manual' | 'setup'
type InviteType = 'code' | 'manual'

export interface State {
  view: ViewType
  name: string
  class?: DeepRequired<GroupResource>
  inviteType?: InviteType
  step: number
  students: CreatedStudent[]
}

export const initialState: State = {
  view: 'details',
  name: '',
  step: 0,
  students: [],
}

export const setView = createAction('setView', (view: ViewType, step: number) => ({
  payload: {
    view,
    step,
  },
}))
export const setName = createAction<string, 'setName'>('setName')
export const setInviteType = createAction<InviteType | undefined>('setInviteType')
export const setClass = createAction<DeepRequired<GroupResource>>('setClass')
export const setCreatedStudents = createAction<CreatedStudent[]>('setCreatedStudents')
export const reset = createAction('reset')

const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(reset, (state, action) => initialState)
    .addCase(setView, (state, action) => {
      state.view = action.payload.view
      state.step = action.payload.step
    })
    .addCase(setName, (state, action) => {
      state.name = action.payload
    })
    .addCase(setInviteType, (state, action) => {
      state.inviteType = action.payload
    })
    .addCase(setClass, (state, action) => {
      state.class = action.payload
    })
    .addCase(setCreatedStudents, (state, action) => {
      state.students = action.payload
    }),
)
const store = React.createContext<[State, React.Dispatch<AnyAction>]>([initialState, () => {}])
const { Provider } = store
const useNewClassContext = (): [State, React.Dispatch<AnyAction>] => React.useContext(store)

const progress = (step: number) => (
  <Progress labels={['Details', 'Students', 'Setup']} step={step} />
)

export default function CreateClassScreen({ navigation }: CreateClassPopupProps): JSX.Element {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const value: [State, React.Dispatch<AnyAction>] = [state, dispatch]

  const dismiss = () => navigation.goBack()

  return (
    <ModalScreen>
      <Provider value={value}>
        {state.view === 'details' ? (
          <Details />
        ) : state.view === 'students' ? (
          <Students />
        ) : state.view === 'code' ? (
          <Code />
        ) : state.view === 'manual' ? (
          <Manual />
        ) : (
          <Setup dismiss={dismiss} />
        )}
      </Provider>
    </ModalScreen>
  )
}

const Details = () => {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const [state, stateDispatch] = useNewClassContext()
  const navigation = useNavigation()

  const [{ data, loading, error }, execute] = useAxios<DeepRequired<GroupResource>>(
    state.class !== undefined
      ? {
          url: `/api/v1/groups/${state.class.data.id}`,
          method: 'PATCH',
        }
      : {
          url: `/api/v1/groups`,
          method: 'POST',
        },
    { manual: true },
  )

  const create = async () => {
    if (state.class !== undefined) {
      try {
        const { data: group } = await execute({
          data: {
            name: state.name,
          },
        })

        stateDispatch(setClass(group))
        stateDispatch(setView('students', 1))
      } catch (error) {
        console.info(error)
      }
    } else {
      try {
        const { data: group } = await execute({
          data: {
            name: state.name,
          },
        })

        stateDispatch(setClass(group))
        stateDispatch(setView('students', 1))
      } catch (error) {
        if (error instanceof ForbiddenError) {
          navigation.goBack()
          navigation.navigate('PermissionDeniedModal', {
            message: error.message,
          })
        } else {
          console.info(error)
        }
      }
    }
  }

  return (
    <View style={styles.inner}>
      <View style={styles.content}>
        {progress(state.step)}

        <View style={styles.titleContainer}>
          <Text style={styles.title}>Create a class</Text>
          <Text style={styles.subtitle}>Follow the steps to create a new class.</Text>
        </View>

        <View>
          <FormInput
            label="Name of class"
            value={state.name}
            onChangeText={(text) => stateDispatch(setName(text))}
            maxLength={128}
            error={error instanceof ValidationError ? error.errors['name'] : error?.message}
          />
        </View>
      </View>

      <ModalFooter>
        <View></View>
        <FormButton
          onPress={create}
          title={'Continue'}
          style={styles.button}
          disabled={state.name.length === 0 || loading}
        />
      </ModalFooter>
    </View>
  )
}

const Students = () => {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const [state, stateDispatch] = useNewClassContext()
  const navigation = useNavigation()

  const back = () => stateDispatch(setView('details', 0))
  const next = () => {
    if (state.inviteType === 'code') {
      stateDispatch(setView('code', 1.5))
    } else if (state.inviteType === 'manual') {
      stateDispatch(setView('manual', 1.5))
    }
  }
  const skip = () => {
    navigation.navigate('Main', {
      screen: 'Classes',
      params: {
        screen: 'Class',
        initial: false,
        params: {
          id: state.class?.data.id,
          name: state.class?.data.name,
        },
      },
    })
  }
  const template = (template: InviteType) => stateDispatch(setInviteType(template))

  return (
    <View style={styles.inner}>
      <View style={styles.content}>
        {progress(state.step)}

        <View style={styles.titleContainer}>
          <Text style={styles.title}>Invite Students</Text>
          <Text style={styles.subtitle}>
            You can always manually add students and access the class code later at any time from
            the class settings menu.
          </Text>
        </View>

        <View>
          <TouchableOpacity
            onPress={() => template('code')}
            style={[styles.option, state.inviteType === 'code' ? styles.optionSelected : {}]}>
            <FontAwesomeIcon
              color={state.inviteType === 'code' ? theme.primary : theme[400]}
              size={ms(24, 0.4)}
              icon={['far', 'share-alt']}
            />
            <View style={styles.optionContent}>
              <Text style={styles.optionTitle}>Share class code</Text>
              <Text style={styles.optionSubtitle}>
                Give the class code to your students that already have a Chatta account
              </Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => template('manual')}
            style={[styles.option, state.inviteType === 'manual' ? styles.optionSelected : {}]}>
            <FontAwesomeIcon
              color={state.inviteType === 'manual' ? theme.primary : theme[400]}
              size={ms(24, 0.4)}
              icon={['far', 'users-class']}
            />
            <View style={styles.optionContent}>
              <Text style={styles.optionTitle}>Manually add Students</Text>
              <Text style={styles.optionSubtitle}>
                Use this method if your students do not have an account
              </Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={skip}>
            <Text style={styles.skipButton}>Skip</Text>
          </TouchableOpacity>
        </View>
      </View>

      <ModalFooter>
        <FormButton
          onPress={back}
          title={'Back'}
          style={styles.backButton}
          textStyle={styles.backButtonText}
        />
        <FormButton
          onPress={next}
          title={'Continue'}
          style={styles.button}
          disabled={state.inviteType === undefined}
        />
      </ModalFooter>
    </View>
  )
}

const Code = () => {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const [state, stateDispatch] = useNewClassContext()
  const [copyCodeDisabled, setCopyCodeDisabled] = useState(false)

  const back = () => stateDispatch(setView('students', 1))
  const next = () => stateDispatch(setView('setup', 2))

  const copyCode = (code: string) => {
    setCopyCodeDisabled(true)
    Clipboard.setString(code)
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (copyCodeDisabled) {
        setCopyCodeDisabled(false)
      }
    }, 3000)

    return () => {
      clearTimeout(timeout)
    }
  }, [copyCodeDisabled])

  return (
    <View style={styles.inner}>
      <View style={styles.content}>
        {progress(state.step)}

        <View style={styles.titleContainer}>
          <Text style={styles.title}>Invite Students code</Text>
          <Text style={styles.subtitle}>Share the access code below</Text>
        </View>

        {/* TODO: Implement website class joining flow */}
        {/* <FormInputButton
          input={{
            value: state.class.data?.link,
            editable: false,
          }}
          button={{
            title: 'Copy',
            icon: ['far', 'copy'],
          }}
        /> */}

        <View style={[styles.titleContainer, styles.classCodeContainer]}>
          <Text style={styles.classCodeTitle}>Class code: </Text>
          {state.class && (
            <FormInputButton
              input={{
                value: state.class.data.code,
                editable: false,
                style: styles.classCode,
              }}
              button={{
                title: copyCodeDisabled ? 'Copied!' : 'Copy',
                icon: copyCodeDisabled ? undefined : ['far', 'copy'],
                onPress: () => copyCode(state.class?.data?.code ?? ''),
                disabled: copyCodeDisabled || state.class?.data?.code === undefined,
              }}
            />
          )}
        </View>
      </View>

      <ModalFooter>
        <FormButton
          onPress={back}
          title={'Back'}
          style={styles.backButton}
          textStyle={styles.backButtonText}
        />
        <FormButton onPress={next} title={'Continue'} style={styles.button} />
      </ModalFooter>
    </View>
  )
}

const Manual = () => {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const [state, stateDispatch] = useNewClassContext()

  const back = () => stateDispatch(setView('students', 1))

  const createdStudents = (students: CreatedStudent[]) => {
    if (students) {
      stateDispatch(setCreatedStudents(students))
    }
    stateDispatch(setView('setup', 2))
  }

  return (
    <View style={styles.inner}>
      <View style={styles.content}>
        {progress(state.step)}

        <View style={styles.titleContainer}>
          <Text style={styles.title}>Manually Add Students</Text>
          <Text style={styles.subtitle}>
            Enter the full names of your students below. An account will be created for each one,
            with their login details available on the next screen.
          </Text>
        </View>

        {state.class && (
          <AddStudentsForm
            classId={state.class.data.id}
            submitted={createdStudents}
            students_count={0}
          />
        )}
      </View>

      <ModalFooter>
        <FormButton
          onPress={back}
          title={'Back'}
          style={styles.backButton}
          textStyle={styles.backButtonText}
        />
      </ModalFooter>
    </View>
  )
}

const Setup = ({ dismiss }: { dismiss: () => void }) => {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const [state, stateDispatch] = useNewClassContext()

  return (
    <View style={styles.inner}>
      <View style={styles.content}>
        {progress(state.step)}

        <View style={styles.titleContainer}>
          <Text style={styles.title}>Setup Instructions</Text>
          <Text style={styles.subtitle}>Generate a PDF of instructions for your students</Text>
        </View>

        <View style={styles.setupActionContainer}>
          {state.class && (
            <GroupPDFButton classId={state.class.data.id} students={state.students} />
          )}
        </View>
      </View>

      <ModalFooter>
        <View></View>
        <FormButton title={'Close'} style={styles.button} onPress={dismiss} />
      </ModalFooter>
    </View>
  )
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    ...getModalStyleDefinition(theme),
    listItem: {
      margin: '5@s',
      padding: '5@s',
      backgroundColor: theme.white,
    },
    listItemLeft: {
      marginLeft: 10,
    },
    listItemSelected: {
      backgroundColor: theme.pink[200],
    },
    setupActionContainer: {
      alignItems: 'center',
      marginTop: '10@s',
    },
    setupActionIcon: {
      marginBottom: '10@s',
      alignSelf: 'center',
    },
    code: {
      color: theme.primary,
    },
    row: {
      flexDirection: 'row',
      alignSelf: 'center',
    },
    classCodeContainer: {
      width: Dimensions.get('window').width < 640 ? '100%' : '250@ms',
      alignSelf: 'center',
    },
    classCode: {
      color: theme.primary,
      letterSpacing: 5,
    },
    classCodeTitle: {
      fontSize: '12@ms0.4',
      fontWeight: '300',
    },
    skipButton: {
      color: theme.primary,
      fontSize: '12@ms',
      marginBottom: '5@s',
      alignSelf: 'center',
    },
  })
