import { createAction } from '@reduxjs/toolkit'
import { NOOP } from './types'

export const removeOfflineAction = createAction(NOOP, (key: string) => {
  return {
    payload: {},
    meta: {
      offline: {
        effect: {
          type: NOOP,
          key,
        },
      },
    },
  }
})
