import React from 'react'
import { Text } from 'react-native'
import RollbarErrorTracking from '~providers/rollbar'

type RollbarErrorBoundaryState = {
  error: any
  errorInfo: any
}

export class RollbarErrorBoundary extends React.Component<any, RollbarErrorBoundaryState> {
  constructor(props: any) {
    super(props)
    this.state = {
      error: null,
      errorInfo: null,
    }
  }

  componentDidCatch(error: any, errorInfo: any): void {
    RollbarErrorTracking.error(error)
    this.setState({
      error,
      errorInfo,
    })
  }

  render(): React.ReactNode {
    if (this.state.errorInfo) {
      return <Text>Something went wrong!</Text>
    }

    return this.props.children
  }
}
