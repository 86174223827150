import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { CompositeNavigationProp, RouteProp, useIsFocused } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useEffect } from 'react'
import { FlatList } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { ScaledSheet } from 'react-native-size-matters'
import { useSelector } from 'react-redux'
import { ClassNavigationItem } from '~components/ClassNavigationItem'
import { Text, View } from '~components/Themed'
import useColumns from '~hooks/useColumns'
import usePermission from '~hooks/usePermission'
import { ApplicationState } from '~redux'
import ClassService from '~services/class'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import { ClassesStackParamList, RootStackParamList } from '~types'
import { usePagination } from '~utils/react-firebase-pagination-hooks'

type Props = {
  navigation: CompositeNavigationProp<
    StackNavigationProp<RootStackParamList>,
    StackNavigationProp<ClassesStackParamList, 'MyClasses'>
  >
  route: RouteProp<ClassesStackParamList, 'MyClasses'>
}

export default function MyClassesScreen({ navigation, route: { params } }: Props): JSX.Element {
  const isFocused = useIsFocused()
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const user_id = useSelector((state: ApplicationState) => state.auth.user?.claims.sub)
  const usingTemplate = params?.template !== undefined
  const permission_create_class = usePermission('restrictions_max_classes')
  const columns = useColumns()

  // TODO: if usingTemplate add cancel button to header

  const [items, { loaded, hasMore, loadingMore, loadMore }] = usePagination(
    usingTemplate ? ClassService.listClassesTeacher(user_id) : ClassService.listClasses(user_id),
    {
      limit: 20,
    },
  )

  useEffect(() => {
    if (isFocused === false) {
      navigation.setParams({ template: undefined })
    }
  }, [navigation, isFocused])

  React.useLayoutEffect(() => {
    const showJoinClass = () => navigation.navigate('JoinClass')
    const showCreateClass = () => {
      if (permission_create_class.status === 'allowed') {
        navigation.navigate('CreateClass')
      } else {
        navigation.navigate('PermissionDeniedModal', {
          message: permission_create_class.message,
        })
      }
    }

    navigation.setOptions({
      headerRight: () => (
        <View style={styles.navIconsContainer}>
          <TouchableOpacity onPress={showJoinClass} style={styles.navIconsButton}>
            <FontAwesomeIcon color={theme.primary} size={24} icon={['far', 'sign-in']} />
          </TouchableOpacity>
          {!usingTemplate && (
            <TouchableOpacity onPress={showCreateClass} style={styles.navIconsButton}>
              <FontAwesomeIcon color={theme.primary} size={24} icon={['far', 'plus']} />
            </TouchableOpacity>
          )}
        </View>
      ),
    })
  }, [
    navigation,
    theme,
    styles,
    usingTemplate,
    permission_create_class.status,
    permission_create_class.message,
  ])

  const renderHeader = () =>
    usingTemplate ? (
      <View style={styles.header}>
        <Text style={styles.subtitle}>Select class.</Text>
      </View>
    ) : null

  const renderItem = ({ item }) => {
    const id = item.id
    const { name, chats_count = 0 } = item.data()
    const role = user_id && item.data()?.teachers.includes(user_id) ? 'teacher' : 'student'

    return (
      <ClassNavigationItem
        name={name}
        id={id}
        template={params?.template}
        role={role}
        chats_count={chats_count}
      />
    )
  }

  return (
    <>
      <FlatList
        key={`myclasses-${columns}`}
        contentContainerStyle={styles.listContainer}
        data={items}
        numColumns={columns}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
        ListHeaderComponent={renderHeader}
        ItemSeparatorComponent={() => <View style={styles.itemSeparator} />}
        onEndReached={() => {
          if (loaded && hasMore && !loadingMore) {
            loadMore()
          }
        }}
        onEndReachedThreshold={0.5}
      />
    </>
  )
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    listContainer: {
      paddingVertical: '20@mvs',
      paddingHorizontal: '10@ms',
    },
    header: {
      paddingHorizontal: '10@ms',
      paddingBottom: '20@mvs',
    },
    subtitle: {
      fontSize: '12@ms0.4',
      fontWeight: '300',
    },
    navIconsContainer: {
      flexDirection: 'row',
      paddingEnd: 10,
    },
    navIconsButton: {
      marginLeft: 15,
      padding: 10,
    },
    itemSeparator: {
      height: '16@ms',
    },
  })
