class AlreadyUpdatedError extends Error {
  value: string

  /**
   *
   * @param value the value that already exists
   */
  constructor(value: string) {
    super('Already exists')
    this.value = value
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export { AlreadyUpdatedError }
