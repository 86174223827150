import * as React from 'react'
import { mvs } from 'react-native-size-matters'
import Svg, { Circle, Path, Ellipse, G, SvgProps } from 'react-native-svg'

type Props = SvgProps

function IconStudentLarge(props: Props): JSX.Element {
  return (
    <Svg width={mvs(80, 1.1)} height={mvs(80, 1.1)} viewBox="0 0 130 130">
      <G transform="translate(-6.88 -6.88)">
        <Circle
          data-name="Ellipse 415"
          cx={65}
          cy={65}
          r={65}
          transform="translate(6.88 6.88)"
          fill="#9c99b0"
        />
        <Circle
          data-name="Ellipse 416"
          cx={63.796}
          cy={63.796}
          r={63.796}
          transform="translate(8.084 8.084)"
          fill="#444155"
        />
        <Circle
          data-name="Ellipse 417"
          cx={60.577}
          cy={60.577}
          r={60.577}
          transform="translate(11.303 11.303)"
          fill="#c7c5d2"
        />
        <Circle
          data-name="Ellipse 418"
          cx={60.577}
          cy={60.577}
          r={60.577}
          transform="translate(11.303 11.303)"
          fill="#c7c5d2"
        />
        <Path
          data-name="Path 958"
          d="M39.952 23.302l-5.088.743c-.552 0 .855-.867 1.306-1.193a61.028 61.028 0 0168.794-1.812c.405.259 1.666 1.126 1.666 1.126l-4.671-.36C87.238 11.303 56.972 8.928 39.952 23.302z"
          fill="#e3e3e5"
        />
        <Path
          data-name="Path 959"
          d="M63.699 12.406a59.722 59.722 0 018.27-.54 58.856 58.856 0 017.564.5"
          fill="none"
          stroke="#efeff4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          data-name="Path 960"
          d="M120.645 35.445a61.152 61.152 0 01-14.05 84.893 60.106 60.106 0 01-84.253-14.16z"
          fill="#b3b2bf"
        />
        <Path
          data-name="Path 961"
          d="M95.028 62.063a4.885 4.885 0 01.777 1.992 6.573 6.573 0 010 2.251 7.181 7.181 0 01-1.249 3.1 3.377 3.377 0 01-2.814 1.516 2.094 2.094 0 01-.923-.259 1.339 1.339 0 00-.754-.214 1.362 1.362 0 00-.754.214 2.015 2.015 0 01-.912.259 3.377 3.377 0 01-2.814-1.52 6.911 6.911 0 01-1.272-3.1 6.573 6.573 0 010-2.251 5.042 5.042 0 01.788-2.037 2.578 2.578 0 011.666-1.126 4.39 4.39 0 011.722.18 7.057 7.057 0 011.587.63 7.384 7.384 0 011.6-.63 4.322 4.322 0 011.711-.18 2.566 2.566 0 011.643 1.171zm-4.075-1.666a2.87 2.87 0 01-.912.135h-.383a3.376 3.376 0 010-.833 2.251 2.251 0 01.6-1.429 2.026 2.026 0 01.968-.5 2.87 2.87 0 01.912-.135h.383v.394a2.972 2.972 0 01-.135.912 1.767 1.767 0 01-1.463 1.463z"
          fill="#fff"
        />
        <Circle
          data-name="Ellipse 419"
          cx={11.717}
          cy={11.717}
          r={11.717}
          transform="translate(41.881 45.644)"
          fill="#444155"
        />
        <Path
          data-name="Path 962"
          d="M109.771 82.85h-33.98a5.448 5.448 0 01-5.448-5.448v-.236a5.448 5.448 0 015.448-5.448h33.981c3.016 0-1.362 2.578-1.306 5.628s4.322 5.504 1.305 5.504z"
          fill="#fff"
          opacity={0.49}
        />
        <Path
          data-name="Path 963"
          d="M66.82 88.68v-.293a7.406 7.406 0 012.4-5.628 7.451 7.451 0 01-2.4-5.628v-.293a7.879 7.879 0 011.126-4.2 12.55 12.55 0 00-5.076-1.058h-.394c-3.23.1-4.21 1.339-8.183 1.339h-.664c-3.973 0-4.952-1.193-8.228-1.272h-.349a11.852 11.852 0 00-9.961 5.054 12.314 12.314 0 00-2.116 6.945v4.817a5.178 5.178 0 005.178 5.189h30.39a7.744 7.744 0 01-1.722-4.975z"
          fill="#444155"
        />
        <Path
          data-name="Path 964"
          d="M109.771 93.904h-33.98a5.448 5.448 0 01-5.448-5.448v-.225a5.459 5.459 0 015.448-5.459h33.981c3.016 0-1.362 2.578-1.306 5.628s4.322 5.504 1.305 5.504z"
          fill="#fff"
          opacity={0.49}
        />
        <Path
          data-name="Path 965"
          d="M109.771 82.85h-33.98a5.448 5.448 0 01-5.448-5.448v-.236a5.448 5.448 0 015.448-5.448h33.981c3.016 0-1.362 2.578-1.306 5.628s4.322 5.504 1.305 5.504z"
          fill="none"
          stroke="#444155"
          strokeMiterlimit={10}
          strokeWidth={1.36}
        />
        <Path
          data-name="Path 966"
          d="M109.771 93.904h-33.98a5.448 5.448 0 01-5.448-5.448v-.225a5.459 5.459 0 015.448-5.459h33.981c3.016 0-1.362 2.578-1.306 5.628s4.322 5.504 1.305 5.504z"
          fill="none"
          stroke="#444155"
          strokeMiterlimit={10}
          strokeWidth={1.36}
        />
        <Path
          data-name="Line 51"
          fill="none"
          stroke="#444155"
          strokeMiterlimit={10}
          strokeWidth={0.91}
          d="M76.64 75.034h26.811"
        />
        <Path
          data-name="Line 52"
          fill="none"
          stroke="#444155"
          strokeMiterlimit={10}
          strokeWidth={0.91}
          d="M76.64 78.926h26.811"
        />
        <Path
          data-name="Line 53"
          fill="none"
          stroke="#444155"
          strokeMiterlimit={10}
          strokeWidth={0.91}
          d="M76.64 86.408h26.811"
        />
        <Path
          data-name="Line 54"
          fill="none"
          stroke="#444155"
          strokeMiterlimit={10}
          strokeWidth={0.91}
          d="M76.64 90.3h26.811"
        />
        <Path
          data-name="Path 967"
          d="M110.525 55.663l-1.216 4.885a.225.225 0 01-.439 0l-1.238-4.885-4.85-1.216a.225.225 0 010-.439l4.851-1.213 1.227-4.874a.214.214 0 01.428 0l1.227 4.874 4.9 1.2a.225.225 0 010 .439z"
          fill="#fff"
        />
        <G data-name="Group 217">
          <Path
            data-name="Path 968"
            d="M105.099 50.502a.2.2 0 01.281-.281l1.745 1.4-.124.507-.507.124zm1.9 5.83l.124.507-1.733 1.4a.2.2 0 01-.281-.281l1.407-1.711zm4.21-4.21l-.124-.507 1.745-1.4a.2.2 0 01.281.281l-1.4 1.745zm1.9 5.819a.203.203 0 01-.281.293l-1.745-1.4.124-.507.507-.124z"
            fill="#fff"
            opacity={0.44}
          />
        </G>
        <Circle
          data-name="Ellipse 420"
          cx={50.086}
          cy={50.086}
          r={50.086}
          transform="translate(21.794 21.794)"
          fill="none"
          stroke="#fff"
          strokeMiterlimit={10}
          strokeWidth={0.92}
        />
        <Path
          data-name="Path 969"
          d="M103.121 120.908l5.628-1.193c.563 0-1.384 1.373-1.835 1.7a61.05 61.05 0 01-68.817.81c-.405-.27-2-1.036-1.531-1.126l4.5.439c10.024 9.973 44.838 13.485 62.055-.63z"
          fill="#afafc6"
        />
        <Circle
          data-name="Ellipse 421"
          cx={54.476}
          cy={54.476}
          r={54.476}
          transform="translate(17.404 17.404)"
          fill="none"
          stroke="#fff"
          strokeMiterlimit={10}
        />
        <Path
          data-name="Path 970"
          d="M57.522 109.068l-.777 3.084a.146.146 0 01-.281 0l-.777-3.111-3.118-.788a.135.135 0 010-.27l3.118-.777.777-3.118a.146.146 0 01.281 0l.777 3.118 3.118.777a.135.135 0 010 .27z"
          fill="#fff"
        />
        <G data-name="Group 218">
          <Path
            data-name="Path 971"
            d="M54.041 105.775a.127.127 0 11.18-.18l1.126.889-.079.326-.326.079zm1.216 3.726l.079.315-1.126.889c-.113.1-.27 0-.18-.18l.889-1.126zm2.69-2.69l-.079-.281 1.126-.889a.127.127 0 11.18.18l-.889 1.126zm1.216 3.714a.127.127 0 01-.18.18l-1.126-.884.079-.326.326-.079z"
            fill="#fff"
            opacity={0.44}
          />
        </G>
        <Path
          data-name="Path 972"
          d="M50.93 104.308l-.45 1.8c0 .079-.146.079-.158 0l-.45-1.8-1.79-.439a.09.09 0 010-.169l1.79-.439.45-1.8c0-.079.135-.079.158 0l.45 1.8 1.79.439a.09.09 0 010 .169z"
          fill="#fff"
        />
        <G data-name="Group 219">
          <Path
            data-name="Path 973"
            d="M48.925 102.444a.072.072 0 01.1-.1l.642.507v.191h-.18zm.7 2.139v.191l-.642.507a.072.072 0 01-.1-.1l.518-.642zm1.553-1.542v-.191l.63-.506c.079 0 .169 0 .113.1l-.519.646zm.7 2.139c0 .068 0 .158-.113.1l-.63-.507v-.191h.18z"
            fill="#fff"
            opacity={0.44}
          />
        </G>
      </G>
    </Svg>
  )
}

export default IconStudentLarge
