import { ActionSheetOptions, useActionSheet } from '@expo/react-native-action-sheet'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import * as React from 'react'
import {
  Platform,
  StyleProp,
  Text,
  TextStyle,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
  ViewStyle,
} from 'react-native'
import { ColorSchemeName } from 'react-native-appearance'
import { ms, ScaledSheet } from 'react-native-size-matters'
import useColumns from '~hooks/useColumns'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import opacity from '~utils/opacity'

export type Action = {
  enabled?: boolean
  destructive?: boolean
  name: string
  icon: IconProp
  action: () => void
}

export interface NavigationItemProps extends TouchableOpacityProps {
  title: string
  textStyle?: StyleProp<TextStyle>
  pressableStyle?: StyleProp<ViewStyle>
  activeTextStyle?: StyleProp<TextStyle>
  inactiveTextStyle?: StyleProp<TextStyle>
  activeButtonStyle?: StyleProp<ViewStyle>
  inactiveButtonStyle?: StyleProp<ViewStyle>
  background?: JSX.Element | undefined
  icon?: IconProp
  actions?: Action[]
  header?: JSX.Element | undefined
}

export function NavigationItem(props: NavigationItemProps): JSX.Element {
  const columns = useColumns()
  const { mode, theme } = useTheme()
  const styles = getStyles(mode, theme, columns)
  const { showActionSheetWithOptions } = useActionSheet()
  const {
    title,
    textStyle,
    style,
    pressableStyle = {},
    activeTextStyle = {},
    inactiveTextStyle = styles.inactiveTextStyle,
    activeButtonStyle = {},
    inactiveButtonStyle = styles.inactiveButtonStyle,
    disabled,
    background,
    icon = ['fal', 'users-class'],
    actions: providedActions,
    header,
    ...rest
  } = props

  const actions =
    providedActions?.filter((action) => action.enabled === undefined || action.enabled) ?? []

  const destructiveId = actions.findIndex((action) => action.destructive)

  const showActions = () => {
    const options = actions.map((action) => {
      return action.name
    })

    const icons = actions.map((action) => {
      return (
        <FontAwesomeIcon
          style={styles.defaultIcon}
          color={theme.black}
          size={ms(18, 0.3)}
          icon={action.icon}
        />
      )
    })

    const settings: ActionSheetOptions = {
      options: [...options, 'Cancel'],
      cancelButtonIndex: options.length,
      destructiveButtonIndex: destructiveId,
      icons: [
        ...icons,
        <FontAwesomeIcon
          style={styles.defaultIcon}
          color={theme.black}
          size={ms(18, 0.3)}
          icon={['fal', 'times-circle']}
        />,
      ],
    }

    showActionSheetWithOptions(settings, async (buttonIndex) => {
      if (actions && buttonIndex !== settings.cancelButtonIndex) {
        actions[buttonIndex].action()
      }
    })
  }

  const actionNode = actions.length ? (
    <TouchableOpacity style={styles.actionButton} onPress={showActions}>
      <FontAwesomeIcon
        style={styles.defaultIcon}
        color={theme.white}
        size={ms(18, 0.3)}
        icon={['fas', 'ellipsis-v']}
      />
    </TouchableOpacity>
  ) : null

  return (
    <View style={[styles.container, style]}>
      <TouchableOpacity
        style={[
          styles.pressable,
          pressableStyle,
          disabled ? inactiveButtonStyle : activeButtonStyle,
        ]}
        {...rest}>
        {header}
        <View style={styles.defaultBackgroundContainer}>
          {background ? (
            background
          ) : (
            <FontAwesomeIcon
              style={styles.defaultIcon}
              color={theme[400]}
              size={Platform.OS === 'web' ? ms(36) : ms(53)}
              icon={icon}
            />
          )}
          {actionNode}
        </View>
        <View style={styles.defaultTextContainer}>
          <Text
            numberOfLines={2}
            style={[
              styles.defaultTextStyle,
              textStyle,
              disabled ? inactiveTextStyle : activeTextStyle,
            ]}>
            {title}
          </Text>
        </View>
      </TouchableOpacity>
    </View>
  )
}

const getStyles = (mode: ColorSchemeName, theme: Theme, columns: number) =>
  ScaledSheet.create({
    container: {
      flex: 1 / columns,
      aspectRatio: 190 / 160,
      ...(Platform.OS === 'web' ? { minHeight: '140@mvs' } : {}),
    },
    pressable: {
      flex: 1,
      backgroundColor: theme[100],
      borderWidth: 1,
      borderColor: theme[100],
      borderRadius: '5@ms',
      marginHorizontal: '8@ms',
    },
    inactiveButtonStyle: {
      backgroundColor: theme[400],
      borderColor: theme[400],
    },
    defaultTextStyle: {
      fontSize: '10@ms0.4',
      fontWeight: '700',
      color: mode === 'dark' ? theme.text : theme[800],
      textAlign: 'center',
    },
    inactiveTextStyle: {
      color: theme[600],
    },
    defaultTextContainer: {
      backgroundColor: theme[300],
      paddingVertical: '4@mvs0.4',
      paddingHorizontal: '4@ms',
      width: '100%',
      height: '40@mvs',
      justifyContent: 'center',
      borderBottomLeftRadius: '5@ms',
      borderBottomRightRadius: '5@ms',
    },
    defaultBackgroundContainer: {
      flexGrow: 1,
      justifyContent: 'center',
      position: 'relative',
    },
    defaultIcon: {
      alignSelf: 'center',
    },
    actionButton: {
      position: 'absolute',
      top: '4@ms',
      right: '4@ms',
      backgroundColor: opacity(theme.pink[700], 0.5),
      width: '28@ms0.3',
      height: '28@ms0.3',
      borderRadius: '14@ms0.3',
      justifyContent: 'center',
    },
  })
