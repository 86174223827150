import * as ImagePicker from 'expo-image-picker'
import * as ImageManipulator from 'expo-image-manipulator'
import { storage } from '~providers/firebase'
import { AvatarImage } from '~components/AvatarImage'

export enum AvatarSource {
  Camera = 'camera',
  Gallery = 'gallery',
}

const getProfileURL = (userId: string | null | undefined): string | null => {
  if (!userId) {
    return null
  }

  return `https://firebasestorage.googleapis.com/v0/b/${
    storage().app.options.storageBucket
  }/o/${encodeURIComponent(`users/${userId}/thumbnail.jpg`)}?alt=media`
}

const captureAvatar = async (userId: string, source: AvatarSource): Promise<boolean> => {
  let pickerResult = null

  switch (source) {
    case AvatarSource.Camera: {
      const permissionResult = await ImagePicker.requestCameraPermissionsAsync()

      if (permissionResult.granted === false) {
        alert('Permission to access camera is required!')
        return false
      }

      pickerResult = await ImagePicker.launchCameraAsync({
        quality: 1,
        allowsEditing: true,
        aspect: [1, 1],
        exif: false,
      })
      break
    }
    case AvatarSource.Gallery: {
      const permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync()

      if (permissionResult.granted === false) {
        alert('Permission to access camera roll is required!')
        return false
      }

      pickerResult = await ImagePicker.launchImageLibraryAsync({
        quality: 1,
        allowsEditing: true,
        aspect: [1, 1],
        exif: false,
      })

      break
    }
    default:
      throw new Error('Unhandled source')
  }

  if (pickerResult.cancelled === true) {
    return false
  }

  const manipulated = await ImageManipulator.manipulateAsync(
    pickerResult.uri,
    [
      {
        resize:
          pickerResult.width > pickerResult.height
            ? {
                width: 128,
              }
            : {
                height: 128,
              },
      },
    ],
    // Compress as a JPEG
    { compress: 0.5, format: ImageManipulator.SaveFormat.JPEG },
  )

  // Upload it
  try {
    const ref = storage().ref(`users/${userId}`).child('thumbnail.jpg')
    const uploadResult = await ref.putFile(manipulated.uri)

    const url = getProfileURL(userId)
    if (url !== null) {
      await AvatarImage.cacheLocalFile(
        manipulated.uri,
        url,
        true,
        new Date(uploadResult.metadata.updated),
        new Date(uploadResult.metadata.timeCreated),
      )
      return true
    }
  } catch (error) {}
  return false
}

const removeAvatar = async (userId: string): Promise<boolean> => {
  try {
    const ref = storage().ref(`users/${userId}`).child('thumbnail.jpg')
    await ref.delete()

    const url = getProfileURL(userId)
    if (url !== null) {
      const fileSystem = AvatarImage.fileSystem()
      await fileSystem.unlink(fileSystem.getFileNameFromUrl(url))
    }

    return true
  } catch (error) {
    console.log(error.message)
  }
  return false
}

export default { getProfileURL, captureAvatar, removeAvatar }
