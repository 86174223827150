import React from 'react'
import { AnyAction, createAction, createReducer } from '@reduxjs/toolkit'

export interface State {
  editing: boolean
  selected: Array<string>
}

export const initialState: State = {
  editing: false,
  selected: [],
}

export const selectableAdd = createAction<string, 'selectableAdd'>('selectableAdd')
export const selectableRemove = createAction<string, 'selectableRemove'>('selectableRemove')
export const selectableReset = createAction('selectableReset')
export const selectableEditing = createAction<boolean, 'selectableEditing'>('selectableEditing')

const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(selectableAdd, (state, action) => {
      state.selected = [...state.selected, action.payload]
    })
    .addCase(selectableRemove, (state, action) => {
      state.selected = state.selected.filter((item) => item !== action.payload)
    })
    .addCase(selectableReset, (state, action) => {
      state.selected = []
    })
    .addCase(selectableEditing, (state, action) => {
      state.editing = action.payload
    }),
)

export const useSelectableReducer = (): [State, React.Dispatch<AnyAction>] =>
  React.useReducer(reducer, initialState)
