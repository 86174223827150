import { ActionSheetProvider } from '@expo/react-native-action-sheet'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faStars } from '@fortawesome/pro-duotone-svg-icons'
import {
  faCamera,
  faChevronLeft,
  faColumns,
  faFileImage,
  faFilePdf,
  faFlag as falFlag,
  faFolders,
  faFrown,
  faGlobe,
  faHandsHeart as falHandsHeart,
  faHome,
  faLockAlt,
  faMinusCircle,
  faPhotoVideo,
  faShieldCheck,
  faTimesCircle,
  faUsersClass,
  faWrench,
} from '@fortawesome/pro-light-svg-icons'
import {
  faCheck,
  faCircle,
  faCog,
  faCopy,
  faEdit,
  faEnvelopeOpen,
  faFlag,
  faList,
  faMinus,
  faPlus,
  faSearch,
  faShareAlt,
  faSignIn,
  faTrash,
  faTrashAlt,
  faUsersClass as farUsersClass,
  faWrench as farWrench,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faCheckCircle,
  faEllipsisV,
  faHandsHeart,
  faMicrophone,
  faPause,
  faPlay,
  faPlusCircle,
  faStop,
  faUser,
} from '@fortawesome/pro-solid-svg-icons'
import React from 'react'
import { LogBox } from 'react-native'
import { ThemeProvider } from 'react-native-elements'
import {
  DefaultTheme as PaperDefaultTheme,
  Provider as PaperProvider,
  Theme as PaperTheme,
} from 'react-native-paper'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { Provider } from 'react-redux'
import { RollbarErrorBoundary } from '~components/RollbarErrorBoundary'
import store from '~redux'
import AppNavigatorWithStore from '~routers'
import { useTheme } from '~theme/ThemeManager'

library.add(
  faPlus,
  faPlusCircle,
  faMinus,
  faCamera,
  faPhotoVideo,
  faMicrophone,
  faTimesCircle,
  faStop,
  faPlay,
  faPause,
  faTrash,
  faTrashAlt,
  faEdit,
  faShareAlt,
  faUser,
  faCog,
  faCheckCircle,
  faCircle,
  faSearch,
  faColumns,
  faFolders,
  faUsersClass,
  farUsersClass,
  faFilePdf,
  faCopy,
  faChevronLeft,
  faFileImage,
  faEllipsisV,
  faFlag,
  faCheck,
  falFlag,
  faShieldCheck,
  faStars,
  faMinusCircle,
  faEnvelopeOpen,
  faList,
  faHandsHeart,
  faFrown,
  falHandsHeart,
  faGlobe,
  faSignIn,
  faHome,
  faWrench,
  farWrench,
  faLockAlt,
)

if (LogBox) {
  LogBox.ignoreLogs([
    'Invalid source prop. <CacheableImage> props.source.uri should be a web accessible url with a valid protocol and host. NOTE: Default valid protocol is https, default valid hosts are *.',
    'Failed prop type: The prop `source` is marked as required in `_class`, but its value is `null`',
    'The AVPlayerItem instance has failed with the error code -1100 and domain "NSURLErrorDomain".',
  ])
}

// Workaround until react-native-elements 3.0.0 https://github.com/react-native-elements/react-native-elements/issues/2541
const theme = {
  colors: {
    platform: {
      default: {
        grey: '#FFF',
      },
    },
  },
}

const App = (): JSX.Element => {
  const { theme, mode } = useTheme()

  const paperTheme: PaperTheme = {
    ...PaperDefaultTheme,
    dark: mode === 'dark',
    mode: 'exact',
    colors: {
      ...PaperDefaultTheme.colors,
      primary: theme.primary,
      background: theme.white,
    },
    fonts: {
      ...PaperDefaultTheme.fonts,
      regular: {
        fontFamily: 'System',
        fontWeight: '400',
      },
      medium: {
        fontFamily: 'System',
        fontWeight: '600',
      },
      light: {
        fontFamily: 'System',
        fontWeight: '300',
      },
      thin: {
        fontFamily: 'System',
        fontWeight: '200',
      },
    },
  }

  return (
    <RollbarErrorBoundary>
      <Provider store={store}>
        <PaperProvider theme={paperTheme}>
          <ThemeProvider theme={theme}>
            <ActionSheetProvider>
              <SafeAreaProvider>
                <AppNavigatorWithStore />
              </SafeAreaProvider>
            </ActionSheetProvider>
          </ThemeProvider>
        </PaperProvider>
      </Provider>
    </RollbarErrorBoundary>
  )
}

export default App
