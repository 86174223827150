import * as React from 'react'

import {
  View,
  Text,
  TouchableOpacity,
  TouchableOpacityProps,
  StyleProp,
  TextStyle,
  ViewStyle,
} from 'react-native'
import { ColorSchemeName } from 'react-native-appearance'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import { ScaledSheet } from 'react-native-size-matters'

export interface FormButtonProps extends TouchableOpacityProps {
  title?: string
  textStyle?: StyleProp<TextStyle>
  activeTextStyle?: StyleProp<TextStyle>
  inactiveTextStyle?: StyleProp<TextStyle>
  activeButtonStyle?: StyleProp<ViewStyle>
  inactiveButtonStyle?: StyleProp<ViewStyle>
  icon?: () => React.ReactNode
}

export function FormButton(props: FormButtonProps): JSX.Element {
  const { mode, theme } = useTheme()
  const styles = getStyles(mode, theme)
  const {
    style,
    title,
    textStyle,
    activeTextStyle = {},
    inactiveTextStyle = styles.inactiveTextStyle,
    activeButtonStyle = {},
    inactiveButtonStyle = styles.inactiveButtonStyle,
    icon,
    disabled,
    ...rest
  } = props

  const iconNode = icon ? icon() : null

  const titleNode = title ? (
    <Text
      style={[styles.defaultTextStyle, textStyle, disabled ? inactiveTextStyle : activeTextStyle]}>
      {title}
    </Text>
  ) : null

  return (
    <TouchableOpacity
      style={[styles.buttonContainer, style, disabled ? inactiveButtonStyle : activeButtonStyle]}
      disabled={disabled}
      {...rest}>
      <>
        {iconNode}
        {titleNode}
      </>
    </TouchableOpacity>
  )
}

const getStyles = (mode: ColorSchemeName, theme: Theme) =>
  ScaledSheet.create({
    buttonContainer: {
      flexDirection: 'row',
      backgroundColor: theme.primary,
      borderWidth: 1,
      borderColor: theme.primary,
      alignItems: 'center',
      justifyContent: 'center',
      paddingVertical: '8@mvs0.4',
      paddingHorizontal: '16@ms',
      marginBottom: '16@mvs',
      borderRadius: '5@ms',
    },
    inactiveButtonStyle: {
      backgroundColor: theme[300],
      borderColor: theme[300],
    },
    defaultTextStyle: {
      fontSize: '12@ms',
      fontWeight: '500',
      color: theme.white,
    },
    inactiveTextStyle: {
      color: mode === 'dark' ? theme.text : theme.white,
    },
  })
