import { IStudent, IGroup } from '~types'

export interface GroupState {
  id: string
  name: string
  checked: Array<string>
}

export const RESET = '@@GROUP/RESET'
export const ADD_CHECKED = '@@GROUP/ADD_CHECKED'
export const REMOVE_CHECKED = '@@GROUP/REMOVE_CHECKED'
export const SET_NAME = '@@GROUP/SET_NAME'
export const SET_GROUP = '@@GROUP/SET_GROUP'

export interface ResetAction {
  type: typeof RESET
}

export interface AddCheckedAction {
  type: typeof ADD_CHECKED
  payload: IStudent
}

export interface RemoveCheckedAction {
  type: typeof REMOVE_CHECKED
  payload: IStudent
}

export interface SetNameAction {
  type: typeof SET_NAME
  payload: string
}

export interface SetGroupAction {
  type: typeof SET_GROUP
  payload: IGroup
}

export type GroupActionTypes =
  | ResetAction
  | AddCheckedAction
  | RemoveCheckedAction
  | SetNameAction
  | SetGroupAction
