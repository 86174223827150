import { useActionSheet } from '@expo/react-native-action-sheet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import React, { useState } from 'react'
import { Linking, Platform, StyleSheet, View } from 'react-native'
import { Avatar, ListItem } from 'react-native-elements'
import { Dialog, Paragraph } from 'react-native-paper'
import { useDispatch, useSelector } from 'react-redux'
import { AlertConfirmation } from '~components/AlertConfirmation'
import { AvatarImage } from '~components/AvatarImage'
import { FormButton } from '~components/FormButton'
import { ApplicationState } from '~redux'
import { logout } from '~redux/auth'
import ProfileService, { AvatarSource } from '~services/profile'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import { listStyle } from '../styles/list'

export default function SettingsScreen(): JSX.Element {
  const { mode, theme } = useTheme()
  const profile = useSelector((state: ApplicationState) => state.auth.profile)
  const user_id = useSelector((state: ApplicationState) => state.auth.user?.claims.sub)
  const dispatch = useDispatch()
  const [showRemoveAvatarConfirmation, setShowRemoveAvatarConfirmation] = useState(false)

  const styles = getStyles(theme)

  const { showActionSheetWithOptions } = useActionSheet()

  const showImagePickerActionSheet = async () => {
    if (!user_id) {
      return
    }

    if (Platform.OS === 'web' && !Modernizr.capture) {
      //TODO: Do not show 'remove image' option if one isn't set?
      const options = ['Choose Existing', 'Remove Image', 'Cancel']
      showActionSheetWithOptions(
        {
          options,
          cancelButtonIndex: options.length - 1,
        },
        async (buttonIndex) => {
          switch (buttonIndex) {
            case 0:
              await ProfileService.captureAvatar(user_id, AvatarSource.Camera)
              break
            case 1:
              setShowRemoveAvatarConfirmation(true)
              break
          }
        },
      )
    } else {
      const exists = await (async () => {
        const url = ProfileService.getProfileURL(user_id)
        if (url) {
          const fileSystem = AvatarImage.fileSystem()
          const path = fileSystem.getFileNameFromUrl(url)
          return await fileSystem.exists(path)
        }
        return false
      })()

      const options = [
        'Take Photo',
        'Choose Existing',
        ...(exists ? ['Remove Image'] : []),
        'Cancel',
      ]
      showActionSheetWithOptions(
        {
          options,
          cancelButtonIndex: options.length - 1,
          destructiveButtonIndex: exists ? options.length - 2 : undefined,
        },
        async (buttonIndex) => {
          switch (buttonIndex) {
            case 0:
              await ProfileService.captureAvatar(user_id, AvatarSource.Camera)
              break
            case 1:
              await ProfileService.captureAvatar(user_id, AvatarSource.Gallery)
              break
            case 2:
              if (!exists) {
                break
              }

              setShowRemoveAvatarConfirmation(true)
              break
          }
        },
      )
    }
  }

  const avatarPlaceholder = <FontAwesomeIcon color={theme[400]} size={38} icon={['fas', 'user']} />

  const openContactUs = () => {
    const url = 'https://chattalearning.com/contact/'
    if (Platform.OS === 'web') {
      window.open(url, '_blank')
    } else {
      Linking.openURL(url).catch((err) => console.error("Couldn't load page", err))
    }
  }

  return (
    <View style={styles.container}>
      <View style={styles.avatarContainer}>
        <Avatar
          rounded
          size={96}
          titleStyle={{ fontSize: 32 }}
          containerStyle={styles.avatar}
          placeholderStyle={styles.avatar}
          onPress={showImagePickerActionSheet}
          activeOpacity={0.6}>
          <View style={styles.placeholderContainer}>
            <AvatarImage
              style={{ width: '100%', height: '100%', borderRadius: 48 }}
              source={{
                uri: ProfileService.getProfileURL(user_id),
                cache: 'mutable',
              }}
              placeholder={avatarPlaceholder}
            />
          </View>
          <Avatar.Accessory size={28} />
        </Avatar>
        {showRemoveAvatarConfirmation && user_id && (
          <AlertConfirmation
            visible={showRemoveAvatarConfirmation}
            setVisible={setShowRemoveAvatarConfirmation}
            confirmAction={async () => await ProfileService.removeAvatar(user_id)}>
            <Dialog.Title>Delete Profile Image?</Dialog.Title>
            <Dialog.Content>
              <Paragraph>Deleting this image will remove it from your profile.</Paragraph>
            </Dialog.Content>
          </AlertConfirmation>
        )}
      </View>
      <ListItem
        topDivider={mode !== 'dark'}
        bottomDivider={mode !== 'dark'}
        containerStyle={{ backgroundColor: theme.white }}>
        <ListItem.Content>
          <ListItem.Title style={{ color: theme.black }}>Name</ListItem.Title>
        </ListItem.Content>
        <ListItem.Content right={true}>
          <ListItem.Title style={{ color: theme[400] }}>{profile?.displayName}</ListItem.Title>
        </ListItem.Content>
      </ListItem>
      <ListItem
        containerStyle={[listStyle.sectionHeaderContainer, { backgroundColor: theme[200] }]}
        topDivider
        bottomDivider>
        <ListItem.Content>
          <ListItem.Title style={[listStyle.sectionHeaderTitle, { color: theme.black }]}>
            Information
          </ListItem.Title>
        </ListItem.Content>
      </ListItem>
      <ListItem
        topDivider={mode !== 'dark'}
        bottomDivider={mode !== 'dark'}
        containerStyle={{ backgroundColor: theme.white }}
        onPress={openContactUs}>
        <ListItem.Content>
          <ListItem.Title style={{ color: theme.black }}>Contact Us</ListItem.Title>
        </ListItem.Content>
        <ListItem.Chevron />
      </ListItem>
      <View style={styles.logoutContainer}>
        <FormButton
          title="LOG OUT"
          style={styles.logoutButton}
          onPress={() => {
            dispatch(logout())
          }}
        />
      </View>
    </View>
  )
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: theme[100],
    },
    avatarContainer: {
      padding: 20,
      alignItems: 'center',
    },
    placeholderContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      width: 96,
      height: 96,
    },
    avatar: {
      backgroundColor: theme[300],
    },
    logoutContainer: {
      padding: 10,
      alignItems: 'center',
    },
    logoutButton: {
      borderRadius: 20,
      paddingHorizontal: 30,
    },
  })
