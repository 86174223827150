import { ColorSchemeName } from 'react-native-appearance'

type ThemeName = 'light' | 'dark'

export interface Theme {
  primary: string
  black: string
  white: string
  text: string
  pink: {
    '100': string
    '200': string
    '300': string
    '400': string
    '500': string
    '600': string
    '700': string
    '800': string
    '900': string
  }
  '100': string
  '200': string
  '300': string
  '400': string
  '500': string
  '600': string
  '700': string
  '800': string
  '900': string
}

export const defaultColors: Theme = {
  primary: '#E5007D',
  black: '#000000',
  white: '#FFFFFF',
  text: '#000000',
  pink: {
    '100': '#FCE6F2',
    '200': '#F9BFDF',
    '300': '#F599CB',
    '400': '#ED4DA4',
    '500': '#E5007D',
    '600': '#CE0071',
    '700': '#89004B',
    '800': '#670038',
    '900': '#450026',
  },
  '100': '#F1F0F4',
  '200': '#DCDBE3',
  '300': '#C7C5D2',
  '400': '#9C99B0',
  '500': '#726D8E',
  '600': '#676280',
  '700': '#444155',
  '800': '#333140',
  '900': '#22212B',
}

export const darkColors: Theme = {
  primary: defaultColors.primary,
  black: defaultColors.white,
  white: defaultColors.black,
  text: defaultColors[400],
  pink: {
    '100': defaultColors.pink[900],
    '200': defaultColors.pink[800],
    '300': defaultColors.pink[700],
    '400': defaultColors.pink[600],
    '500': defaultColors.pink[500],
    '600': defaultColors.pink[400],
    '700': defaultColors.pink[300],
    '800': defaultColors.pink[200],
    '900': defaultColors.pink[100],
  },
  '100': defaultColors[900],
  '200': defaultColors[800],
  '300': defaultColors[700],
  '400': defaultColors[600],
  '500': defaultColors[500],
  '600': defaultColors[400],
  '700': defaultColors[300],
  '800': defaultColors[200],
  '900': defaultColors[100],
}

const themes: Record<ThemeName, Theme> = {
  light: { ...defaultColors },
  dark: { ...darkColors },
}

export default themes

export const getTheme = (mode: ColorSchemeName): Theme => {
  const lookup = mode === 'dark' ? 'dark' : 'light'

  return themes[lookup]
}
