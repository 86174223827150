import * as React from 'react'

import { ms, ScaledSheet } from 'react-native-size-matters'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import { TextInputProps } from '~components/Themed'
import { TouchableOpacity } from 'react-native'
import * as WebBrowser from 'expo-web-browser'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { FormButton } from '~components/FormButton'
import { CreatedStudent, DownloadPDFResource } from '~api/types'
import { useAxios } from '~utils/fetch'

export interface GroupPDFButtonProps extends TextInputProps {
  classId: string
  students?: CreatedStudent[]
}

export default function GroupPDFButton({ classId, students }: GroupPDFButtonProps): JSX.Element {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const [{ data, loading, error }, execute] = useAxios<DownloadPDFResource>(
    {
      url: `/api/v1/groups/${classId}/pdf`,
      method: 'POST',
    },
    { manual: true },
  )

  const pdf = async () => {
    try {
      const {
        status,
        data: {
          data: { url },
        },
      } = await execute({
        data:
          students && students.length > 0
            ? {
                students,
              }
            : undefined,
      })

      if (status === 200) {
        WebBrowser.openBrowserAsync(url)
      } else {
        alert(status === 403 ? 'You do not have permission to request this file' : 'Unknown error')
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <TouchableOpacity onPress={() => pdf()}>
      <FontAwesomeIcon
        style={styles.setupActionIcon}
        color={theme[400]}
        size={ms(100)}
        icon={['fal', 'file-pdf']}
      />
      <FormButton onPress={() => pdf()} title="Download PDF" style={styles.button} />
    </TouchableOpacity>
  )
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    setupActionIcon: {
      marginBottom: '10@s',
      alignSelf: 'center',
    },
    button: {
      alignSelf: 'center',
    },
  })
