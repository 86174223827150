import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import { makeUseAxios } from 'axios-hooks'
import { ForbiddenError } from '~errors/ForbiddenError'
import { ValidationError } from '~errors/ValidationError'
import getIdToken from '~utils/getIdToken'

const onRejected = (error: any) => {
  const axiosError = error as AxiosError<any>

  if (axiosError.response) {
    switch (axiosError.response.status) {
      case 403:
        return Promise.reject(new ForbiddenError(axiosError.response))
      case 422:
        return Promise.reject(new ValidationError(axiosError.response))
    }

    if (axiosError.response.data?.message) {
      throw new Error(axiosError.response.data.message)
    }
  }

  return Promise.reject(axiosError)
}

export const client = axios.create({
  baseURL: process.env.EXPO_API_HOSTNAME,
  headers: {
    Accept: 'application/json',
  },
})

client.interceptors.request.use(async (config) => {
  const token = await getIdToken()
  config.headers.Authorization = `Bearer ${token}`
  return config
}, onRejected)

client.interceptors.response.use(undefined, onRejected)

export const clientUnauthenticated = axios.create({
  baseURL: process.env.EXPO_API_HOSTNAME,
  headers: {
    Accept: 'application/json',
  },
})

clientUnauthenticated.interceptors.response.use(undefined, onRejected)

const fetch = async <T>(client: AxiosInstance, url: string) => {
  return await client.get<any, AxiosResponse<T>>(url).then((response) => response.data)
}

export default async function <T>(url: string): Promise<T> {
  return fetch<T>(client, url)
}

export const fetchUnauthenticated = async <T>(url: string): Promise<T> =>
  fetch<T>(clientUnauthenticated, url)

export const useAxios = makeUseAxios({
  axios: client,
})

export const useAxiosUnauthenticated = makeUseAxios({
  axios: clientUnauthenticated,
})
