import { Reducer } from 'redux'
import {
  GroupState,
  GroupActionTypes,
  ADD_CHECKED,
  REMOVE_CHECKED,
  RESET,
  SET_NAME,
  SET_GROUP,
} from './types'

const initialState: GroupState = {
  id: '',
  name: '',
  checked: [],
}

const reducer: Reducer<GroupState, GroupActionTypes> = (
  state = initialState,
  action: GroupActionTypes,
) => {
  switch (action.type) {
    case RESET: {
      return initialState
    }
    case ADD_CHECKED: {
      return {
        ...state,
        checked: [...state.checked, action.payload.key],
      }
    }
    case REMOVE_CHECKED: {
      return {
        ...state,
        checked: state.checked.filter((item) => item !== action.payload.key),
      }
    }
    case SET_NAME: {
      return {
        ...state,
        name: action.payload,
      }
    }
    case SET_GROUP: {
      const { key, name, students } = action.payload
      return {
        ...state,
        id: key,
        name,
        checked: (students || []).map((item) => item.key),
      }
    }
    default:
      return state
  }
}

export { reducer as groupReducer }
