import { RESET_STATE } from '@redux-offline/redux-offline/lib/constants'
import { createReducer } from '@reduxjs/toolkit'
import { Platform } from 'react-native'
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable'
import { EMPTY, from, of } from 'rxjs'
import { catchError, delayWhen, filter, ignoreElements, map, mergeMap } from 'rxjs/operators'
import { CacheableAudioRecording } from '~components/CacheableAudioRecording'
import { CacheableImage } from '~components/CacheableImage'
import { firestore } from '~providers/firebase'
import { ApplicationState } from '~redux'
import ChatService, { initialChatSection } from '~services/chat'
import { AudioRecordingType } from '~types'
import urlInfo from '~utils/urlInfo'
import {
  attachAudioCommit,
  attachAudioFulfilled,
  attachAudioReject,
  attachAudioRollback,
  attachImageCommit,
  attachImageFulfilled,
  attachImageReject,
  attachImageRollback,
  detachAudioCommit,
  detachAudioFulfilled,
  detachAudioRequest,
  detachImageCommit,
  detachImageFulfilled,
  detachImageRequest,
  setChat,
  setChatAudio,
  setChatImage,
  setImageAnimation,
  setIsPlaying,
  setIsRecording,
  setSelectedObject,
} from './actions'
import { AlreadyUpdatedError } from './errors'
import { ChatIdentifier, ChatState, PendingChat } from './types'

const initialState: ChatState = {
  isRecording: false,
  isPlaying: false,
  chat: {
    id: '',
    path: '',
    name: '',
    level: '',
    layout: {
      rows: 1,
      columns: 1,
    },
    sections: [],
    created_at: '',
    author_id: '',
    author_name: '',
    classId: '',
    isAuthor: false,
  },
  selectedObject: -1,
  pending: {},
}

interface UpdatePendingImageOptions {
  chat: ChatIdentifier
  index: number
  local?: string // Match the pending local value before making any changes
  remote: string // The url the pending request resolved to
}

interface UpdatePendingAudioOptions {
  chat: ChatIdentifier
  index: number
  local?: string // Match the pending local value before making any changes
  remote: string // The url the pending request resolved to
  type: AudioRecordingType
}

// TODO: immer
const removePendingImage = (state: ChatState, options: UpdatePendingImageOptions) => {
  const {
    chat: { path },
    index,
    local,
    remote,
  } = options

  const newPending = Object.entries(state.pending).reduce(
    (newObj: typeof state.pending, [key, val]) => {
      if (key === path) {
        const { image, audio } = val
        const updated = {
          image: Object.entries(image).reduce((acc, [k, v]) => {
            // Filter out pending
            if (k === index.toString() && (local === undefined || local === v)) {
              return acc
            }

            return {
              ...acc,
              [k]: v,
            }
          }, {}),
          audio,
        }

        if (Object.keys(updated.image).length > 0 || Object.keys(updated.audio).length > 0) {
          newObj[key] = updated
        }
      } else {
        newObj[key] = val
      }

      return newObj
    },
    {},
  )

  return {
    ...state,
    pending: newPending,
  }
}

// TODO: immer
const removePendingAudio = (state: ChatState, options: UpdatePendingAudioOptions) => {
  const {
    chat: { path },
    index,
    local,
    remote,
    type,
  } = options

  const newPending = Object.entries(state.pending).reduce(
    (newObj: typeof state.pending, [key, val]) => {
      if (key === path) {
        const { image, audio } = val
        const updated = {
          image,
          audio: Object.entries(audio).reduce((acc, [k, v]) => {
            if (k === index.toString() && (local === undefined || local === v[type])) {
              switch (type) {
                case 'student': {
                  const { teacher } = v
                  if (teacher) {
                    return {
                      ...acc,
                      [k]: {
                        teacher,
                        [type]: '',
                      },
                    }
                  }
                  break
                }
                case 'teacher': {
                  const { student } = v
                  if (student) {
                    return {
                      ...acc,
                      [k]: {
                        student,
                        [type]: '',
                      },
                    }
                  }
                  break
                }
                default:
                  throw new Error('AudioRecordingType not handled')
              }
              return acc
            }

            return {
              ...acc,
              [k]: v,
            }
          }, {}),
        }

        if (Object.keys(updated.image).length > 0 || Object.keys(updated.audio).length > 0) {
          newObj[key] = updated
        }
      } else {
        newObj[key] = val
      }

      return newObj
    },
    {},
  )

  return {
    ...state,
    pending: newPending,
  }
}

const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setChat.fulfilled, (state, action) => {
      state.isRecording = false
      state.chat = action.payload

      // If previously selected chat image doesn't exist reset selectedObject
      if (
        state.selectedObject !== -1 &&
        state.chat.sections[state.selectedObject].image.src === initialChatSection.image.src
      ) {
        state.selectedObject = -1
      }
    })
    .addCase(setSelectedObject, (state, action) => {
      state.selectedObject = action.payload
    })
    .addCase(setChatImage, (state, action) => {
      // An image has been queued for upload so we need to remember the local path to use until it completes
      const {
        payload: {
          chat: { path },
          uri,
          index,
        },
      } = action

      const newPending = state.pending[path] || {
        image: {},
        audio: {},
      }

      newPending.image[index] = uri

      state.pending[path] = newPending
    })
    .addCase(setChatAudio, (state, action) => {
      // An image has been queued for upload so we need to remember the local path to use until it completes
      const {
        payload: {
          chat: { path },
          uri,
          index,
          type,
        },
      } = action

      const current = state.pending[path] || {
        image: {},
        audio: {},
      }

      const updated: PendingChat = {
        image: current.image,
        audio: {
          ...current.audio,
          [index]: {
            ...current.audio[index],
            [type]: uri,
          },
        },
      }

      state.pending[path] = updated
    })
    .addCase(attachImageFulfilled, (state, action) => {
      return removePendingImage(state, action.payload)
    })
    .addCase(setIsRecording, (state, action) => {
      state.isRecording = action.payload
    })
    .addCase(setIsPlaying, (state, action) => {
      state.isPlaying = action.payload
    })
    .addCase(RESET_STATE, (state, action) => {
      state.pending = initialState.pending
    })
    .addCase(detachImageRequest, (state, action) => {
      // An image has been queued for upload so we need to remember the local path to use until it completes
      const {
        payload: { chat, index },
      } = action

      return {
        ...removePendingImage(state, {
          chat,
          index,
          remote: '',
        }),
        selectedObject: -1,
      }
    })
    .addCase(detachAudioRequest, (state, action) => {
      // An image has been queued for upload so we need to remember the local path to use until it completes
      const {
        payload: { chat, index, type },
      } = action

      return removePendingAudio(state, {
        chat,
        index,
        remote: '',
        type,
      })
    })
    .addCase(attachAudioFulfilled, (state, action) => {
      // Upload has finished remove it from pending
      const { chat, index, local, remote, type } = action.payload

      return removePendingAudio(state, {
        chat,
        index,
        local,
        remote,
        type,
      })
    })
    .addCase(attachAudioRollback, (state, action) => {
      const {
        meta: { chat, index, local, type },
      } = action

      return removePendingAudio(state, {
        chat,
        index,
        local,
        remote: '',
        type,
      })
    })
    .addCase(attachImageRollback, (state, action) => {
      const {
        meta: { chat, index, local },
      } = action

      return removePendingImage(state, {
        chat,
        index,
        local,
        remote: '',
      })
    }),
)

const attachAudioEpic = (action$: ActionsObservable<ReturnType<typeof attachAudioCommit>>) =>
  action$.pipe(
    filter(attachAudioCommit.match),
    // Don't do anything for web, cloud functions will convert the file and update the document
    filter((action) => action.meta.extension === '.m4a'),
    mergeMap((action) => {
      const documentRef = firestore().doc(action.meta.chat.path)

      return from(
        firestore().runTransaction((transaction) => {
          return transaction.get(documentRef).then((doc) => {
            if (!doc.exists) {
              throw new Error('Document does not exist')
            }

            const chat = ChatService.chatFromSnapshot(doc)
            const existing = chat.sections[action.meta.index]?.audio[action.meta.type]?.src ?? ''
            const timestampExisting = urlInfo(existing).basename.split('_').pop() ?? 0
            const timestampUpdated = urlInfo(action.payload.url).basename.split('_').pop() ?? 0

            // Latest wins
            if (timestampUpdated < timestampExisting) {
              throw new AlreadyUpdatedError(existing)
            } else {
              transaction.update(documentRef, {
                [`sections.${action.meta.index}.audio.${action.meta.type}.src`]: action.payload.url,
                updated_at: firestore.FieldValue.serverTimestamp(),
              })

              return action.payload.url
            }
          })
        }),
      ).pipe(
        delayWhen(() =>
          from(
            Platform.OS === 'ios'
              ? CacheableImage.cacheLocalFile(action.meta.local, action.payload.url, true)
              : Promise.resolve(),
          ),
        ),
        map(() =>
          attachAudioFulfilled(
            action.meta.chat,
            action.meta.index,
            action.meta.local,
            action.payload.url,
            action.meta.type,
          ),
        ),
        catchError((error) => {
          if (error instanceof AlreadyUpdatedError) {
            const {
              payload: { path, filename },
              meta: { chat, index, type },
            } = action
            return of(
              attachAudioReject({
                chat,
                index,
                remote: error.value, // TODO: Should be handled by the chat subscription
                type,
                path,
                filename,
              }),
            )
          }
          return EMPTY
        }),
      )
    }),
  )

const detachAudioEpic = (action$: ActionsObservable<ReturnType<typeof detachAudioCommit>>) =>
  action$.pipe(
    filter(detachAudioCommit.match),
    mergeMap((action) =>
      from(
        firestore()
          .doc(action.payload.chat.path)
          .update({
            [`sections.${action.payload.index}.audio.${action.payload.type}.src`]: '',
            updated_at: firestore.FieldValue.serverTimestamp(),
          }),
      ).pipe(map(() => detachAudioFulfilled())),
    ),
  )

const attachImageEpic = (action$: ActionsObservable<ReturnType<typeof attachImageCommit>>) =>
  action$.pipe(
    filter(attachImageCommit.match),
    mergeMap((action) => {
      const documentRef = firestore().doc(action.meta.chat.path)

      return from(
        firestore().runTransaction((transaction) => {
          return transaction.get(documentRef).then((doc) => {
            if (!doc.exists) {
              throw new Error('Document does not exist')
            }

            const chat = ChatService.chatFromSnapshot(doc)
            const existing = chat.sections[action.meta.index]?.image?.src ?? ''
            const timestampExisting = urlInfo(existing).basename.split('_').pop() ?? 0
            const timestampUpdated = urlInfo(action.payload.url).basename.split('_').pop() ?? 0

            // Latest wins
            if (timestampUpdated < timestampExisting) {
              throw new AlreadyUpdatedError(existing)
            } else {
              transaction.update(documentRef, {
                [`sections.${action.meta.index}.image.src`]: action.payload.url,
                updated_at: firestore.FieldValue.serverTimestamp(),
              })

              return action.payload.url
            }
          })
        }),
      ).pipe(
        delayWhen(() =>
          from(
            Platform.OS === 'ios'
              ? CacheableAudioRecording.cacheLocalFile(action.meta.local, action.payload.url, true)
              : Promise.resolve(),
          ),
        ),
        map(() =>
          attachImageFulfilled(
            action.meta.chat,
            action.meta.index,
            action.meta.local,
            action.payload.url,
          ),
        ),
        catchError((error) => {
          if (error instanceof AlreadyUpdatedError) {
            return of(
              attachImageReject({
                chat: action.meta.chat,
                index: action.meta.index,
                remote: error.value, // TODO: Should be handled by the chat subscription
                path: action.payload.path,
                filename: action.payload.filename,
              }),
            )
          }
          return EMPTY
        }),
      )
    }),
  )

const detachImageEpic = (action$: ActionsObservable<ReturnType<typeof detachImageCommit>>) =>
  action$.pipe(
    filter(detachImageCommit.match),
    mergeMap((action) =>
      from(
        firestore()
          .doc(action.payload.chat.path)
          .update({
            [`sections.${action.payload.index}.image`]: initialChatSection.image,
            updated_at: firestore.FieldValue.serverTimestamp(),
          }),
      ).pipe(map(() => detachImageFulfilled())),
    ),
  )

// TODO: Type this and move it to the thunk, pending can do the local state update
const setImageAnimationEpic = (
  action$: ActionsObservable<ReturnType<typeof setImageAnimation>>,
  state$: StateObservable<ApplicationState>,
) =>
  action$.pipe(
    filter(setImageAnimation.match),
    map((action) => {
      const updates: Record<string, any> = {}

      if (action.payload.scale !== undefined) {
        updates[`sections.${action.payload.index}.image.scale`] = action.payload.scale
      }
      if (action.payload.translateX !== undefined) {
        updates[`sections.${action.payload.index}.image.translateX`] = action.payload.translateX
      }
      if (action.payload.translateY !== undefined) {
        updates[`sections.${action.payload.index}.image.translateY`] = action.payload.translateY
      }

      if (Object.keys(updates).length > 0) {
        updates[`updated_at`] = firestore.FieldValue.serverTimestamp()
      }

      return {
        path: action.payload.path,
        updates,
      }
    }),
    filter((action) => Object.keys(action.updates).length > 0),
    map(({ path, updates }) => from(firestore().doc(path).update(updates))),
    ignoreElements(),
  )

const epic = combineEpics(
  attachAudioEpic,
  detachAudioEpic,
  attachImageEpic,
  detachImageEpic,
  setImageAnimationEpic,
)

export { reducer as chatReducer, epic as chatEpic }
