import { StyleSheet } from 'react-native'

export const listStyle = StyleSheet.create({
  container: {
    flex: 1,
  },
  sectionHeaderContainer: {
    paddingVertical: 5,
  },
  sectionHeaderTitle: {
    fontSize: 14,
    fontWeight: '500',
  },
  sectionContentTitle: {
    fontSize: 17,
  },
  sectionContentSubtitle: {
    fontSize: 13,
    fontWeight: '300',
    marginTop: 5,
  },
})
