import { LayoutChangeEvent } from 'react-native'
import { useState, useCallback } from 'react'

const useComponentSize = (): [
  {
    width: number
    height: number
  },
  (event: LayoutChangeEvent) => void,
] => {
  const [size, setSize] = useState<{
    width: number
    height: number
  }>({ width: 0, height: 0 })

  const onLayout = useCallback((event: LayoutChangeEvent) => {
    const { width, height } = event.nativeEvent.layout
    setSize({ width, height })
  }, [])

  return [size, onLayout]
}

export default useComponentSize
