import { FirebaseFirestoreTypes } from '@react-native-firebase/firestore'

const findIndexOfDocument = (
  doc: FirebaseFirestoreTypes.QueryDocumentSnapshot,
  items: FirebaseFirestoreTypes.DocumentData[],
): number =>
  items.findIndex((item) => {
    return item.ref.path === doc.ref.path
  })

export const updateItem = (
  doc: FirebaseFirestoreTypes.QueryDocumentSnapshot,
  items: FirebaseFirestoreTypes.DocumentData[],
): void => {
  const i = findIndexOfDocument(doc, items)
  items[i] = doc
}

export const deleteItem = (
  doc: FirebaseFirestoreTypes.QueryDocumentSnapshot,
  items: FirebaseFirestoreTypes.DocumentData[],
): void => {
  const i = findIndexOfDocument(doc, items)
  items.splice(i, 1)
}

export const addItem = (
  doc: FirebaseFirestoreTypes.QueryDocumentSnapshot,
  items: FirebaseFirestoreTypes.DocumentData[],
): void => {
  const i = findIndexOfDocument(doc, items)
  if (i === -1) {
    items.push(doc)
  }
}
