import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import React from 'react'
import { Clipboard, Modal, StyleSheet, TouchableOpacity } from 'react-native'
import { FormButton } from '~components/FormButton'
import { FormInput } from '~components/FormInput'
import { Text, View } from '~components/Themed'
import { windowWidth } from '~constants/Layout'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'

export interface ShareChatPopupProps {
  visible: boolean
  setVisible: (visible: boolean) => void
  url: string
}

export default function ShareChatPopup({
  visible,
  setVisible,
  url,
}: ShareChatPopupProps): JSX.Element {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const dismiss = () => setVisible(false)

  const copy = () => {
    Clipboard.setString(url)
  }

  return (
    <View>
      <Modal visible={visible} onDismiss={dismiss} transparent={true}>
        <View style={styles.centeredView}>
          <View style={styles.childContainer}>
            <View>
              <Text style={styles.headerStyle}>Share Chat</Text>
            </View>

            <TouchableOpacity
              style={{ position: 'absolute', right: 10, top: 10 }}
              onPress={dismiss}>
              <View>
                <FontAwesomeIcon size={36} icon={['fal', 'times-circle']} />
              </View>
            </TouchableOpacity>

            <Text style={{ textAlign: 'center' }}>Use this url to share the chat</Text>
            <FormInput value={url} placeholder="Chat URL" editable={false} style={styles.input} />

            <FormButton title="Copy URL" onPress={copy} />
          </View>
        </View>
      </Modal>
    </View>
  )
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    centeredView: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
    childContainer: {
      backgroundColor: theme.white,
      borderColor: theme[200],
      borderRadius: 20,
      padding: 20,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      elevation: 5,
      alignSelf: 'center',
      minWidth: Math.max(300, windowWidth * 0.4),
      borderWidth: 1,
    },
    openButton: {
      backgroundColor: '#F194FF',
      borderRadius: 20,
      padding: 10,
      elevation: 2,
    },
    headerStyle: {
      marginBottom: 20,
      fontWeight: 'bold',
      textAlign: 'center',
    },
    list: {
      flexGrow: 0,
      alignSelf: 'center',
      marginVertical: 20,
    },
    textStyle: {
      color: 'white',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    listIcon: {
      margin: 20,
    },
    input: {
      padding: 10,
      marginTop: 5,
      marginBottom: 10,
      fontSize: 16,
      borderRadius: 8,
      borderWidth: 1,
    },
  })
