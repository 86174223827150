import AsyncStorage from '@react-native-async-storage/async-storage'
import { FirebaseAuthTypes } from '@react-native-firebase/auth'
import { RESET_STATE } from '@redux-offline/redux-offline/lib/constants'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import merge from 'lodash/merge'
import { Platform } from 'react-native'
import { Action } from 'redux'
import { combineEpics } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { filter, map, switchMap } from 'rxjs/operators'
import { SETTINGS_ACCEPTED_TERMS_AND_CONDITIONS_VERSION } from '~constants/Settings'
import { analytics, auth, crashlytics, remoteConfig } from '~providers/firebase'
import { Profile, UserClaims, UserWithClaims } from '~types'
import defaultConfig from '../../config'

export interface AuthState {
  readonly isAuthenticated: boolean
  readonly user: UserWithClaims | null
  readonly profile: Profile
  readonly termsAndConditions: string
}

const initialProfile: Profile = {
  displayName: '',
  count_classes: 0,
  permissions: {
    restrictions_max_classes: defaultConfig.restrictions_max_classes,
    restrictions_max_students_per_classes: defaultConfig.restrictions_max_students_per_classes,
    restrictions_max_chats_per_class: defaultConfig.restrictions_max_chats_per_class,
  },
}

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  profile: initialProfile,
  termsAndConditions: defaultConfig.terms_and_conditions_version,
}

export const acceptTermsAndConditions = createAsyncThunk('auth/terms_and_conditions', async () => {
  const version = remoteConfig().getString('terms_and_conditions_version')

  await AsyncStorage.setItem(SETTINGS_ACCEPTED_TERMS_AND_CONDITIONS_VERSION, version)

  return version
})

export const logout = createAsyncThunk('auth/logout', async () => {
  await auth().signOut()

  // Microsoft MSAL stores in sessionStorage
  if (Platform.OS === 'web') {
    try {
      sessionStorage.removeItem('msal.idtoken')
    } catch (error) {
      console.log(error)
    }
  }
})

export const getAuth = createAsyncThunk(
  'auth/get_auth',
  async (user: FirebaseAuthTypes.User | null) => {
    if (user == null) {
      throw new Error('Not logged in')
    }

    const result = await user.getIdTokenResult()

    return {
      ...user.toJSON(),
      claims: result.claims as UserClaims,
    }
  },
)

const reducer = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authStateChanged(state, action: PayloadAction<UserWithClaims | undefined>) {
      // TODO: Shouldn't happen in reducer
      crashlytics().setUserId(action.payload?.claims.sub ?? '')
      analytics().setUserId(action.payload?.claims.sub ?? null)
      // analytics().setUserProperties({
      //   customer_id: action.payload?.claims.customer_id ?? null,
      // })
      state.isAuthenticated = action.payload !== undefined
      state.user = action.payload ?? null
    },
    setProfile(state, action: PayloadAction<Profile | undefined>) {
      state.profile = merge(
        {},
        initialProfile,
        {
          permissions: {
            restrictions_max_classes: remoteConfig().getNumber('restrictions_max_classes'),
            restrictions_max_students_per_classes: remoteConfig().getNumber(
              'restrictions_max_students_per_classes',
            ),
            restrictions_max_chats_per_class: remoteConfig().getNumber(
              'restrictions_max_chats_per_class',
            ),
          },
        },
        action.payload,
      )
    },
    setTermsAndConditions(state, action: PayloadAction<string>) {
      state.termsAndConditions = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(acceptTermsAndConditions.fulfilled, (state, action) => {
        state.termsAndConditions = action.payload
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.isAuthenticated = false
        state.user = null
      })
  },
})

const getAuthFulfilledEpic = (action$: Observable<Action>) =>
  action$.pipe(
    filter(getAuth.fulfilled.match),
    switchMap((action) => {
      if (action.payload.claims.aud !== auth().app.options.projectId) {
        // Logout and clear outbox
        return of(logout(), { type: RESET_STATE })
      } else {
        return of(authStateChanged(action.payload))
      }
    }),
  )

const getAuthRejectedEpic = (action$: Observable<Action>) =>
  action$.pipe(
    filter(getAuth.rejected.match),
    map(() => authStateChanged(undefined)),
  )

const epic = combineEpics(getAuthFulfilledEpic, getAuthRejectedEpic)
export const { authStateChanged, setProfile, setTermsAndConditions } = reducer.actions
export const authReducer = reducer.reducer

export { epic as authEpic }
