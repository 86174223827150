import { auth } from '~providers/firebase'

export default (): Promise<string> => {
  const user = auth().currentUser

  if (user === null) {
    return Promise.reject()
  }

  return user.getIdToken()
}
