import { useNavigation } from '@react-navigation/native'
import * as React from 'react'
import { useState } from 'react'
import { TouchableOpacityProps } from 'react-native'
import { Dialog, Paragraph } from 'react-native-paper'
import { Action, NavigationItem } from '~components/NavigationItem'
import usePermission from '~hooks/usePermission'
import { Role } from '~redux/recent/types'
import { ChatTemplate } from '~types'
import { useAxios } from '~utils/fetch'
import { AlertConfirmation } from './AlertConfirmation'

export interface ClassNavigationItemProps extends TouchableOpacityProps {
  id: string
  name: string
  template?: ChatTemplate
  role: Role
  chats_count?: number
  isDashboard?: boolean
}

export function ClassNavigationItem(props: ClassNavigationItemProps): JSX.Element {
  const { id, name, template, role, chats_count = 0, isDashboard = false } = props
  const navigation = useNavigation()
  const permission_create_chat = usePermission('restrictions_max_chats_per_class', chats_count)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  const onPress = () => {
    // TODO: Consider confirmation?
    if (template) {
      if (permission_create_chat.status === 'allowed') {
        navigation.navigate('NewChatModal', {
          classId: id,
          template: template,
        })
      } else {
        navigation.navigate('PermissionDeniedModal', {
          message: permission_create_chat.message,
        })
      }
    } else {
      navigation.navigate('Main', {
        screen: 'Classes',
        params: {
          screen: 'Class',
          initial: false,
          params: {
            id,
            name,
          },
        },
      })
    }
  }

  const [{ data, loading, error }, execute] = useAxios<never>(
    {
      url: `/api/v1/groups/${id}`,
      method: 'DELETE',
    },
    { manual: true },
  )

  const deleteClass = async () => {
    if (role !== 'teacher') {
      return
    }

    try {
      const { status, data } = await execute()
    } catch (error) {
      console.info(error)
    }
  }

  const actions: Action[] = [
    {
      enabled: role === 'teacher',
      name: 'Edit class settings',
      icon: ['far', 'wrench'],
      action: () =>
        navigation.navigate('ClassSettingsModal', {
          id,
        }),
    },
    {
      enabled: role === 'teacher',
      name: 'Invite students',
      icon: ['far', 'envelope-open'],
      action: () =>
        navigation.navigate('ClassSettingsModal', {
          id,
          view: 'students',
          subview: 'create',
        }),
    },
    {
      enabled: !isDashboard && role === 'teacher',
      name: 'Delete Class',
      icon: ['far', 'trash'],
      destructive: true,
      action: () => {
        setShowDeleteConfirmation(true)
      },
    },
  ]

  return (
    <>
      <NavigationItem title={name} onPress={onPress} actions={actions} />
      {showDeleteConfirmation && (
        <AlertConfirmation
          visible={showDeleteConfirmation}
          setVisible={setShowDeleteConfirmation}
          confirmAction={deleteClass}>
          <Dialog.Title>Delete Chat?</Dialog.Title>
          <Dialog.Content>
            <Paragraph>
              Deleting this class will remove it from your account and it will no longer be visible
              to anyone else.
            </Paragraph>
          </Dialog.Content>
        </AlertConfirmation>
      )}
    </>
  )
}
