import { AxiosResponse } from 'axios'

export class ValidationError extends Error {
  public readonly name = 'ValidationError'

  public readonly errors: Record<string, string[]>

  constructor(public response: AxiosResponse<any>) {
    super(response.data.message)
    Object.setPrototypeOf(this, ValidationError.prototype)
    this.errors = response.data.errors
  }
}
