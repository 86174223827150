import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import TermsAndConditionsScreen from '~screens/TermsAndConditionsScreen'

const Stack = createStackNavigator()

export default function TermsAndConditionsStack(): JSX.Element {
  return (
    <Stack.Navigator initialRouteName="Terms and Conditions">
      <Stack.Screen
        name="Terms and Conditions"
        component={TermsAndConditionsScreen}
        options={{ header: () => null }}
      />
    </Stack.Navigator>
  )
}
