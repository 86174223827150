import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { CompositeNavigationProp, RouteProp } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React from 'react'
import { Dimensions, FlatList, ListRenderItemInfo, Platform } from 'react-native'
import { ms, ScaledSheet } from 'react-native-size-matters'
import { useSWRInfinite } from 'swr'
import { CommunityChatCategoryResource, CommunityChatCategoryResourceCollection } from '~api/types'
import { CacheableImage } from '~components/CacheableImage'
import ChattaCommunitySearch from '~components/ChattaCommunitySearch'
import Loading from '~components/Loading'
import { NavigationItem } from '~components/NavigationItem'
import RenderError from '~components/RenderError'
import { View } from '~components/Themed'
import useColumns from '~hooks/useColumns'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import { MainStackParamList, RootStackParamList } from '~types'
import opacity from '~utils/opacity'

type Props = {
  navigation: CompositeNavigationProp<
    StackNavigationProp<RootStackParamList>,
    StackNavigationProp<MainStackParamList, 'ChattaCommunityCategories'>
  >
  route: RouteProp<MainStackParamList, 'ChattaCommunityCategories'>
}

export default function ChattaCommunityCategoriesScreen({ navigation, route }: Props): JSX.Element {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const columns = useColumns()

  const { data, size, setSize, isValidating } =
    useSWRInfinite<CommunityChatCategoryResourceCollection>((pageIndex, previousPageData) => {
      // First
      if (pageIndex === 0) {
        return `/api/v1/community/categories`
      }

      // End
      if (previousPageData && previousPageData.links.next !== null) {
        return previousPageData.links.next
      }

      return null
    })

  const categories = (Array.isArray(data) ? data : [])
    .flatMap((o) => o.data)
    .filter((item, index, array) => {
      return array.findIndex((o) => o.id === item.id) === index
    })

  const more =
    size > 0 &&
    data !== undefined &&
    data[size - 1] !== undefined &&
    data[size - 1]?.links.next !== null

  const renderItem = ({ item }: ListRenderItemInfo<CommunityChatCategoryResource>) => {
    const src = item.thumbnail

    return (
      <NavigationItem
        title={item.name}
        onPress={() =>
          navigation.navigate(
            'ChattaCommunity',
            Platform.select({
              web: () => ({
                id: item.id,
              }),
              default: () => ({
                id: item.id,
                name: item.name,
              }),
            })(),
          )
        }
        background={
          src ? (
            <View style={styles.navigationItemContainer}>
              <CacheableImage
                source={{ uri: src }}
                style={[styles.navigationItemImage, styles.navigationItemImageWithoutHeader]}
                resizeMode="cover"
                placeholder={
                  <FontAwesomeIcon
                    style={styles.navigationItemPlaceholder}
                    color={theme[400]}
                    size={ms(53)}
                    icon={['fal', 'file-image']}
                  />
                }
              />
              <View
                style={[styles.navigationItemOverlay, styles.navigationItemImageWithoutHeader]}
              />
            </View>
          ) : undefined
        }
        icon={['fal', 'file-image']}
      />
    )
  }

  const renderEmptyList = isValidating ? (
    <View style={styles.emptyContainer}>
      <Loading />
    </View>
  ) : (
    <View style={styles.emptyContainer}>
      <RenderError
        title="Oops, nothing here!"
        description={`No templates have been found.\nWhy not create one and share with the community!`}
      />
    </View>
  )

  return (
    <ChattaCommunitySearch
      navigation={navigation}
      key={`chattacommunitycategorywrapper-${columns}`}
      placeholder={
        categories.length === 0 ? (
          renderEmptyList
        ) : (
          <FlatList<CommunityChatCategoryResource>
            key={`chattacommunitycategory-${columns}`}
            contentContainerStyle={styles.listContainer}
            data={categories}
            numColumns={columns}
            renderItem={renderItem}
            keyExtractor={(item) => item.id}
            ItemSeparatorComponent={() => <View style={styles.itemSeparator} />}
            onEndReached={() => more && setSize(size + 1)}
            onEndReachedThreshold={0.25}
          />
        )
      }
    />
  )
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    listContainer: {
      paddingVertical: '20@mvs',
      paddingHorizontal: '10@ms',
    },
    header: {
      paddingHorizontal: '10@ms',
      paddingBottom: '20@mvs',
      flexDirection: Dimensions.get('window').width < 640 ? 'column-reverse' : 'row',
    },
    emptyContainer: {
      paddingHorizontal: '10@ms',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    headerActions: {
      flexGrow: 1,
      alignItems: Dimensions.get('window').width < 640 ? 'center' : 'flex-end',
    },
    headerActionTextStyle: {
      fontWeight: '600',
    },
    subtitle: {
      fontSize: '12@ms0.4',
      fontWeight: '300',
    },
    navigationItemContainer: {
      flex: 1,
      justifyContent: 'center',
      borderTopLeftRadius: '5@ms',
      borderTopRightRadius: '5@ms',
    },
    navigationItemImage: {
      flex: 1,
      resizeMode: 'cover',
    },
    navigationItemImageWithoutHeader: {
      borderTopLeftRadius: '5@ms',
      borderTopRightRadius: '5@ms',
    },
    navigationItemPlaceholder: {
      alignSelf: 'center',
    },
    navigationItemOverlay: {
      flex: 1,
      backgroundColor: opacity(theme[500], 0.3),
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    createdByChatta: {
      flexDirection: 'row',
      backgroundColor: theme.primary,
      justifyContent: 'center',
      alignItems: 'center',
      borderTopLeftRadius: '5@ms',
      borderTopRightRadius: '5@ms',
      paddingHorizontal: '4@ms',
      paddingVertical: '3@mvs',
    },
    createdByChattaText: {
      fontSize: '9@ms',
      fontWeight: '700',
      color: theme.white,
      marginLeft: '4@ms',
    },
    itemSeparator: {
      height: '16@ms',
    },
  })
