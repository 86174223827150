import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { useNavigation } from '@react-navigation/native'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import { ms, ScaledSheet } from 'react-native-size-matters'
import uuid from 'react-native-uuid'
import { CreatedStudent, StoreStudentResource, Student } from '~api/types'
import { FormButton } from '~components/FormButton'
import { FormInputButton } from '~components/FormInputButton'
import { TextInputProps } from '~components/Themed'
import { ForbiddenError } from '~errors/ForbiddenError'
import usePermission from '~hooks/usePermission'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import { useAxios } from '~utils/fetch'

export interface AddStudentsFormProps extends TextInputProps {
  classId: string
  submitted: (students: CreatedStudent[]) => void
  students_count: number
}

export default function AddStudentsForm({
  classId,
  submitted,
  students_count,
}: AddStudentsFormProps): JSX.Element {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const [name, setName] = useState('')
  const [students, setStudents] = useState<Student[]>([])
  const navigation = useNavigation()
  const permission_add_student = usePermission(
    'restrictions_max_students_per_classes',
    students_count + students.length,
  )

  useEffect(() => {
    setName('')
    setStudents([])
  }, [])

  const addStudent = () => {
    if (permission_add_student.status === 'allowed') {
      setStudents([
        ...students,
        {
          id: uuid.v4(),
          name,
        },
      ])
      setName('')
    } else {
      navigation.navigate('PermissionDeniedModal', {
        message: permission_add_student.message,
      })
    }
  }

  const removeStudent = (id: string) => {
    setStudents(students.filter((student) => student.id !== id))
  }

  const [{ data, loading, error }, execute] = useAxios<StoreStudentResource>(
    {
      url: `/api/v1/groups/${classId}/students`,
      method: 'POST',
    },
    { manual: true },
  )

  const createStudents = async () => {
    if (students.length === 0) {
      return
    }

    try {
      const { status, data: created } = await execute({
        data: {
          students: students.map(({ name }) => ({
            name,
          })), // TODO: Probably send id and name
        },
      })

      if (status === 200) {
        submitted(created.data)
        setStudents([])
      }
    } catch (error) {
      if (error instanceof ForbiddenError) {
        navigation.navigate('PermissionDeniedModal', {
          message: error.message,
        })
      } else {
        console.info(error)
      }
    }
  }

  return (
    <View style={styles.container}>
      <View>
        <FormInputButton
          input={{
            value: name,
            placeholder: 'Full name',
            onChangeText: (changedName: string) => setName(changedName),
          }}
          button={{
            title: 'Add',
            onPress: () => addStudent(),
            disabled: !name,
          }}
        />

        {students.length === 0 ? (
          <Text>Use the form above to start adding students</Text>
        ) : (
          students.map(({ id, name }) => {
            return (
              <View style={styles.listEntryContainer} key={`student-${id}`}>
                <Text style={styles.listEntryTitle}>{name}</Text>
                <TouchableOpacity onPress={() => removeStudent(id)}>
                  <FontAwesomeIcon
                    style={styles.listEntryButton}
                    size={ms(12)}
                    icon={['fal', 'minus-circle']}
                    color={theme[400]}
                  />
                </TouchableOpacity>
              </View>
            )
          })
        )}
      </View>
      <FormButton
        onPress={() => createStudents()}
        title={'Add Students'}
        style={styles.button}
        disabled={students.length === 0 || loading}
      />
    </View>
  )
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    container: {
      flex: 1,
    },
    listEntryContainer: {
      flexDirection: 'row',
      marginBottom: '16@mvs',
      borderBottomColor: theme[200],
      borderBottomWidth: '1@mvs',
    },
    listEntryTitle: {
      flexGrow: 1,
      flexShrink: 1,
      marginBottom: '8@mvs',
      fontSize: '12@ms0.4',
      fontWeight: '300',
    },
    listEntryButton: {
      marginBottom: '8@mvs',
    },
    button: {
      alignSelf: 'flex-end',
      marginBottom: 0,
      marginTop: 'auto',
    },
  })
