import { getDefaultHeaderHeight } from '@react-navigation/elements'
import { CompositeNavigationProp, RouteProp } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Dimensions, FlatList, StatusBar, StyleSheet, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { HeaderButtons } from 'react-navigation-header-buttons'
import ChatSection from '~components/ChatSection'
import { FontAwesomeIconHeaderButton } from '~components/header/HeaderButton'
import { Item } from '~components/header/HeaderItems'
import Loading from '~components/Loading'
import { initialChatSection } from '~services/chat'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import {
  ChatSection as ChatSectionType,
  ChatTemplate,
  CommunityStackParamList,
  IChatLayout,
  RootStackParamList,
} from '~types'
import { useAxios } from '~utils/fetch'

type ChatScreenRouteProp = RouteProp<CommunityStackParamList, 'ChattaCommunityTemplateScreen'>

type Props = {
  navigation: CompositeNavigationProp<
    StackNavigationProp<RootStackParamList>,
    StackNavigationProp<CommunityStackParamList, 'ChattaCommunityTemplateScreen'>
  >
  route: ChatScreenRouteProp
}

export default function ChattaCommunityTemplateScreen({ navigation, route }: Props): JSX.Element {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const [dimensions, setDimensions] = useState(Dimensions.get('window'))
  const insets = useSafeAreaInsets()
  const headerHeight = React.useMemo(
    () => getDefaultHeaderHeight(dimensions, false, insets.top),
    [dimensions, insets],
  )
  const list = useRef<FlatList>()

  const onChange = ({ window }) => {
    setDimensions(window)
  }

  useEffect(() => {
    Dimensions.addEventListener('change', onChange)
    return () => {
      Dimensions.removeEventListener('change', onChange)
    }
  })

  const [{ data: template, loading, error }] = useAxios(
    `/api/v1/community/chats/${route.params.id}`,
  )

  if (error) {
    navigation.goBack()
  }

  const doc: ChatTemplate | undefined = (() => {
    if (template === undefined) {
      return undefined
    }

    const doc: ChatTemplate = {
      id: template.data.id,
      name: template.data.name ?? '',
      ...template.data.document,
    }

    const layout: IChatLayout = {
      rows: doc?.layout?.rows ?? 1,
      columns: doc?.layout?.columns ?? 1,
    }
    const sections: Record<number, ChatSectionType> = {
      ...Array.from(Array(layout.rows * layout.columns).keys()).map((index) => ({
        ...initialChatSection,
        ...(doc?.sections[index] ?? {}),
      })),
    }

    const chat: ChatTemplate = {
      ...doc,
      layout,
      sections,
    }

    return chat
  })()

  const data = Array.from(Array((doc?.layout?.rows || 1) * (doc?.layout?.columns || 1)).keys()).map(
    (index) => {
      return {
        index,
        key: `section_${index}`,
      }
    },
  )

  const small = Dimensions.get('window').width < 480 || Dimensions.get('window').height < 480

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: small ? 'Template' : doc?.name ?? 'Template',
      headerRight: () => (
        <HeaderButtons HeaderButtonComponent={FontAwesomeIconHeaderButton}>
          <Item
            title="Report"
            icon={['far', 'flag']}
            onPress={() =>
              navigation.navigate('ReportCommunityChatModal', {
                id: route.params.id,
              })
            }
          />
          <Item
            title="Use this template"
            icon={['far', 'check']}
            onPress={() => {
              if (doc) {
                navigation.navigate('Main', {
                  screen: 'Classes',
                  params: {
                    screen: 'MyClasses',
                    initial: false,
                    params: {
                      template: doc,
                    },
                  },
                })
              }
            }}
            disabled={doc === undefined}
          />
        </HeaderButtons>
      ),
    })
  }, [navigation, theme, styles, small])

  const displayLayout: IChatLayout = small
    ? {
        rows: 1,
        columns: 1,
      }
    : {
        rows: doc?.layout?.rows ?? 1,
        columns: doc?.layout?.columns ?? 1,
      }

  const chatSectionOptions = useMemo(() => {
    const availableHeight =
      dimensions.height -
      (StatusBar.currentHeight || 0) -
      20 -
      (headerHeight || 0) -
      insets.bottom -
      insets.top

    const heightEach = Math.floor(availableHeight / displayLayout.rows)
    const heightMin = 250

    const height = Math.max(heightEach, heightMin)

    return {
      scrollEnabled: heightEach < heightMin,
      style: {
        flexBasis: 0,
        minHeight: height,
        height,
      },
    }
  }, [dimensions, headerHeight, insets.top, insets.bottom, displayLayout.rows])

  const renderItem = ({ item }) => (
    <ChatSection
      style={chatSectionOptions.style}
      key={item.key}
      index={item.index}
      template={doc?.sections[item.index]}
    />
  )

  if (loading || doc === undefined) {
    return <Loading />
  }

  return (
    <View style={styles.container}>
      <FlatList
        ref={(c) => (list.current = c)}
        key={`chat_${displayLayout.rows}_${displayLayout.columns}`}
        style={styles.chat}
        scrollEnabled={small || chatSectionOptions.scrollEnabled}
        contentContainerStyle={styles.chatContainer}
        numColumns={displayLayout.columns}
        snapToAlignment="start"
        snapToInterval={chatSectionOptions.style.height}
        decelerationRate="fast"
        pagingEnabled
        data={data}
        renderItem={renderItem}
        getItemLayout={(data, index) => ({
          length: chatSectionOptions.style.height,
          offset: chatSectionOptions.style.height * index,
          index,
        })}
      />
    </View>
  )
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      flex: 1,
    },
    chat: {
      flex: 1,
    },
    chatContainer: {
      padding: 10,
      flexGrow: 1,
    },
    navIconsContainer: {
      flexDirection: 'row',
      paddingEnd: 10,
    },
    navIconsButton: {
      marginLeft: 10,
      marginBottom: 0,
    },
    navIconsButtonReport: {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    },
    navTextStyleReport: {
      color: theme[800],
    },
    navIcons: {
      marginRight:
        Dimensions.get('window').width < 480 || Dimensions.get('window').height < 480 ? 0 : 10,
    },
  })
