import React from 'react'
import { Dimensions } from 'react-native'
import { Button, Dialog, Portal } from 'react-native-paper'
import { ScaledSheet } from 'react-native-size-matters'

export interface AlertConfirmationProps {
  visible: boolean
  setVisible: (visible: boolean) => void
  cancelText?: string
  confirmText?: string
  confirmAction?: () => any
  children: React.ReactNode
}

export function AlertConfirmation({
  visible,
  setVisible,
  cancelText,
  confirmText,
  confirmAction,
  children,
}: AlertConfirmationProps): JSX.Element {
  const hideDialog = () => setVisible(false)
  const confirm = () => {
    if (confirmAction !== undefined) {
      confirmAction()
    }
    hideDialog()
  }

  return (
    <Portal>
      <Dialog style={styles.container} visible={visible} onDismiss={hideDialog}>
        {children}
        <Dialog.Actions>
          <Button onPress={hideDialog}>{cancelText ?? 'Cancel'}</Button>
          {confirmAction && <Button onPress={confirm}>{confirmText ?? 'OK'}</Button>}
        </Dialog.Actions>
      </Dialog>
    </Portal>
  )
}

const styles = ScaledSheet.create({
  container: {
    width: Dimensions.get('window').width < 640 ? '80%' : '60%',
    alignSelf: 'center',
  },
})
