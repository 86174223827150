import React, { useState, useMemo } from 'react'
import {
  StyleSheet,
  TouchableOpacity,
  StyleProp,
  ViewStyle,
  TouchableHighlight,
  Platform,
} from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { useSelector, useDispatch, connect } from 'react-redux'
import { View } from '~components/Themed'
import { avatarSize } from '~components/AudioRecording'
import { useTheme } from '~theme/ThemeManager'
import { AudioRecordingType, ChatSection as ChatSectionType } from '~types'
import { CacheableAudioRecording } from '~components/CacheableAudioRecording'
import { ApplicationState } from '~redux'
import { setSelectedObject, setImageAnimation } from '~redux/chat/actions'
import { SetImageAnimationProps } from '~redux/chat/types'
import { MAX_IMAGE_SCALE, MIN_IMAGE_SCALE } from '~constants/Settings'
import { initialChatSection } from '~services/chat'
import useComponentSize from '~hooks/useComponentSize'
import ChatImage from '~components/ChatImage'
import { AvatarImage } from '~components/AvatarImage'
import ProfileService from '~services/profile'
import { Theme } from '~theme'

import {
  Rect,
  Transform,
  transformedRect,
  getTransform,
} from 'react-native-view-transformer-next/library/transform/TransformUtils'
import { useNavigation } from '@react-navigation/native'
import { Role } from '~redux/recent/types'

export interface ChatSectionProps {
  style: StyleProp<ViewStyle>
  index: number // Key in section map
  template?: ChatSectionType
  readonly?: boolean
  role: Role
}

interface OwnProps {
  section: ChatSectionType
  image: string | null
  audioTeacher?: string
  audioStudent?: string
  isRecording: boolean
  isPlaying: boolean
  isTemplate: boolean
  isTeacher: boolean
}

type Props = ChatSectionProps & OwnProps

const createAnimationHOC = (Comp) => {
  class AnimationHOC extends React.Component {
    render() {
      return <Comp {...this.props} />
    }
  }
  const mapStateToProps = (state, props) => ({
    isSelected: !props.isTemplate && state.chat.selectedObject === props.index,
  })
  const mapDispatchToProps = {
    selectImage: setSelectedObject,
  }
  return connect(mapStateToProps, mapDispatchToProps)(AnimationHOC)
}

const AnimatedChatImage = createAnimationHOC(ChatImage)

const ChatSection = ({
  style,
  index,
  section,
  image,
  audioTeacher,
  audioStudent,
  isRecording,
  isPlaying,
  isTemplate,
  isTeacher,
}: Props): JSX.Element => {
  const user = useSelector((state: ApplicationState) => state.auth.user)
  const { chat, selectedObject } = useSelector((state: ApplicationState) => state.chat)
  const dispatch = useDispatch()
  const navigation = useNavigation()
  const [expanded, setExpanded] = useState<null | AudioRecordingType>(null)
  const preventScaleDown = (section.image.scale || 1) <= MIN_IMAGE_SCALE
  const preventScaleUp = (section.image.scale || 1) >= MAX_IMAGE_SCALE
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const [size, onLayout] = useComponentSize()
  const audioTeacherSource = useMemo(
    () => (audioTeacher ? { uri: audioTeacher } : undefined),
    [audioTeacher],
  )
  const audioStudentSource = useMemo(
    () => (audioStudent ? { uri: audioStudent } : undefined),
    [audioStudent],
  )
  const avatarTeacher = useMemo(() => {
    const url = ProfileService.getProfileURL(chat.teacher_id ?? chat.author_id)
    return url
      ? {
          uri: url,
          cache: 'mutable',
        }
      : null
  }, [chat.teacher_id, chat.author_id])
  const avatarStudent = useMemo(() => {
    const url = ProfileService.getProfileURL(
      chat.teacher_id === undefined
        ? undefined
        : user?.claims.sub === chat.author_id
        ? chat.author_id
        : undefined,
    )
    return url
      ? {
          uri: url,
          cache: 'mutable',
        }
      : null
  }, [chat.teacher_id, chat.author_id, user?.claims.sub])

  const changeImageScale = (amount: number) => {
    const currentScale = section.image.scale || 1

    const dx = 0
    const dy = 0

    const scaleBy = (currentScale + amount) / currentScale
    // TODO: Might be better as center of rect
    const pivotX = size.width / 2
    const pivotY = size.height / 2

    const r = new Rect()
    r.set(0, 0, size.width, size.height)

    const rect = transformedRect(
      transformedRect(
        r.copy(),
        new Transform(currentScale, section.image.translateX || 0, section.image.translateY || 0),
      ),
      new Transform(scaleBy, dx, dy, {
        x: pivotX,
        y: pivotY,
      }),
    )
    const transform = getTransform(r.copy(), rect)

    const scale = Math.min(Math.max(transform.scale, MIN_IMAGE_SCALE), MAX_IMAGE_SCALE)

    const clamp = {
      width: 0.2 / scale,
      height: 0.2 / scale,
    }

    const t: SetImageAnimationProps = {
      chat: {
        id: chat.id,
        path: chat.path,
      },
      index,
      scale,
      translateX: Math.min(Math.max(transform.translateX, -clamp.width), clamp.width),
      translateY: Math.min(Math.max(transform.translateY, -clamp.height), clamp.height),
    }

    dispatch(setImageAnimation(t))
  }
  const changeImageScaleUp = () => changeImageScale(0.25)
  const changeImageScaleDown = () => changeImageScale(-0.25)

  const toggleTeacher = () => {
    // If any sections are recording or playing don't allow toggling
    if (isRecording || isPlaying) {
      return
    }

    if (expanded === 'teacher') {
      setExpanded(null)
    } else {
      setExpanded('teacher')
    }
  }

  const toggleStudent = () => {
    // If any sections are recording or playing don't allow toggling
    if (isRecording || isPlaying) {
      return
    }

    if (expanded === 'student') {
      setExpanded(null)
    } else {
      setExpanded('student')
    }
  }

  const avatarPlaceholder = (
    <View style={styles.avatar}>
      <FontAwesomeIcon
        color={theme[400]}
        size={40}
        style={styles.avatarPlaceholder}
        icon={['fas', 'user']}
      />
    </View>
  )

  return (
    <View style={[styles.container, style]} onLayout={onLayout}>
      {image === null ? (
        isTeacher || chat.isAuthor ? (
          <TouchableOpacity
            onPress={() =>
              // Platform.OS === 'web' && !Modernizr.capture
              //   ? openImagePickerAsync()
              //   : setModalMediaSourceVisible(true)
              {
                navigation.navigate('MediaSourceModal', {
                  target: index,
                })
              }
            }>
            <FontAwesomeIcon color={theme.primary} size={112} icon={['fas', 'plus-circle']} />
          </TouchableOpacity>
        ) : null
      ) : (
        <AnimatedChatImage
          index={index}
          image={image}
          containerSize={size}
          scale={section.image.scale}
          translateX={section.image.translateX}
          translateY={section.image.translateY}
          isTemplate={isTemplate}
          updateAnimationData={(
            payload: Pick<SetImageAnimationProps, 'scale' | 'translateX' | 'translateY'>,
          ) => {
            const scale = section.image?.scale !== payload.scale ? payload.scale : undefined
            const translateX =
              Math.abs((section.image.translateX ?? 0) - (payload.translateX ?? 0)) < Number.EPSILON
                ? undefined
                : payload.translateX
            const translateY =
              Math.abs((section.image.translateY ?? 0) - (payload.translateY ?? 0)) < Number.EPSILON
                ? undefined
                : payload.translateY

            if (scale || translateX || translateY) {
              dispatch(
                setImageAnimation({
                  index,
                  chat: {
                    id: chat.id,
                    path: chat.path,
                  },
                  scale,
                  translateX,
                  translateY,
                }),
              )
            }
          }}
        />
      )}

      {Platform.OS === 'web' && selectedObject === index ? (
        <View style={styles.zoomContainer}>
          <TouchableOpacity onPress={changeImageScaleUp} disabled={preventScaleUp}>
            <View style={styles.zoomButton}>
              <FontAwesomeIcon
                color={preventScaleUp ? theme[300] : theme.primary}
                size={22}
                icon={['far', 'plus']}
              />
            </View>
          </TouchableOpacity>
          <View style={styles.zoomSeperator} />
          <TouchableOpacity onPress={changeImageScaleDown} disabled={preventScaleDown}>
            <View style={styles.zoomButton}>
              <FontAwesomeIcon
                color={preventScaleDown ? theme[300] : theme.primary}
                size={22}
                icon={['far', 'minus']}
              />
            </View>
          </TouchableOpacity>
        </View>
      ) : null}

      {(isTeacher || (chat.isAuthor && audioTeacherSource) || audioTeacherSource) && (
        <View style={[styles.containerBase, styles.containerLeft]}>
          <CacheableAudioRecording
            source={audioTeacherSource}
            type="teacher"
            index={index}
            expanded={expanded === 'teacher'}
            readonly={!isTeacher || isTemplate}
          />
        </View>
      )}
      {(isTeacher || chat.isAuthor || audioStudentSource) && (
        <View style={[styles.containerBase, styles.containerRight]}>
          <CacheableAudioRecording
            source={audioStudentSource}
            type="student"
            index={index}
            expanded={expanded === 'student'}
            readonly={isTemplate}
          />
        </View>
      )}
      {(isTeacher || (chat.isAuthor && audioTeacherSource) || audioTeacherSource) && (
        <View style={[styles.containerBase, styles.containerLeft]}>
          <TouchableHighlight
            onPress={toggleTeacher}
            style={styles.avatarContainer}
            activeOpacity={0.6}>
            <AvatarImage
              style={styles.avatar}
              source={avatarTeacher}
              resizeMode="cover"
              placeholder={avatarPlaceholder}
            />
          </TouchableHighlight>
        </View>
      )}
      {(isTeacher || chat.isAuthor || audioStudentSource) && (
        <View style={[styles.containerBase, styles.containerRight]}>
          <TouchableHighlight
            onPress={toggleStudent}
            style={styles.avatarContainer}
            activeOpacity={0.6}>
            <AvatarImage
              style={styles.avatar}
              source={avatarStudent}
              resizeMode="cover"
              placeholder={avatarPlaceholder}
            />
          </TouchableHighlight>
        </View>
      )}
    </View>
  )
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      flex: 1,
      borderColor: theme[200],
      borderWidth: 1,
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
    },
    zoomContainer: {
      position: 'absolute',
      margin: 8,
      top: 0,
      right: 0,
      alignItems: 'flex-end',
      flexDirection: 'column',
      borderRadius: 8,
      borderWidth: 2,
      backgroundColor: theme[200],
      borderColor: theme[300],
    },
    zoomButton: {
      backgroundColor: 'transparent',
      width: 32,
      height: 32,
      padding: 5,
    },
    zoomSeperator: {
      height: 2,
      width: 26,
      backgroundColor: theme[300],
      alignSelf: 'center',
    },
    containerBase: {
      position: 'absolute',
      bottom: 0,
      margin: 8,
      backgroundColor: 'transparent',
    },
    containerLeft: {
      left: 0,
    },
    containerRight: {
      right: 0,
    },
    avatarContainer: {
      borderRadius: avatarSize / 4,
    },
    avatar: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      borderColor: theme[300],
      borderRadius: avatarSize / 4,
      borderWidth: 2,
      width: avatarSize,
      height: avatarSize,
      backgroundColor: theme[200],
    },
    avatarPlaceholder: {
      paddingVertical: 12,
    },
  })

const mapStateToProps = (state: ApplicationState, props: ChatSectionProps) => {
  const isTeacher = props.role === 'teacher'
  const isTemplate = props.template !== undefined
  const isReadOnly = props.readonly
  const section = {
    ...initialChatSection,
    ...((isTemplate || isReadOnly) && props.template !== undefined
      ? props.template
      : state.chat.chat.sections[props.index]),
  }
  const pending = isTemplate ? undefined : state.chat.pending[state.chat.chat.path]

  const image = pending?.image[props.index] ?? section?.image?.src ?? null

  const audioTeacher = pending?.audio[props.index]?.teacher ?? section?.audio?.teacher?.src ?? null

  const audioStudent = pending?.audio[props.index]?.student ?? section?.audio?.student?.src ?? null

  return {
    isTemplate,
    section,
    image: image !== '' ? image : null,
    audioTeacher,
    audioStudent,
    isRecording: state.chat.isRecording,
    isPlaying: state.chat.isPlaying,
    isTeacher,
  }
}

export default connect<ChatSectionProps, OwnProps>(mapStateToProps)(ChatSection)
