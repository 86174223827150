import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'
import 'firebase/remote-config'
import 'firebase/analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyBHAW0lw1tGnUQEh1ISB85mcxvEXffGf1s',
  authDomain: 'chatta-staging-62e57.firebaseapp.com',
  databaseURL: 'https://chatta-staging-62e57.firebaseio.com',
  projectId: 'chatta-staging-62e57',
  storageBucket: 'chatta-staging-62e57.appspot.com',
  messagingSenderId: '838242434436',
  appId: '1:838242434436:web:218a5fc2e3120964cdb1d1',
  measurementId: 'G-KKEFVGGGXH',
}

const app = firebase.initializeApp(firebaseConfig)

export const auth = firebase.auth
export const functions = app.functions.bind(app)
export const firestore = firebase.firestore
export const storage = firebase.storage
export const crashlytics = () => ({
  setUserId: () => {},
})
export const remoteConfig: () => firebase.remoteConfig.RemoteConfig = () =>
  firebase.remoteConfig(app)
export const analytics: () => firebase.analytics.Analytics = () => firebase.analytics(app)
