import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { RouteProp } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React from 'react'
import { ms, ScaledSheet } from 'react-native-size-matters'
import { FormButton } from '~components/FormButton'
import ModalScreen, { getModalStyleDefinition, ModalFooter } from '~components/ModalScreen'
import { Text, View } from '~components/Themed'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import { RootStackParamList } from '~types'

type Props = {
  navigation: StackNavigationProp<RootStackParamList, 'PermissionDeniedModal'>
  route: RouteProp<RootStackParamList, 'PermissionDeniedModal'>
}

export default function PermissionDeniedScreen({
  navigation,
  route: {
    params: { message },
  },
}: Props): JSX.Element {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const dismiss = () => navigation.goBack()

  return (
    <ModalScreen>
      <View style={styles.inner}>
        <View style={[styles.content, styles.contentCenter]}>
          <FontAwesomeIcon
            icon={['fal', 'lock-alt']}
            size={ms(53)}
            color={theme[400]}
            style={styles.button}
          />

          <View style={styles.titleContainer}>
            <Text style={styles.title}>Permission Denied</Text>
            {message && <Text style={styles.subtitle}>{message}</Text>}
          </View>
        </View>

        <ModalFooter>
          <View></View>
          <FormButton onPress={dismiss} title={'Close'} style={styles.button} />
          <View></View>
        </ModalFooter>
      </View>
    </ModalScreen>
  )
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    ...getModalStyleDefinition(theme),
    button: {
      alignSelf: 'center',
    },
    contentCenter: {
      justifyContent: 'center',
    },
  })
