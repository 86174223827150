import * as React from 'react'

import { ScaledSheet } from 'react-native-size-matters'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import { View, Text, TouchableOpacity } from 'react-native'

export interface TabbedMenuProps<T> {
  labels: T[]
  active?: null | T
  onPress?: null | ((index: number) => void)
}

export default function TabbedMenu<T>({
  labels,
  active,
  onPress,
}: TabbedMenuProps<T>): JSX.Element {
  const { theme } = useTheme()
  const styles = getStyles(theme)

  return (
    <View style={styles.labelsContainer}>
      <View style={styles.labels}>
        {labels.map((label, index) => (
          <TouchableOpacity
            key={`link-${index}`}
            onPress={() => onPress && onPress(index)}
            style={[styles.labelWrapper, active === label ? styles.active : {}]}>
            <Text style={styles.label}>{label}</Text>
          </TouchableOpacity>
        ))}
      </View>
    </View>
  )
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    labelsContainer: {
      borderBottomWidth: '2@mvs',
      borderBottomColor: theme[200],
      marginTop: '20@mvs',
    },
    labels: {
      width: '80%',
      flexDirection: 'row',
      alignSelf: 'center',
      marginBottom: '-3@mvs',
    },
    labelWrapper: {
      flexBasis: 'auto',
      paddingHorizontal: '20@ms',
    },
    active: {
      borderBottomWidth: '4@mvs',
      borderBottomColor: theme.primary,
    },
    label: {
      textAlign: 'center',
      fontWeight: '300',
      color: theme[800],
      fontSize: '10@ms0.5',
      textTransform: 'uppercase',
    },
  })
