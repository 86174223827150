import { OfflineAction } from '@redux-offline/redux-offline/lib/types'

export const NOOP = '@@offline/NOOP'

export interface NoopAction extends OfflineAction {
  type: typeof NOOP
  meta: {
    offline: {
      effect: NoopEffect
    }
  }
}

export interface NoopEffect {
  type: typeof NOOP
  key: string
}
