import React, { useState } from 'react'
import {
  StyleSheet,
  TouchableWithoutFeedback,
  ImageBackground,
  View,
  Dimensions,
  Linking,
  Platform,
} from 'react-native'
import { Text } from '~components/Themed'
import { FormButton } from '~components/FormButton'
import ChattaLogo from '~components/ChattaLogo'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import { remoteConfig } from '~providers/firebase'
import { acceptTermsAndConditions } from '~redux/auth'
import { useDispatch } from 'react-redux'

export default function TermsAndConditionsScreen(): JSX.Element {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const dispatch = useDispatch()

  const [dimensions, setDimensions] = useState({
    window: Dimensions.get('window'),
    screen: Dimensions.get('screen'),
  })

  const onChange = ({ window, screen }) => {
    setDimensions({ window, screen })
  }

  React.useEffect(() => {
    Dimensions.addEventListener('change', onChange)
    return () => {
      Dimensions.removeEventListener('change', onChange)
    }
  })

  const backgroundImage = React.useMemo(
    () =>
      Dimensions.get('window').width < 640
        ? require('../assets/images/background-login-mobile.jpg')
        : require('../assets/images/background-login-tablet.jpg'),
    [dimensions],
  )

  const accept = () => dispatch(acceptTermsAndConditions())

  const termsAndConditionsURL = remoteConfig().getString('terms_and_conditions_url')

  return (
    <View style={styles.container}>
      <ImageBackground source={backgroundImage} style={styles.background}>
        <View style={styles.form}>
          <View style={styles.inner}>
            <View>
              <ChattaLogo />
              <View style={styles.titleContainer}>
                <Text style={styles.title}>We've updated our Terms and Conditions</Text>
                <Text style={styles.content}>
                  Please accept the changes to continue using the app.
                </Text>
                <Text style={styles.content}>
                  Visit&nbsp;
                  {Platform.OS === 'web' ? (
                    <a
                      style={{ color: theme.primary, fontWeight: 'bold' }}
                      href={termsAndConditionsURL}
                      target="_blank"
                      rel="noreferrer">
                      {termsAndConditionsURL}
                    </a>
                  ) : (
                    <Text
                      style={styles.link}
                      onPress={() => Linking.openURL(termsAndConditionsURL)}>
                      {termsAndConditionsURL}
                    </Text>
                  )}
                  &nbsp;to view the changes.
                </Text>
              </View>
            </View>
            <TouchableWithoutFeedback>
              <View>
                <FormButton
                  style={styles.button}
                  title={'Accept Terms and Conditions'}
                  onPress={accept}
                />
              </View>
            </TouchableWithoutFeedback>
          </View>
        </View>
      </ImageBackground>
    </View>
  )
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      flex: 1,
    },
    inner: {
      flex: 1,
      alignSelf: Dimensions.get('window').width < 640 ? 'center' : 'flex-start',
      justifyContent: 'center',
      paddingVertical: 30,
      paddingHorizontal: 20,
    },
    form: {
      flex: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
    button: {
      width: Dimensions.get('window').width < 640 ? 280 : 320,
      paddingHorizontal: 10,
      paddingVertical: 15,
    },
    titleContainer: {
      marginTop: 30,
      marginBottom: 40,
    },
    title: {
      fontSize: Dimensions.get('window').width < 640 ? 16 : 20,
      fontWeight: 'bold',
      color: 'white',
      marginBottom: 10,
    },
    content: {
      fontSize: Dimensions.get('window').width < 640 ? 16 : 20,
      color: 'white',
    },
    background: {
      flex: 1,
      width: '100%',
      height: '100%',
      resizeMode: 'cover',
      justifyContent: 'center',
    },
    link: {
      color: theme.primary,
      fontWeight: 'bold',
    },
  })
