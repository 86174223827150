import * as React from 'react'
import { Dimensions, Platform, StyleSheet, Text, View } from 'react-native'
import { HeaderButtonsContext } from 'react-navigation-header-buttons/src/HeaderButtonsContext'
import { ItemProps, VisibleButtonProps } from '~components/HeaderButton'

export function Item(props: ItemProps): React.ReactElement<typeof Item> {
  const HeaderButtonComponent = React.useContext(HeaderButtonsContext)
  return <HeaderButtonComponent {...props} renderButtonElement={renderVisibleButton} />
}

export function renderVisibleButton(
  visibleButtonProps: VisibleButtonProps,
): React.ReactElement<any> {
  const { title, IconComponent, color, icon, iconSize, buttonStyle } = visibleButtonProps

  const small = Dimensions.get('window').width < 480 || Dimensions.get('window').height < 480

  const iconNode =
    IconComponent && icon ? <IconComponent icon={icon} color={color} size={iconSize} /> : null

  const titleNode = title ? <Text style={[styles.text, { color }]}>{title}</Text> : null

  return (
    <View style={[styles.button, buttonStyle]}>
      {iconNode}
      {(!small || !icon) && titleNode}
    </View>
  )
}

const styles = StyleSheet.create({
  text: {
    ...Platform.select({
      android: {
        fontFamily: 'sans-serif-medium',
        fontSize: 14,
        marginHorizontal: 11,
        textTransform: 'uppercase',
      },
      default: {
        fontSize: 17,
        marginHorizontal: 10,
        textTransform: 'capitalize',
      },
    }),
  },
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 11,
  },
})
