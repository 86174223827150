import { CompositeNavigationProp } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React from 'react'
import {
  Dimensions,
  FlatList,
  ListRenderItem,
  ScrollView,
  SectionList,
  TouchableOpacity,
} from 'react-native'
import { ScaledSheet } from 'react-native-size-matters'
import { useSelector } from 'react-redux'
import { ChatNavigationItem } from '~components/ChatNavigationItem'
import { ClassNavigationItem } from '~components/ClassNavigationItem'
import { FormButton } from '~components/FormButton'
import IconJoinClassLarge from '~components/IconJoinClassLarge'
import { Text, View } from '~components/Themed'
import useColumns from '~hooks/useColumns'
import { ApplicationState } from '~redux'
import { RecentChat, RecentClass } from '~redux/recent/types'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import { MainStackParamList, RootStackParamList } from '~types'

type Props = {
  navigation: CompositeNavigationProp<
    StackNavigationProp<RootStackParamList, 'Main'>,
    StackNavigationProp<MainStackParamList, 'Dashboard'>
  >
}

export default function DashboardScreen(props: Props): JSX.Element {
  const { chats, classes } = useSelector((state: ApplicationState) => state.recent)

  return chats.length > 0 || classes.length > 0 ? <Recent /> : <Actions {...props} />
}

const Actions = ({ navigation }: Props) => {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const { profile } = useSelector((state: ApplicationState) => state.auth)

  const joinAClass = () =>
    navigation.navigate('Main', {
      screen: 'Classes',
      params: {
        screen: 'JoinClass',
        initial: false,
      },
    })

  return (
    <ScrollView>
      <View style={styles.container}>
        <Text style={styles.title}>Hello {profile?.displayName}!</Text>
        <Text style={styles.subtitle}>What would you like to do today?</Text>

        <View style={styles.actions}>
          <TouchableOpacity style={styles.actionContainer} onPress={joinAClass}>
            <IconJoinClassLarge />
            <FormButton
              onPress={joinAClass}
              title="Join a class"
              style={styles.actionButton}
              textStyle={styles.actionButtonText}
            />
          </TouchableOpacity>
        </View>
      </View>
    </ScrollView>
  )
}

const Recent = () => {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const { profile } = useSelector((state: ApplicationState) => state.auth)
  const { classes, chats } = useSelector((state: ApplicationState) => state.recent)
  const columns = useColumns()

  const sections = React.useMemo(() => {
    const result = []

    if (classes.length > 0) {
      result.push({ title: 'Recent classes', data: [classes] })
    }
    if (chats.length > 0) {
      result.push({ title: 'Recent chats', data: [chats] })
    }

    return result
  }, [classes, chats])

  const renderHeader = () => (
    <View style={styles.header}>
      <Text style={styles.title}>Welcome back {profile?.displayName}!</Text>
      <Text style={styles.subtitle}>What would you like to do today?</Text>
    </View>
  )

  const renderSection = ({ item, section }) => {
    return (
      <FlatList
        key={`recent-${columns}`}
        numColumns={columns}
        data={item}
        renderItem={section.title === 'Recent classes' ? renderItemClass : renderItemChat}
        ItemSeparatorComponent={() => <View style={styles.itemSeparator} />}
        keyExtractor={(item) => item.path}
      />
    )
  }

  const renderSectionHeader = ({ section }) => {
    return <Text style={styles.sectionTitle}>{section.title}</Text>
  }

  const renderItemChat: ListRenderItem<RecentChat> = ({ item }) => {
    return <ChatNavigationItem chat={item} role={item.role} isDashboard={true} />
  }

  const renderItemClass: ListRenderItem<RecentClass> = ({ item }) => {
    return <ClassNavigationItem name={item.name} id={item.id} role={item.role} isDashboard={true} />
  }

  return (
    <SectionList
      contentContainerStyle={styles.listContainer}
      sections={sections}
      renderItem={renderSection}
      ListHeaderComponent={renderHeader}
      renderSectionHeader={renderSectionHeader}
      ItemSeparatorComponent={() => <View style={styles.itemSeparator} />}
      keyExtractor={(item, index) => `recent-section-${index}`}
      stickySectionHeadersEnabled={false}
    />
  )
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    container: {
      flex: 1,
      paddingVertical: '20@mvs',
      paddingHorizontal: '20@ms',
    },
    title: {
      fontSize: '18@ms',
      fontWeight: '700',
    },
    subtitle: {
      fontSize: '12@ms',
      fontWeight: '300',
    },
    actions: {
      flexDirection: Dimensions.get('window').width < 640 ? 'column' : 'row',
      marginVertical: '10@s',
      width: Dimensions.get('window').width < 640 ? '100%' : '60%',
    },
    actionContainer: {
      flexGrow: 1,
      flexDirection: Dimensions.get('window').width < 640 ? 'row' : 'column',
      justifyContent: Dimensions.get('window').width < 640 ? 'center' : 'flex-end',
      alignItems: 'center',
      alignContent: 'stretch',
      padding: '5@s',
      marginBottom: '5@ms',
    },
    actionButton: {
      flexGrow: 1,
      marginBottom: 0,
      marginStart: Dimensions.get('window').width < 640 ? '20@s' : 0,
      marginTop: Dimensions.get('window').width < 640 ? 0 : '10@mvs',
    },
    actionButtonText: {
      fontWeight: '600',
    },
    // break

    listContainer: {
      paddingVertical: '20@mvs',
      paddingHorizontal: '10@ms',
    },
    header: {
      paddingHorizontal: '10@ms',
    },
    sectionTitle: {
      fontSize: '10@ms',
      fontWeight: '500',
      color: theme[500],
      paddingTop: '20@mvs',
      paddingBottom: '5@mvs',
      paddingHorizontal: '10@ms',
    },
    itemSeparator: {
      height: '16@ms',
    },
  })
