import React, { useState } from 'react'
import { Image, ImageProps } from 'react-native'

type Props = {
  placeholder: JSX.Element
} & ImageProps

const AvatarImage = (props: Props): JSX.Element => {
  const { placeholder, ...filteredProps } = props
  const [error, setError] = useState(false)

  const onError = () => setError(true)

  if (error && placeholder) {
    return placeholder
  }

  return <Image {...filteredProps} onError={onError} />
}

export { AvatarImage }
