import * as React from 'react'
import { mvs } from 'react-native-size-matters'
import Svg, { Circle, Path, Ellipse, G, SvgProps } from 'react-native-svg'

type Props = SvgProps

function IconTeacherLarge(props: Props): JSX.Element {
  return (
    <Svg width={mvs(80, 1.1)} height={mvs(80, 1.1)} viewBox="0 0 130 130">
      <Circle data-name="Ellipse 402" cx={65} cy={65} r={65} fill="#9c99b0" />
      <Circle
        data-name="Ellipse 403"
        cx={63.796}
        cy={63.796}
        r={63.796}
        transform="translate(1.204 1.204)"
        fill="#444155"
      />
      <Circle
        data-name="Ellipse 404"
        cx={60.579}
        cy={60.579}
        r={60.579}
        transform="translate(4.421 4.421)"
        fill="#c7c5d2"
      />
      <Circle
        data-name="Ellipse 405"
        cx={60.579}
        cy={60.579}
        r={60.579}
        transform="translate(4.421 4.421)"
        fill="#c7c5d2"
      />
      <Path
        data-name="Path 931"
        d="M33.094 16.411l-5.084.742c-.551 0 .855-.866 1.3-1.192a60.989 60.989 0 0168.75-1.811c.4.259 1.665 1.125 1.665 1.125l-4.668-.36C80.347 4.421 50.1 2.048 33.094 16.411z"
        fill="#e3e3e5"
      />
      <Path
        data-name="Path 932"
        d="M56.824 5.523a59.684 59.684 0 018.27-.54 58.818 58.818 0 017.559.495"
        fill="none"
        stroke="#efeff4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        data-name="Path 933"
        d="M113.767 28.629a61.083 61.083 0 01-14.052 84.838 60.142 60.142 0 01-84.263-14.151z"
        fill="#b3b2bf"
      />
      <Path
        data-name="Path 934"
        d="M96.226 114.038l5.624-1.192c.562 0-1.384 1.372-1.833 1.7a61.011 61.011 0 01-68.773.81c-.4-.27-2-1.035-1.53-1.125l4.5.439c10.011 9.964 44.802 13.474 62.012-.632z"
        fill="#afafc6"
      />
      <Circle
        data-name="Ellipse 406"
        cx={50.096}
        cy={50.096}
        r={50.096}
        transform="translate(14.904 14.904)"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={0.92}
      />
      <G data-name="Ellipse 3">
        <Circle
          data-name="Ellipse 407"
          cx={51.322}
          cy={51.322}
          r={51.322}
          fill="none"
          transform="translate(13.678 17.244)"
        />
      </G>
      <Circle
        data-name="Ellipse 408"
        cx={54.483}
        cy={54.483}
        r={54.483}
        transform="translate(10.517 10.517)"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
      />
      <Path
        data-name="Path 935"
        d="M91.464 53.911l-1.215 4.871a.225.225 0 01-.439 0l-1.215-4.871-4.871-1.2a.225.225 0 010-.427l4.871-1.226 1.223-4.875a.225.225 0 01.427 0l1.226 4.871 4.859 1.2a.225.225 0 010 .45z"
        fill="#fff"
      />
      <G data-name="Group 210">
        <Path
          data-name="Path 936"
          d="M86.038 48.744a.2.2 0 01.281-.281l1.744 1.395-.124.506-.506.124zm1.9 5.827l.124.506-1.744 1.395a.2.2 0 01-.281-.281l1.395-1.743zm4.207-4.207l-.124-.506 1.744-1.395a.2.2 0 01.281.281l-1.395 1.71zm1.949 5.827a.2.2 0 01-.281.281l-1.744-1.395.125-.506.506-.124z"
          fill="#fff"
          opacity={0.44}
        />
      </G>
      <Path
        data-name="Path 937"
        d="M33.446 61.126l-.776 3.1a.135.135 0 11-.27 0l-.787-3.1-3.1-.776a.146.146 0 010-.281l3.116-.776.776-3.116a.135.135 0 11.27 0l.787 3.116 3.1.776a.146.146 0 010 .281z"
        fill="#fff"
      />
      <G data-name="Group 211">
        <Path
          data-name="Path 938"
          d="M29.976 57.83a.127.127 0 01.18-.18l1.126.889-.079.326-.326.079zm1.215 3.723l.079.315-1.125.9a.135.135 0 01-.18-.191l.889-1.125zm2.691-2.688l-.079-.326 1.125-.889a.127.127 0 11.18.18l-.889 1.125zm1.2 3.712a.127.127 0 01-.18.18l-1.12-.889.079-.315.315-.079z"
          fill="#fff"
          opacity={0.44}
        />
      </G>
      <Path
        data-name="Path 939"
        d="M26.85 56.394l-.45 1.788c0 .079-.135.079-.157 0l-.45-1.788-1.788-.45a.09.09 0 010-.169l1.789-.439.45-1.8c0-.079.146-.079.157 0l.45 1.8 1.799.434a.09.09 0 010 .169z"
        fill="#fff"
      />
      <G data-name="Group 212">
        <Path
          data-name="Path 940"
          d="M24.858 54.493a.072.072 0 11.1-.1l.641.506v.191h-.191zm.7 2.137v.191l-.641.506a.072.072 0 01-.1-.1l.506-.641zm1.541-1.541v-.191l.642-.507a.072.072 0 01.1.1l-.506.641zm.7 2.137a.072.072 0 01-.1.1l-.641-.506v-.191h.183z"
          fill="#fff"
          opacity={0.44}
        />
      </G>
      <Path
        data-name="Path 941"
        d="M80.084 91.315a5.107 5.107 0 01-1.946.4H42.284a4.893 4.893 0 01-4.893-4.893v-4.225a13.7 13.7 0 0113.693-13.689h1.7a17.885 17.885 0 007.446 1.62 17.671 17.671 0 007.4-1.62h1.7c.27 0 .517.067.765.079 0 .247-.112.484-.112.731a27.412 27.412 0 0010.1 21.6zM47.169 52.62a13.037 13.037 0 1113.037 13.026A13.037 13.037 0 0147.169 52.62z"
        fill="#444155"
      />
      <G data-name="Group 213">
        <Path
          data-name="Path 942"
          d="M88.011 92.351c-6.625-2.317-13.892-9.932-14.3-21.293a2.778 2.778 0 011.71-2.6l11.563-4.983a3.149 3.149 0 011.125-.247c.408-.021.25 29.247-.098 29.123z"
          fill="#fff"
          opacity={0.5}
        />
      </G>
      <Path
        data-name="Path 943"
        d="M102.348 70.249c0 12.677-8.3 19.741-13.5 21.777a3.093 3.093 0 01-2.25 0c-6.535-2.553-13.5-10.416-13.5-21.777a2.756 2.756 0 011.8-2.542l11.732-4.578a3.037 3.037 0 011.125-.214 2.981 2.981 0 011.125.214l11.732 4.578a2.756 2.756 0 011.736 2.542zM85.73 66.031l-10.427 4.6c-.169 9.415 4.4 14.825 10.753 18.571zm1.991 22.954zm12.182-18.849l-10.585-4.285.315 23.52c3.971-2.286 11.654-8.886 10.27-19.235z"
        fill="#444155"
      />
    </Svg>
  )
}

export default IconTeacherLarge
