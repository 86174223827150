import { StackNavigationProp } from '@react-navigation/stack'
import { createAction, createReducer } from '@reduxjs/toolkit'
import React, { useEffect, useReducer, useState } from 'react'
import { ScrollView } from 'react-native'
import { Dialog, Paragraph } from 'react-native-paper'
import { ScaledSheet } from 'react-native-size-matters'
import { GroupResource } from '~api/types'
import { AlertConfirmation } from '~components/AlertConfirmation'
import { FormInputButton } from '~components/FormInputButton'
import { Text, View } from '~components/Themed'
import { ValidationError } from '~errors/ValidationError'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import { ClassesStackParamList } from '~types'
import { useAxios } from '~utils/fetch'

type JoinClassScreenNavigationProp = StackNavigationProp<ClassesStackParamList, 'JoinClass'>

type Props = {
  navigation: JoinClassScreenNavigationProp
}

type AlertConfirmationType = 'join-class'

export interface State {
  code: string
  alert?: AlertConfirmationType
}

export const initialState: State = {
  code: '',
}

export const setCode = createAction<string>('setCode')
export const setAlert = createAction<AlertConfirmationType | undefined>('setAlert')
export const reset = createAction('reset')

const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(reset, (state, action) => initialState)
    .addCase(setCode, (state, action) => {
      // Remove whitespace characters from string
      state.code = action.payload.replace(/\s/g, '')
    })
    .addCase(setAlert, (state, action) => {
      state.alert = action.payload
    }),
)

export default function JoinClassScreen({ navigation }: Props): JSX.Element {
  const { mode, theme } = useTheme()
  const styles = getStyles(theme)
  const [state, dispatch] = useReducer(reducer, initialState)
  const [classNameToJoin, setClassNameToJoin] = useState('')

  const [{ data: postData, loading: postLoading, error: postError }, executeJoin] =
    useAxios<GroupResource>(
      {
        url: '/api/v1/groups/join/' + state.code + '/confirm',
        method: 'POST',
      },
      { manual: true },
    )

  const [{ data: getData, loading: getLoading, error: getError }, executeGet] =
    useAxios<GroupResource>(
      {
        url: '/api/v1/groups/join/' + state.code,
        method: 'GET',
      },
      { manual: true },
    )

  const canSubmit = state.code.length >= 6 && !postLoading && !getLoading

  // TODO: Make cancellable/unmount
  const requestJoin = async () => {
    try {
      const { data: response } = await executeJoin()

      navigation.navigate('Main', {
        screen: 'Classes',
        params: {
          screen: 'Class',
          initial: false,
          params: {
            id: response.data.id,
            name: response.data.name,
          },
        },
      })
    } catch (error) {
      console.info(error)
    }
  }

  const join = async () => {
    try {
      const { status, data: response } = await executeGet()

      if (status === 200) {
        setClassNameToJoin(response.data.name)
        dispatch(setAlert('join-class'))
      }
    } catch (error) {
      console.info(error)
    }
  }

  // Reset state on focus
  useEffect(() => navigation.addListener('focus', () => dispatch(reset)), [navigation])

  return (
    <ScrollView>
      <View style={styles.container}>
        <Text style={styles.subtitle}>
          If you have a class invite code use the ‘Enter code’ box below.
        </Text>
        <Text style={styles.subtitle}>
          Alternatively if you don’t have your code handy you can search for a class, by using the
          search function below
        </Text>
        <FormInputButton
          input={{
            value: state.code,
            onChangeText: (text) => dispatch(setCode(text)),
            placeholder: 'Enter class code here',
            autoCorrect: false,
            autoCapitalize: 'characters',
            autoCompleteType: 'off',
            maxLength: 12,
            label: 'Have a code?',
          }}
          button={{
            title: 'Search',
            onPress: join,
            icon: ['far', 'search'],
            disabled: !canSubmit,
          }}
          error={
            getError?.message
              ? getError?.message
              : postError instanceof ValidationError
              ? postError.errors['code']
              : postError?.message
          }
        />
        <AlertConfirmation
          visible={state.alert === 'join-class'}
          setVisible={(visible) => dispatch(setAlert(undefined))}
          confirmAction={requestJoin}>
          <Dialog.Title>Join Class</Dialog.Title>
          <Dialog.Content>
            <Paragraph>Do you want to join the class: {classNameToJoin}?</Paragraph>
          </Dialog.Content>
        </AlertConfirmation>
      </View>
    </ScrollView>
  )
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    container: {
      flex: 1,
      paddingVertical: '20@mvs',
      paddingHorizontal: '20@ms',
      maxWidth: '480@ms',
    },
    subtitle: {
      fontSize: '12@ms0.3',
      fontWeight: '300',
      marginBottom: '5@s',
    },
    formHeader: {
      fontSize: '10@ms0.3',
      fontWeight: '500',
      marginTop: '10@vs',
      marginBottom: '5@s',
      color: theme[500],
    },
    form: {
      flexDirection: 'row',
    },
    formInput: {
      flexGrow: 1,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    formButton: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    formButtonText: {
      fontSize: '12@ms',
      fontWeight: '500',
    },
  })
