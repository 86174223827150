import * as React from 'react'
import { PixelRatio } from 'react-native'
import { ms, s } from 'react-native-size-matters'
import Svg, { G, Path, SvgProps } from 'react-native-svg'
import { useTheme } from '~theme/ThemeManager'

export function ChatLayoutOneByOne(props: SvgProps): JSX.Element {
  const { theme } = useTheme()

  return (
    <Svg width={s(80)} height={s(48)} viewBox="0 0 160 96" {...props}>
      <G
        fill={theme[100]}
        stroke={theme[400]}
        strokeWidth={PixelRatio.getPixelSizeForLayoutSize(2)}>
        <Path stroke="none" d="M0 0h160v96H0z" />
        <Path fill="none" d="M1 1h158v94H1z" />
      </G>
    </Svg>
  )
}

export function ChatLayoutOneByTwo(props: SvgProps): JSX.Element {
  const { theme } = useTheme()

  return (
    <Svg width={s(80)} height={s(48)} viewBox="0 0 160 96" {...props}>
      <G stroke={theme[400]} strokeWidth={PixelRatio.getPixelSizeForLayoutSize(2)}>
        <G fill={theme[100]}>
          <Path stroke="none" d="M0 0h160v96H0z" />
          <Path fill="none" d="M1 1h158v94H1z" />
        </G>
        <Path fill="none" d="M80 0v96" />
      </G>
    </Svg>
  )
}

export function ChatLayoutOneByThree(props: SvgProps): JSX.Element {
  const { theme } = useTheme()

  return (
    <Svg width={s(80)} height={s(48)} viewBox="0 0 160 96" {...props}>
      <G stroke={theme[400]} strokeWidth={PixelRatio.getPixelSizeForLayoutSize(2)}>
        <G fill={theme[100]}>
          <Path stroke="none" d="M0 0h160v96H0z" />
          <Path fill="none" d="M1 1h158v94H1z" />
        </G>
        <Path fill="none" d="M53 0v96M107 0v96" />
      </G>
    </Svg>
  )
}

export function ChatLayoutTwoByTwo(props: SvgProps): JSX.Element {
  const { theme } = useTheme()

  return (
    <Svg width={s(80)} height={s(48)} viewBox="0 0 160 96" {...props}>
      <G stroke={theme[400]} strokeWidth={PixelRatio.getPixelSizeForLayoutSize(2)}>
        <G fill={theme[100]}>
          <Path stroke="none" d="M0 0h160v96H0z" />
          <Path fill="none" d="M1 1h158v94H1z" />
        </G>
        <Path fill="none" d="M80 0v96M0 48h160" />
      </G>
    </Svg>
  )
}

export function ChatLayoutTwoByThree(props: SvgProps): JSX.Element {
  const { theme } = useTheme()

  return (
    <Svg width={s(80)} height={s(48)} viewBox="0 0 160 96" {...props}>
      <G stroke={theme[400]} strokeWidth={PixelRatio.getPixelSizeForLayoutSize(2)}>
        <G fill={theme[100]}>
          <Path stroke="none" d="M0 0h160v96H0z" />
          <Path fill="none" d="M1 1h158v94H1z" />
        </G>
        <Path fill="none" d="M53 0v96M107 0v96M0 48h160" />
      </G>
    </Svg>
  )
}
