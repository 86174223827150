import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { CompositeNavigationProp, RouteProp } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React from 'react'
import { Dimensions, FlatList, ListRenderItemInfo, Text } from 'react-native'
import { ms, ScaledSheet } from 'react-native-size-matters'
import { useSWRInfinite } from 'swr'
import { CommunityChatResource, CommunityChatResourceCollection } from '~api/types'
import { CacheableImage } from '~components/CacheableImage'
import ChattaCommunitySearch, { useSearchContext } from '~components/ChattaCommunitySearch'
import Loading from '~components/Loading'
import { NavigationItem } from '~components/NavigationItem'
import RenderError from '~components/RenderError'
import { View } from '~components/Themed'
import useColumns from '~hooks/useColumns'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import { MainStackParamList, RootStackParamList } from '~types'
import opacity from '~utils/opacity'

type Props = {
  navigation: CompositeNavigationProp<
    StackNavigationProp<RootStackParamList>,
    StackNavigationProp<MainStackParamList, 'ChattaCommunity'>
  >
  route: RouteProp<MainStackParamList, 'ChattaCommunity'>
}

export default function ChattaCommunityScreen({ navigation, route }: Props): JSX.Element {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const [state, stateDispatch] = useSearchContext()
  const columns = useColumns()

  const { data, size, setSize, error, isValidating } =
    useSWRInfinite<CommunityChatResourceCollection>((pageIndex, previousPageData) => {
      // First
      if (pageIndex === 0) {
        const args = new URLSearchParams()

        // facetFilters
        if (state.filterTypeChatta) {
          args.append('facetFilters[type][]', 'chatta')
        }
        if (state.filterTypeCommunity) {
          args.append('facetFilters[type][]', 'community')
        }

        return `/api/v1/community/categories/${route.params.id}/chats?${args.toString()}`
      }

      // End
      if (previousPageData && previousPageData.links.next !== null) {
        return previousPageData.links.next
      }

      return null
    })

  const templates = (Array.isArray(data) ? data : [])
    .flatMap((o) => o.data)
    .filter((item, index, array) => {
      return array.findIndex((o) => o.id === item.id) === index
    })

  const more =
    size > 0 &&
    data !== undefined &&
    data[size - 1] !== undefined &&
    data[size - 1]?.links.next !== null

  React.useLayoutEffect(() => {
    if (data) {
      const name = data[data.length - 1].meta.category_name
      if (name) {
        navigation.setOptions({
          headerTitle: name,
        })
      }
    }
  }, [navigation, data])

  const renderItem = ({ item }: ListRenderItemInfo<CommunityChatResource>) => {
    const src = item.thumbnail

    return (
      <NavigationItem
        title={item.name}
        onPress={() =>
          navigation.navigate('ChattaCommunityTemplateScreen', {
            id: item.id,
          })
        }
        background={
          src ? (
            <View style={styles.navigationItemContainer}>
              <CacheableImage
                source={{ uri: src }}
                style={[
                  styles.navigationItemImage,
                  item.type === 'chatta' ? undefined : styles.navigationItemImageWithoutHeader,
                ]}
                resizeMode="cover"
                placeholder={
                  <FontAwesomeIcon
                    style={styles.navigationItemPlaceholder}
                    color={theme[400]}
                    size={ms(53)}
                    icon={['fal', 'file-image']}
                  />
                }
              />
              <View
                style={[
                  styles.navigationItemOverlay,
                  item.type === 'chatta' ? undefined : styles.navigationItemImageWithoutHeader,
                ]}
              />
            </View>
          ) : undefined
        }
        icon={['fal', 'file-image']}
        header={
          item.type === 'chatta' ? (
            <View style={styles.createdByChatta}>
              <FontAwesomeIcon
                color={theme.white}
                secondaryOpacity={0.7}
                size={ms(16, 0.3)}
                icon={['fad', 'stars']}
              />
              <Text style={styles.createdByChattaText}>Created by Chatta</Text>
            </View>
          ) : undefined
        }
      />
    )
  }

  const renderEmptyList = isValidating ? (
    <View style={styles.emptyContainer}>
      <Loading />
    </View>
  ) : (
    <View style={styles.emptyContainer}>
      <RenderError
        title="Oops, nothing here!"
        description={`No templates have been found.\nWhy not create one and share with the community!`}
      />
    </View>
  )

  return (
    <ChattaCommunitySearch
      navigation={navigation}
      category={route.params.id}
      key={`chattacommunitywrapper-${columns}`}
      placeholder={
        templates.length === 0 ? (
          renderEmptyList
        ) : (
          <FlatList<CommunityChatResource>
            key={`chattacommunity-${columns}`}
            contentContainerStyle={styles.listContainer}
            data={templates}
            numColumns={columns}
            renderItem={renderItem}
            keyExtractor={(item) => item.id}
            ItemSeparatorComponent={() => <View style={styles.itemSeparator} />}
            onEndReached={() => more && setSize(size + 1)}
            onEndReachedThreshold={0.5}
          />
        )
      }
    />
  )
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    listContainer: {
      paddingVertical: '20@mvs',
      paddingHorizontal: '10@ms',
    },
    header: {
      paddingHorizontal: '10@ms',
      paddingBottom: '20@mvs',
      flexDirection: Dimensions.get('window').width < 640 ? 'column-reverse' : 'row',
    },
    emptyContainer: {
      paddingHorizontal: '10@ms',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    headerActions: {
      flexGrow: 1,
      alignItems: Dimensions.get('window').width < 640 ? 'center' : 'flex-end',
    },
    headerActionTextStyle: {
      fontWeight: '600',
    },
    subtitle: {
      fontSize: '12@ms0.4',
      fontWeight: '300',
    },
    navigationItemContainer: {
      flex: 1,
      justifyContent: 'center',
      borderTopLeftRadius: '5@ms',
      borderTopRightRadius: '5@ms',
    },
    navigationItemImage: {
      flex: 1,
      resizeMode: 'cover',
    },
    navigationItemImageWithoutHeader: {
      borderTopLeftRadius: '5@ms',
      borderTopRightRadius: '5@ms',
    },
    navigationItemPlaceholder: {
      alignSelf: 'center',
    },
    navigationItemOverlay: {
      flex: 1,
      backgroundColor: opacity(theme[500], 0.3),
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    createdByChatta: {
      flexDirection: 'row',
      backgroundColor: theme.primary,
      justifyContent: 'center',
      alignItems: 'center',
      borderTopLeftRadius: '5@ms',
      borderTopRightRadius: '5@ms',
      paddingHorizontal: '4@ms',
      paddingVertical: '3@mvs',
    },
    createdByChattaText: {
      fontSize: '9@ms',
      fontWeight: '700',
      color: theme.white,
      marginLeft: '4@ms',
    },
    itemSeparator: {
      height: '16@ms',
    },
  })
