import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { useTheme } from '@react-navigation/native'
import React from 'react'
import {
  Platform,
  StyleProp,
  StyleSheet,
  TouchableWithoutFeedbackProps,
  View,
  ViewStyle,
} from 'react-native'
import { ms } from 'react-native-size-matters'
import TouchableItem from 'react-navigation-header-buttons/src/TouchableItem'

const BUTTON_HIT_SLOP = Object.freeze({ top: 5, bottom: 5, left: 5, right: 5 })

export type VisibleButtonProps = {
  IconComponent?: React.ComponentType<any>
  iconSize?: number
  title?: string
  onPress?: () => any
  icon?: (() => React.ReactNode) | IconProp
  color?: string
  buttonStyle?: StyleProp<ViewStyle>
}

// from <Item />
export type ItemProps = TouchableWithoutFeedbackProps &
  VisibleButtonProps & {
    style?: StyleProp<ViewStyle>
  }

type OtherProps = {
  background?: any
  foreground?: any
  pressColor?: any
  renderButtonElement: (props: VisibleButtonProps) => React.ReactElement
}

export type HeaderButtonProps = ItemProps & OtherProps

export const HeaderButton = (props: HeaderButtonProps): JSX.Element => {
  const { colors, dark } = useTheme()
  const themeColor = Platform.select({
    ios: colors.primary,
    default: colors.text,
  })
  const themePressColorAndroid = dark ? 'rgba(255, 255, 255, .32)' : 'rgba(0, 0, 0, .32)'

  const {
    style,
    title,
    IconComponent,
    icon,
    iconSize = ms(18, 0.25),
    color,
    buttonStyle,
    pressColor,
    onPress,
    renderButtonElement,
    ...other
  } = props

  const usedColor = color || themeColor
  const usedPressColor = pressColor || themePressColorAndroid

  const ButtonElement = renderButtonElement({
    icon,
    title,
    color: usedColor,
    buttonStyle,
    IconComponent,
    iconSize,
  })
  return (
    <TouchableItem
      borderless
      onPress={onPress}
      hitSlop={BUTTON_HIT_SLOP}
      rippleRadius={20}
      style={[styles.buttonContainer, style]}
      pressColor={usedPressColor}
      {...other}>
      <View>{ButtonElement}</View>
    </TouchableItem>
  )
}

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleTextStyle: {
    ...Platform.select({
      android: {
        fontFamily: 'sans-serif-medium',
        fontSize: 14,
        marginHorizontal: 11,
        textTransform: 'uppercase',
      },
      default: {
        fontSize: 17,
        marginHorizontal: 10,
        textTransform: 'capitalize',
      },
    }),
  },
})

export const FontAwesomeIconHeaderButton = (props: HeaderButtonProps): JSX.Element => (
  <HeaderButton IconComponent={FontAwesomeIcon} {...props} />
)
