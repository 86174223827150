import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { CompositeNavigationProp, RouteProp } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { AnyAction, createAction, createReducer } from '@reduxjs/toolkit'
import React, { useEffect, useState } from 'react'
import {
  Dimensions,
  FlatList,
  ListRenderItemInfo,
  SectionList,
  TouchableOpacity,
} from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Dialog, Paragraph } from 'react-native-paper'
import { ms, ScaledSheet } from 'react-native-size-matters'
import uuid from 'react-native-uuid'
import { useSelector } from 'react-redux'
import { cache, mutate as importedMutate, useSWRInfinite } from 'swr'
import { validate } from 'validate.js'
import {
  CreatedStudent,
  GroupResource,
  GroupStudentResourceCollection,
  GroupTeacherResourceCollection,
  StoreTeacherResource,
  Student,
  Teacher,
} from '~api/types'
import AddStudentsForm from '~components/AddStudentsForm'
import { AlertConfirmation } from '~components/AlertConfirmation'
import { FormButton } from '~components/FormButton'
import { FormInput } from '~components/FormInput'
import { FormInputButton } from '~components/FormInputButton'
import { FormSwitch } from '~components/FormSwitch'
import GroupPDFButton from '~components/GroupPDFButton'
import Loading from '~components/Loading'
import ModalScreen, { getModalStyleDefinition, ModalFooter } from '~components/ModalScreen'
import TabbedMenu from '~components/TabbedMenu'
import { Text, View } from '~components/Themed'
import { ValidationError } from '~errors/ValidationError'
import { ApplicationState } from '~redux'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import {
  ClassSettingsSubViewType,
  ClassSettingsViewType,
  DeepRequired,
  MainStackParamList,
  RootStackParamList,
} from '~types'
import { useAxios } from '~utils/fetch'

type Props = {
  navigation: CompositeNavigationProp<
    StackNavigationProp<RootStackParamList, 'ClassSettingsModal'>,
    StackNavigationProp<MainStackParamList>
  >
  route: RouteProp<RootStackParamList, 'ClassSettingsModal'>
}

const initialView = 'details'
const initialSubview = 'list'

type TeacherEntry = {
  id: string
  email: string
}

type RemoveUser = {
  id: string
  name: string
}

type AlertConfirmationType = 'regenerate-code'

export interface State {
  view: ClassSettingsViewType
  subview: ClassSettingsSubViewType
  id: string
  class?: GroupResource
  keyboard: boolean // wrap the modal in a KeyboardAwareScrollView
  alert?: AlertConfirmationType
}

export const initialState: State = {
  view: initialView,
  subview: initialSubview,
  id: '',
  keyboard: true,
}

const labels: ClassSettingsViewType[] = ['details', 'teachers', 'students']

export const setView = createAction<ClassSettingsViewType>('setView')
export const setSubView = createAction<ClassSettingsSubViewType>('setSubView')
export const setClass = createAction<GroupResource>('setClass')
export const setAlert = createAction<AlertConfirmationType | undefined>('setAlert')

const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setView, (state, action) => {
      if (state.view !== action.payload) {
        state.view = action.payload
        state.subview = 'list'
        state.keyboard = action.payload === initialView
      }
    })
    .addCase(setSubView, (state, action) => {
      state.subview = action.payload
      state.keyboard = false
    })
    .addCase(setClass, (state, action) => {
      state.class = action.payload
    })
    .addCase(setAlert, (state, action) => {
      state.alert = action.payload
    }),
)
const store = React.createContext<[State, React.Dispatch<AnyAction>]>([initialState, () => {}])
const { Provider } = store
const useClassSettingsContext = (): [State, React.Dispatch<AnyAction>] => React.useContext(store)

export default function ClassSettingsScreen({ navigation, route: { params } }: Props): JSX.Element {
  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState,
    id: params.id,
    view: params.view ?? initialView,
    subview: params.subview ?? initialSubview,
  })
  const value: [State, React.Dispatch<AnyAction>] = [state, dispatch]

  const [{ data, loading, error }, execute] = useAxios<DeepRequired<GroupResource>>(
    `/api/v1/groups/${state.id}`,
    { manual: true },
  )

  const menu = (active: ClassSettingsViewType) => (
    <TabbedMenu<ClassSettingsViewType>
      labels={labels}
      active={active}
      onPress={(index: number) => dispatch(setView(labels[index]))}
    />
  )

  useEffect(() => {
    let cancel = false

    async function load() {
      try {
        const { status, data: group } = await execute()

        if (cancel) {
          return
        }

        if (status === 200) {
          dispatch(setClass(group))
        }
      } catch (error) {
        console.info(error)
      }
    }

    load()

    return () => {
      cancel = true
    }
  }, [execute])

  const renderActiveTab = () => {
    switch (state.view) {
      case 'teachers':
        return <Teachers />
      case 'students':
        return <Students />
      case 'details':
      default:
        return <Details />
    }
  }

  return (
    <Provider value={value}>
      <ModalScreen ContainerComponent={state.keyboard ? KeyboardAwareScrollView : React.Fragment}>
        {menu(state.view)}
        {loading ? <Loading /> : state.class && renderActiveTab()}
      </ModalScreen>
    </Provider>
  )
}

const Details = () => {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const [state, stateDispatch] = useClassSettingsContext()
  const [name, setName] = useState(state.class?.data.name ?? '')
  const [open, setOpen] = useState(state.class?.data.open ?? false)

  const [{ data, loading, error }, execute] = useAxios<DeepRequired<GroupResource>>(
    {
      url: `/api/v1/groups/${state.id}`,
      method: 'PATCH',
    },
    { manual: true },
  )

  const requestNewCode = async () => {
    try {
      const { status, data: group } = await execute({
        data: {
          code: null,
        },
      })

      if (status === 200) {
        stateDispatch(setClass(group))
      }
    } catch (error) {
      console.info(error)
    }
  }

  const generateNewCode = () => {
    if (state.id !== undefined) {
      stateDispatch(setAlert('regenerate-code'))
    }
  }

  const updateGroup = async () => {
    if (
      (state.class?.data.open === open && state.class?.data.name === name) ||
      name?.length === 0
    ) {
      return
    }

    try {
      const { status, data: group } = await execute({
        data: {
          name,
          open,
        },
      })

      if (status === 200) {
        stateDispatch(setClass(group))
      }
    } catch (error) {
      console.info(error)
    }
  }

  return (
    <View style={styles.inner}>
      <View style={styles.content}>
        <View>
          <FormInput
            label="Name"
            value={name}
            onChangeText={(text) => setName(text)}
            maxLength={128}
            placeholder="Class name"
            error={error instanceof ValidationError ? error.errors['name'] : error?.message}
          />
          <FormInputButton
            input={{
              label: 'Invite code',
              value: state.class?.data.code,
              editable: false,
              style: styles.classCode,
            }}
            button={{
              title: 'Generate new code',
              onPress: () => generateNewCode(),
              disabled: loading,
            }}
          />
          <FormSwitch
            description={{ enabled: 'Open', disabled: 'Closed' }}
            label="New students"
            onValueChange={() => setOpen(!open)}
            value={open}
            error={error instanceof ValidationError ? error.errors['open'] : error?.message}
          />
          <AlertConfirmation
            visible={state.alert === 'regenerate-code'}
            setVisible={(visible) => stateDispatch(setAlert(undefined))}
            confirmAction={requestNewCode}>
            <Dialog.Title>Generate a new code?</Dialog.Title>
            <Dialog.Content>
              <Paragraph>This will change the current class code.</Paragraph>
              <Paragraph>
                Anyone using the current code will no longer be able to join your class.
              </Paragraph>
            </Dialog.Content>
          </AlertConfirmation>
        </View>
      </View>
      <ModalFooter>
        <View></View>
        <FormButton
          onPress={() => updateGroup()}
          title={'Save Changes'}
          style={styles.button}
          disabled={
            (state.class?.data.open === open && state.class?.data.name === name) ||
            name === '' ||
            loading
          }
        />
      </ModalFooter>
    </View>
  )
}

const Teachers = () => {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const [state, dispatch] = useClassSettingsContext()
  const [email, setEmail] = useState('')
  const [emailValidate, setEmailValidate] = useState<{ email: string[] } | undefined>()
  const [teacherEntries, setTeacherEntries] = useState<TeacherEntry[]>([])
  const [inviting, setInviting] = useState(true)
  const user_id = useSelector((state: ApplicationState) => state.auth.user?.claims.sub)
  const [showDeleteTeacherConfirmation, setShowDeleteTeacherConfirmation] = useState(false)
  const [removeUser, setRemoveUser] = useState<RemoveUser>()

  const getKey = (
    pageIndex: number,
    previousPageData: GroupTeacherResourceCollection | null = null,
  ) => {
    // First
    if (pageIndex === 0) {
      return `/api/v1/groups/${state.id}/teachers`
    }

    // End
    if (previousPageData && previousPageData.links.next !== null) {
      return previousPageData.links.next
    }

    return null
  }

  const {
    data,
    size,
    setSize,
    error: getError,
    revalidate,
    mutate,
    isValidating,
  } = useSWRInfinite<GroupTeacherResourceCollection>(getKey)

  const teachers = (data ?? [])
    .flatMap((o) => o.data)
    .filter((item, index, array) => {
      return array.findIndex((o) => o.id === item.id) === index
    })

  const more = !data || (size > 0 && data[size - 1]?.links.next !== null)

  const [{ data: deleteData, loading: deleteLoading, error: deleteError }, executeDelete] =
    useAxios<never>({}, { manual: true })

  const [{ data: postData, loading: postLoading, error: postError }, executePost] =
    useAxios<StoreTeacherResource>(
      {
        url: `/api/v1/groups/${state.id}/teachers`,
        method: 'POST',
      },
      { manual: true },
    )

  const addTeacher = () => {
    setTeacherEntries([
      ...teacherEntries,
      {
        id: uuid.v4(),
        email,
      },
    ])
    setEmail('')
  }

  const removeTeacherEntry = (id: string) => {
    setTeacherEntries(teacherEntries.filter((teacher) => teacher.id !== id))
  }

  const requestTeacherRemovedFromGroup = async () => {
    if (!removeUser || !removeUser.id) {
      return
    }

    try {
      await executeDelete({
        url: `/api/v1/groups/${state.id}/teachers/${removeUser.id}`,
        method: 'DELETE',
        validateStatus: (status) => (status >= 200 && status < 300) || status === 404,
      })

      // Uses SWR internals so check it exists - https://github.com/vercel/swr/issues/1156
      const infiniteKey = ['inf', cache.serializeKey(getKey(0))[0]]
      if (!cache.has(infiniteKey)) {
        console.warn('infiniteKey not found')
        return
      }

      importedMutate(
        infiniteKey,
        (resource: GroupTeacherResourceCollection[]) =>
          resource.map((resource) => ({
            ...resource,
            data: resource.data.filter((o) => o.id !== removeUser.id),
          })),
        false,
      )
    } catch (error) {
      console.info(error)
    }
  }

  const removeTeacher = (user: RemoveUser) => {
    setRemoveUser(user)
    setShowDeleteTeacherConfirmation(true)
  }

  const createTeachers = async () => {
    try {
      const { status, data } = await executePost({
        data: {
          teachers: teacherEntries.map(({ email }) => ({
            email,
          })),
        },
      })

      if (status === 200) {
        setInviting(false)
        if (data.data.invited.length > 0) {
          revalidate()
        }
      }
    } catch (error) {
      console.info(error)
    }
  }

  const renderHeader = () => (
    <>
      {getError && (
        <Text style={styles.error}>
          {getError instanceof ValidationError ? getError.message : 'An error occurred'}
        </Text>
      )}
    </>
  )

  const renderItem = ({ item: { id, name } }: ListRenderItemInfo<Teacher>) => {
    return (
      <View style={styles.listEntryContainer} key={id}>
        <Text style={styles.listEntryTitle}>{name}</Text>
        {user_id !== id && (
          <TouchableOpacity onPress={() => removeTeacher({ id, name })}>
            <FontAwesomeIcon
              style={styles.listEntryButton}
              size={ms(12)}
              icon={['far', 'trash-alt']}
              color={theme[400]}
            />
          </TouchableOpacity>
        )}
      </View>
    )
  }

  const DATA =
    !inviting && postData
      ? [
          ...(postData?.data.ignored.length
            ? [
                {
                  title:
                    'The following emails have recently been sent an invitation, please try again in 5 minutes.',
                  data: postData?.data.ignored.map((o) => o.email) ?? [],
                },
              ]
            : []),
          ...(postData?.data.invited.length
            ? [
                {
                  title:
                    'The following emails have been sent a invitation to become a teacher on the class:',
                  data: postData?.data.invited.map((o) => o.email) ?? [],
                },
              ]
            : []),
        ]
      : []

  const renderEmpty = (
    <View>
      {postData && postData?.data.ignored.length === 0 && postData?.data.invited.length === 0 && (
        <Text style={styles.error}>No changes were made.</Text>
      )}

      {postError && (
        <Text style={styles.error}>
          {postError instanceof ValidationError ? postError.message : 'An error occurred'}
        </Text>
      )}

      <FormInputButton
        input={{
          value: email,
          placeholder: 'Email address',
          keyboardType: 'email-address',
          autoCapitalize: 'none',
          onChangeText: (changedEmail: string) => {
            setEmailValidate(
              validate(
                { email: changedEmail },
                {
                  email: {
                    email: true,
                  },
                },
              ),
            )
            setEmail(changedEmail)
          },
        }}
        button={{
          title: 'Add',
          onPress: () => addTeacher(),
          disabled: !email || emailValidate !== undefined,
        }}
        error={email && emailValidate?.email}
      />

      {teacherEntries.length === 0 ? (
        <>
          <Text>Use the form above to start adding teachers.</Text>
          <Text>
            If any user you add to become a teacher of this group does not exist, we will email them
            an invitation to create an account.
          </Text>
        </>
      ) : (
        teacherEntries.map(({ id, email }) => {
          return (
            <View style={styles.listEntryContainer} key={`teacher-${id}`}>
              <Text style={styles.listEntryTitle}>{email}</Text>
              <TouchableOpacity onPress={() => removeTeacherEntry(id)}>
                <FontAwesomeIcon
                  style={styles.listEntryButton}
                  size={ms(12)}
                  icon={['fal', 'minus-circle']}
                  color={theme[400]}
                />
              </TouchableOpacity>
            </View>
          )
        })
      )}
    </View>
  )

  switch (state.subview) {
    case 'create':
      return (
        <>
          <SectionList
            sections={DATA}
            contentContainerStyle={styles.listContainer}
            keyExtractor={(item, index) => item + index}
            renderItem={({ item }) => <Text style={styles.addedTeacher}>{item}</Text>}
            renderSectionHeader={({ section: { title } }) => (
              <Text style={styles.addedTeacherTitle}>{title}</Text>
            )}
            ListEmptyComponent={renderEmpty}
          />
          <ModalFooter>
            <FormButton
              title="Current Teachers"
              onPress={() => dispatch(setSubView('list'))}
              style={styles.button}
              icon={() => (
                <FontAwesomeIcon
                  color={theme.white}
                  size={ms(12)}
                  icon={['far', 'list']}
                  style={{ marginRight: ms(4) }}
                />
              )}
            />
            {!inviting ? (
              <FormButton
                onPress={() => {
                  setInviting(true)
                  setTeacherEntries([])
                }}
                title={'Add More Teachers'}
                style={styles.button}
              />
            ) : (
              <FormButton
                onPress={() => createTeachers()}
                title={'Add Teachers'}
                style={styles.button}
                disabled={teacherEntries.length === 0 || postLoading}
              />
            )}
          </ModalFooter>
        </>
      )
    case 'list':
    default:
      return (
        <View style={styles.flex}>
          {!data ? (
            <Loading />
          ) : (
            <>
              <FlatList<Teacher>
                key={`classsettings-teacher`}
                contentContainerStyle={styles.listContainer}
                data={teachers}
                ListHeaderComponent={renderHeader}
                renderItem={renderItem}
                keyExtractor={(item) => item.id}
                onEndReached={() => more && setSize(size + 1)}
                onEndReachedThreshold={0.5}
              />
              {showDeleteTeacherConfirmation && (
                <AlertConfirmation
                  visible={showDeleteTeacherConfirmation}
                  setVisible={setShowDeleteTeacherConfirmation}
                  confirmAction={requestTeacherRemovedFromGroup}>
                  <Dialog.Title>Remove Teacher</Dialog.Title>
                  <Dialog.Content>
                    <Paragraph>This will change the current class code.</Paragraph>
                    <Paragraph>{`Are you sure you want to remove ${removeUser?.name} from the class?`}</Paragraph>
                  </Dialog.Content>
                </AlertConfirmation>
              )}
              <ModalFooter>
                <FormButton
                  title="Invite Teachers"
                  onPress={() => dispatch(setSubView('create'))}
                  style={styles.button}
                  icon={() => (
                    <FontAwesomeIcon
                      color={theme.white}
                      size={ms(12)}
                      icon={['far', 'envelope-open']}
                      style={{ marginRight: ms(4) }}
                    />
                  )}
                />
              </ModalFooter>
            </>
          )}
        </View>
      )
  }
}

const Students = () => {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const [state, dispatch] = useClassSettingsContext()
  const [createdStudents, setCreatedStudents] = useState<CreatedStudent[]>()
  const [showDeleteStudentConfirmation, setShowDeleteStudentConfirmation] = useState(false)
  const [removeUser, setRemoveUser] = useState<RemoveUser>()

  const getKey = (
    pageIndex: number,
    previousPageData: GroupTeacherResourceCollection | null = null,
  ) => {
    // First
    if (pageIndex === 0) {
      return `/api/v1/groups/${state.id}/students`
    }

    // End
    if (previousPageData && previousPageData.links.next !== null) {
      return previousPageData.links.next
    }

    return null
  }

  const {
    data,
    size,
    setSize,
    error: getError,
    revalidate,
  } = useSWRInfinite<GroupStudentResourceCollection>(getKey)

  const students = (data ?? [])
    .flatMap((o) => o.data)
    .filter((item, index, array) => {
      return array.findIndex((o) => o.id === item.id) === index
    })

  const more = !data || (size > 0 && data[size - 1]?.links.next !== null)

  const students_count = data && size > 0 ? data[size - 1]?.meta.total : 0

  const [{ loading: deleteLoading, error: deleteError }, executeDelete] = useAxios<never>(
    {},
    { manual: true },
  )

  const requestStudentRemovedFromGroup = async () => {
    if (!removeUser || !removeUser.id) {
      return
    }

    try {
      await executeDelete({
        url: `/api/v1/groups/${state.id}/students/${removeUser.id}`,
        method: 'DELETE',
        validateStatus: (status) => (status >= 200 && status < 300) || status === 404,
      })

      // Uses SWR internals so check it exists - https://github.com/vercel/swr/issues/1156
      const infiniteKey = ['inf', cache.serializeKey(getKey(0))[0]]
      if (!cache.has(infiniteKey)) {
        console.warn('infiniteKey not found')
        return
      }

      importedMutate(
        infiniteKey,
        (resource: GroupStudentResourceCollection[]) =>
          resource.map((resource) => ({
            ...resource,
            data: resource.data.filter((o) => o.id !== removeUser.id),
          })),
        false,
      )
    } catch (error) {
      console.info(error)
    }
  }

  const removeStudent = (user: RemoveUser) => {
    setRemoveUser(user)
    setShowDeleteStudentConfirmation(true)
  }

  const createdStudentsCallback = (students: CreatedStudent[]) => {
    if (students) {
      setCreatedStudents(students)
      revalidate()
    }
  }

  const renderHeader = () => (
    <>
      {getError && (
        <Text style={styles.error}>
          {getError instanceof ValidationError ? getError.message : 'An error occurred'}
        </Text>
      )}
    </>
  )

  const renderItem = ({ item: { id, name } }: ListRenderItemInfo<Teacher>) => {
    return (
      <View style={styles.listEntryContainer} key={id}>
        <Text style={styles.listEntryTitle}>{name}</Text>
        <TouchableOpacity onPress={() => removeStudent({ id, name })} disabled={deleteLoading}>
          <FontAwesomeIcon
            style={styles.listEntryButton}
            size={ms(12)}
            icon={['far', 'trash-alt']}
            color={theme[400]}
          />
        </TouchableOpacity>
      </View>
    )
  }

  switch (state.subview) {
    case 'create':
      return (
        <View style={styles.inner}>
          <View style={styles.content}>
            {createdStudents ? (
              <View>
                {createdStudents.length ? (
                  <View>
                    <View style={styles.titleContainer}>
                      <Text style={styles.title}>Setup Instructions</Text>
                      <Text style={styles.subtitle}>
                        Generate a PDF of instructions for your students
                      </Text>
                    </View>
                    {state.class && (
                      <GroupPDFButton classId={state.class.data.id} students={createdStudents} />
                    )}
                  </View>
                ) : (
                  <Text style={styles.addedTeacherTitle}>No students were created.</Text>
                )}
              </View>
            ) : (
              <View style={styles.flex}>
                <Text style={styles.studentIntroText}>
                  Enter the full names of your students below. An account will be created for each
                  one, with their login details available on the next screen.
                </Text>
                {state.class && (
                  <AddStudentsForm
                    classId={state.class.data.id}
                    submitted={createdStudentsCallback}
                    students_count={students_count}
                  />
                )}
              </View>
            )}
          </View>
          <ModalFooter>
            <FormButton
              title="Current Students"
              onPress={() => dispatch(setSubView('list'))}
              style={styles.button}
              icon={() => (
                <FontAwesomeIcon
                  color={theme.white}
                  size={ms(12)}
                  icon={['far', 'list']}
                  style={{ marginRight: ms(4) }}
                />
              )}
            />
            {createdStudents && (
              <FormButton
                onPress={() => {
                  setCreatedStudents(undefined)
                }}
                title={'Add More Students'}
                style={styles.button}
              />
            )}
          </ModalFooter>
        </View>
      )
    case 'list':
    default:
      return (
        <View style={styles.flex}>
          {!data ? (
            <Loading />
          ) : (
            <>
              <FlatList<Student>
                key={`classsettings-student`}
                contentContainerStyle={styles.listContainer}
                data={students}
                ListHeaderComponent={renderHeader}
                renderItem={renderItem}
                keyExtractor={(item) => item.id}
                onEndReached={() => more && setSize(size + 1)}
                onEndReachedThreshold={0.5}
                ListEmptyComponent={() => (
                  <Text>
                    This class has no students. You can start adding them by using the button above.
                  </Text>
                )}
              />
              {showDeleteStudentConfirmation && (
                <AlertConfirmation
                  visible={showDeleteStudentConfirmation}
                  setVisible={setShowDeleteStudentConfirmation}
                  confirmAction={requestStudentRemovedFromGroup}>
                  <Dialog.Title>Remove Student</Dialog.Title>
                  <Dialog.Content>
                    <Paragraph>This will change the current class code.</Paragraph>
                    <Paragraph>{`Are you sure you want to remove ${removeUser?.name} from the class?`}</Paragraph>
                  </Dialog.Content>
                </AlertConfirmation>
              )}
              <ModalFooter>
                <FormButton
                  title="Add Students"
                  onPress={() => dispatch(setSubView('create'))}
                  style={styles.button}
                  icon={() => (
                    <FontAwesomeIcon
                      color={theme.white}
                      size={ms(12)}
                      icon={['far', 'plus']}
                      style={{ marginRight: ms(4) }}
                    />
                  )}
                />
              </ModalFooter>
            </>
          )}
        </View>
      )
  }
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    ...getModalStyleDefinition(theme),
    classCode: {
      color: theme.primary,
      letterSpacing: 5,
    },
    actions: {
      marginBottom: '16@mvs',
    },
    addedTeacherTitle: {
      fontSize: '12@ms0.4',
      fontWeight: '400',
      marginBottom: '2@mvs',
    },
    addedTeacher: {
      fontSize: '12@ms0.2',
      fontWeight: '300',
      marginBottom: '4@mvs',
    },
    flex: {
      flex: 1,
    },
    studentIntroText: {
      marginBottom: '16@mvs',
    },
    listContainer: {
      paddingVertical: '20@mvs',
      paddingHorizontal: '10@ms',
      width: Dimensions.get('window').width < 640 ? '100%' : '80%',
      alignSelf: 'center',
    },
  })
