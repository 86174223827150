import { createAction } from '@reduxjs/toolkit'
import { ExistingChat, UserWithClaims } from '~types'
import { RecentClass, Role } from './types'

export const addRecentChat = createAction('addRecentChat', (chat: ExistingChat, role: Role) => {
  return {
    payload: {
      path: chat.path,
      name: chat.name,
      thumbnail: Object.values(chat.sections)
        .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
        .map((section) => section.image.src)
        .find((src) => src.length !== 0),
      role,
    },
  }
})
export const removeRecentChat = createAction<string, 'removeRecentChat'>('removeRecentChat')
export const addRecentClass = createAction<RecentClass, 'addRecentClass'>('addRecentClass')
export const removeRecentClass = createAction<string, 'removeRecentClass'>('removeRecentClass')
export const maybeResetRecent = createAction<UserWithClaims, 'maybeResetRecent'>('maybeResetRecent')
