export default function (path: string): {
  classId: string | undefined
  chatId: string | undefined
} {
  // Parse the path
  const regex = /^classes\/(?<classId>[\da-zA-Z]+)\/chats\/(?<chatId>[\da-zA-Z]+)$/
  const matches = path.match(regex)

  // Path is a class document
  if (matches) {
    const classId = matches.groups?.classId
    const chatId = matches.groups?.chatId

    return {
      classId,
      chatId,
    }
  }

  // replies
  const replyMatches = path.match(/^users\/(?<userId>[\da-zA-Z]+)\/chats\/(?<chatId>[\da-zA-Z]+)$/)

  if (replyMatches) {
    const chatId = replyMatches.groups?.chatId

    return {
      classId: undefined,
      chatId,
    }
  }

  return {
    classId: undefined,
    chatId: undefined,
  }
}
