import { CompositeNavigationProp, useFocusEffect } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { AnyAction, createAction, createReducer } from '@reduxjs/toolkit'
import React, { useState } from 'react'
import {
  BackHandler,
  Dimensions,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  TouchableOpacity,
  TouchableWithoutFeedback,
  useWindowDimensions,
  View,
} from 'react-native'
import { ScaledSheet } from 'react-native-size-matters'
import ChattaLogo from '~components/ChattaLogo'
import { FormButton } from '~components/FormButton'
import { FormInput } from '~components/FormInput'
import IconGuardianLarge from '~components/IconGuardianLarge'
import IconStudentLarge from '~components/IconStudentLarge'
import IconTeacherLarge from '~components/IconTeacherLarge'
import SocialSignIn from '~components/SocialSignIn'
import { Text } from '~components/Themed'
import { ValidationError } from '~errors/ValidationError'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import { MainStackParamList, RootStackParamList } from '~types'
import { useAxiosUnauthenticated } from '~utils/fetch'
import opacity from '~utils/opacity'

type ViewType = 'type' | 'details'

type UserType = 'teacher' | 'student' | 'guardian'

export interface State {
  view: ViewType
  type?: UserType
}

export const initialState: State = {
  view: 'type',
}

export const setView = createAction<ViewType, 'setView'>('setView')
export const setType = createAction<UserType, 'setType'>('setType')

const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setView, (state, action) => {
      state.view = action.payload
    })
    .addCase(setType, (state, action) => {
      state.type = action.payload
    }),
)

type Props = {
  navigation: CompositeNavigationProp<
    StackNavigationProp<RootStackParamList>,
    StackNavigationProp<MainStackParamList, 'Register'>
  >
}

const store = React.createContext<[State, React.Dispatch<AnyAction>]>([initialState, () => {}])
const { Provider } = store
const useRegisterContext = (): [State, React.Dispatch<AnyAction>] => React.useContext(store)

const dismissKeyboard = Platform.select({
  web: () => {},
  default: Keyboard.dismiss,
})

const PickType = () => {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const [state, stateDispatch] = useRegisterContext()

  const details = () => stateDispatch(setView('details'))

  return (
    <>
      <Text style={styles.title}>Are you a…</Text>

      <View style={styles.typeOptions}>
        <TouchableOpacity
          style={[
            styles.typeContainer,
            state.type === 'teacher' ? styles.typeContainerSelected : {},
          ]}
          onPress={() => stateDispatch(setType('teacher'))}>
          <View style={styles.typeImageContainer}>
            <IconTeacherLarge />
          </View>
          <Text style={styles.typeLabel}>Teacher</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[
            styles.typeContainer,
            state.type === 'student' ? styles.typeContainerSelected : {},
            styles.typeContainerMiddle,
          ]}
          onPress={() => stateDispatch(setType('student'))}>
          <View style={styles.typeImageContainer}>
            <IconStudentLarge />
          </View>
          <Text style={styles.typeLabel}>Student</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[
            styles.typeContainer,
            state.type === 'guardian' ? styles.typeContainerSelected : {},
          ]}
          onPress={() => stateDispatch(setType('guardian'))}>
          <View style={styles.typeImageContainer}>
            <IconGuardianLarge />
          </View>
          <Text style={styles.typeLabel}>Guardian</Text>
        </TouchableOpacity>
      </View>

      <FormButton
        onPress={details}
        style={styles.button}
        textStyle={styles.buttonText}
        title={'Continue'}
        disabled={state.type === undefined}
        inactiveButtonStyle={styles.inactiveButtonStyle}
      />
    </>
  )
}

const CreateAccount = ({ navigation }: Props) => {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const [state, stateDispatch] = useRegisterContext()

  const [registrationSuccess, setRegistrationSuccess] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')

  const [{ data, loading, error }, execute] = useAxiosUnauthenticated(
    {
      url: '/api/v1/register',
      method: 'POST',
    },
    { manual: true },
  )

  const canSubmit =
    name.length > 0 &&
    email.length > 0 &&
    password.length >= 8 &&
    password === passwordConfirmation &&
    !loading

  const register = async () => {
    try {
      const res = await execute({
        data: {
          name,
          email,
          password,
          password_confirmation: passwordConfirmation,
          type: state.type,
        },
      })

      if (res.status === 200) {
        setRegistrationSuccess(true)
      }
    } catch (error) {
      console.info(error)
    }
  }

  const errorPassword = React.useMemo(() => {
    if (error instanceof ValidationError) {
      return error.errors['password']
    }

    const errors = []

    if (password !== passwordConfirmation) {
      errors.push('The password confirmation does not match.')
    }

    if (password.length < 8) {
      errors.push('The password must be at least 8 characters.')
    }

    return errors.length > 0 ? errors : undefined
  }, [error, password, passwordConfirmation])

  return (
    <>
      {registrationSuccess ? (
        <View>
          <Text style={styles.title}>Account Created</Text>

          <Text style={styles.instructions}>
            Your account has been created. Please tap the button below to log in with the details
            you signed up with.
          </Text>

          <FormButton
            textStyle={styles.buttonText}
            title={'Log in'}
            onPress={() => navigation.navigate('Login')}
          />
        </View>
      ) : (
        <View style={styles.columns}>
          <View>
            <Text style={styles.title}>Create Account</Text>
            <FormInput
              style={styles.input}
              value={name}
              placeholder="Full name"
              onChangeText={setName}
              error={error instanceof ValidationError ? error.errors['name'] : undefined}
            />
            <FormInput
              style={styles.input}
              value={email}
              placeholder="Email"
              onChangeText={setEmail}
              autoCapitalize="none"
              keyboardType="email-address"
              autoCorrect={false}
              error={error instanceof ValidationError ? error.errors['email'] : undefined}
            />
            <FormInput
              style={styles.input}
              value={password}
              placeholder="Password"
              onChangeText={setPassword}
              secureTextEntry={true}
              error={errorPassword}
            />
            <FormInput
              style={styles.input}
              value={passwordConfirmation}
              placeholder="Confirm Password"
              onChangeText={setPasswordConfirmation}
              secureTextEntry={true}
            />

            <FormButton
              style={styles.button}
              textStyle={styles.buttonText}
              title={loading ? 'Loading...' : 'Create account'}
              disabled={!canSubmit}
              onPress={register}
              inactiveButtonStyle={styles.inactiveButtonStyle}
            />
          </View>
          <View style={styles.columnSeparator}></View>
          <View>
            <Text style={styles.title}>Or Sign-in using</Text>
            <SocialSignIn color={theme[400]} seperatorText="Or Sign-in using:" />
          </View>
        </View>
      )}
    </>
  )
}

export default function LoginScreen({ navigation }: Props): JSX.Element {
  const { mode, theme } = useTheme()
  const styles = getStyles(theme)
  const windowDimensions = useWindowDimensions()

  const [state, dispatch] = React.useReducer(reducer, initialState)

  const value: [State, React.Dispatch<AnyAction>] = [state, dispatch]

  const [dimensions, setDimensions] = useState({
    window: Dimensions.get('window'),
    screen: Dimensions.get('screen'),
  })

  const onChange = ({ window, screen }) => {
    setDimensions({ window, screen })
  }

  React.useEffect(() => {
    Dimensions.addEventListener('change', onChange)
    return () => {
      Dimensions.removeEventListener('change', onChange)
    }
  })

  useFocusEffect(
    React.useCallback(() => {
      const onBackPress = () => {
        if (state.view === 'details') {
          dispatch(setView('type'))
          return true
        } else {
          return false
        }
      }

      BackHandler.addEventListener('hardwareBackPress', onBackPress)

      return () => BackHandler.removeEventListener('hardwareBackPress', onBackPress)
    }, [state.view]),
  )

  return (
    <View style={styles.container}>
      <View style={styles.background}></View>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={styles.container}>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <TouchableWithoutFeedback onPress={dismissKeyboard}>
            <View style={styles.form}>
              <View style={styles.inner}>
                {windowDimensions.width > 640 && (
                  <TouchableOpacity onPress={() => navigation.navigate('Login')}>
                    <Text style={styles.backText}>Already using Chatta? Log in here</Text>
                  </TouchableOpacity>
                )}
                <View style={styles.content}>
                  <View style={styles.formBackground}>
                    <View style={styles.logo}>
                      <ChattaLogo solid={true} />
                    </View>
                    <Provider value={value}>
                      {state.view === 'type' ? (
                        <PickType />
                      ) : (
                        <CreateAccount navigation={navigation} />
                      )}
                    </Provider>
                  </View>
                  {windowDimensions.width < 640 && (
                    <View style={styles.signUpBackground}>
                      <TouchableOpacity onPress={() => navigation.navigate('Login')}>
                        <Text style={[styles.loginText]}>Already using Chatta? Log in here</Text>
                      </TouchableOpacity>
                    </View>
                  )}
                </View>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </ScrollView>
      </KeyboardAvoidingView>
    </View>
  )
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    container: {
      flex: 1,
    },
    inner: {
      flex: 1,
      alignSelf: 'center',
      paddingHorizontal: '20@mvs',
      paddingVertical: '20@mvs',
      alignItems: 'center',
    },
    form: {
      flex: 1,
    },
    input: {
      width: '260@ms0.3',
      backgroundColor: theme.white,
    },
    button: {
      width: '260@ms0.3',
      alignSelf: 'center',
    },
    buttonText: {
      color: theme.white,
    },
    title: {
      fontSize: '18@ms',
      fontWeight: '600',
      color: theme.text,
      marginBottom: '10@s',
      textAlign: 'center',
    },
    background: {
      flex: 1,
      position: 'absolute',
      top: Dimensions.get('screen').height * 0.55,
      bottom: 0,
      width: '100%',
      backgroundColor: theme[300],
    },
    cancelButton: {
      textAlign: 'center',
      color: theme.primary,
      paddingVertical: 5,
      marginTop: 15,
    },
    forgotPasswordText: {
      textAlign: 'center',
      color: theme.white,
    },
    formBackground: {
      alignItems: 'center',
      width: Dimensions.get('window').width < 640 ? '300@ms' : '480@ms0.5',
      backgroundColor: theme[100],
      borderTopLeftRadius: '5@ms',
      borderTopRightRadius: '5@ms',
      paddingHorizontal: '20@ms',
      paddingVertical: '10@vs',
      paddingTop: '45@ms0.4',
      elevation: 1,
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowRadius: 4,
      shadowColor: opacity(theme.black, 0.16),
      marginTop: '30@ms0.4',
      borderBottomLeftRadius: Dimensions.get('window').width < 640 ? 0 : '5@ms',
      borderBottomRightRadius: Dimensions.get('window').width < 640 ? 0 : '5@ms',
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'center',
    },
    typeOptions: {
      flexDirection: Dimensions.get('window').width < 640 ? 'column' : 'row',
      marginBottom: '10@s',
      width: '100%',
    },
    typeContainer: {
      flex: 1,
      justifyContent: Dimensions.get('window').width < 640 ? 'flex-start' : 'flex-end',
      alignItems: 'center',
      borderRadius: 5,
      backgroundColor: theme.white,
      borderColor: theme[200],
      borderWidth: 1,
      padding: '10@mvs',
      elevation: 1,
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowRadius: 4,
      shadowColor: opacity(theme.black, 0.16),
      marginBottom: '10@ms',
      flexDirection: Dimensions.get('window').width < 640 ? 'row' : 'column',
    },
    typeImageContainer: {
      marginBottom: Dimensions.get('window').width < 640 ? 0 : '5@s',
    },
    typeContainerMiddle: {
      marginHorizontal: Dimensions.get('window').width < 640 ? 0 : '20@ms',
    },
    typeContainerSelected: {
      borderColor: theme.primary,
    },
    typeLabel: {
      fontWeight: '700',
      marginStart: Dimensions.get('window').width < 640 ? '20@ms' : 0,
    },
    logo: {
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      position: 'absolute',
      top: '-32@ms0.4',
    },
    backText: {
      color: theme.primary,
      fontSize: '12@ms',
      marginBottom: '5@s',
    },
    signUpBackground: {
      width: Dimensions.get('window').width < 640 ? '300@ms' : '480@ms0.5',
      backgroundColor: theme.white,
      borderBottomLeftRadius: '5@ms',
      borderBottomRightRadius: '5@ms',
      fontWeight: '400',
      elevation: 1,
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowRadius: 4,
      shadowColor: opacity(theme.black, 0.16),
    },
    loginText: {
      textAlign: 'center',
      color: theme.primary,
      fontSize: '10@ms0.4',
      fontWeight: '400',
      paddingHorizontal: '8@ms',
      paddingVertical: '8@mvs',
    },
    content: {
      flexGrow: 1,
      justifyContent: 'center',
    },
    instructions: {
      marginBottom: '10@s',
      fontSize: '12@ms0.4',
    },
    error: {
      textAlign: 'center',
      color: theme.primary,
      marginBottom: '10@s',
      fontSize: '12@ms0.4',
    },
    inputDescription: {
      textAlign: 'left',
      marginBottom: '16@mvs',
      fontSize: '11@ms0.4',
      fontStyle: 'italic',
      color: theme[500],
    },
    inactiveButtonStyle: {
      backgroundColor: theme[400],
      borderColor: theme[400],
    },
    columns: {
      flexDirection: Dimensions.get('window').width < 640 ? 'column' : 'row',
    },
    columnSeparator: {
      marginHorizontal: '10@ms',
      width: 1,
      backgroundColor: theme[300],
    },
  })
