import * as React from 'react'
import { ActivityIndicator, StyleSheet } from 'react-native'
import { View } from '~components/Themed'
import { useTheme } from '~theme/ThemeManager'

export default function Loading(): JSX.Element {
  const { theme } = useTheme()

  return (
    <View style={styles.container}>
      <ActivityIndicator size="large" color={theme.primary} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
})
