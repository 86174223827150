import { URL } from 'react-native-url-polyfill'

export type UrlInfo = {
  hostname: string
  filename: string
  basename: string
  extension: string
}

export default (url: string): UrlInfo => {
  let pathname = ''
  let hostname = ''
  try {
    const parsed = new URL(url)
    pathname = parsed.pathname
    hostname = parsed.hostname
  } catch {
    // eslint-disable-line no-empty
  }
  const [basename, extension] = pathname
    .split(/\/|%2F/)
    .pop()
    ?.split('.') ?? ['', '']
  return {
    hostname,
    filename: extension ? `${basename}.${extension}` : basename,
    basename: basename ?? '',
    extension: extension ?? '',
  }
}
