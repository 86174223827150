import { createReducer } from '@reduxjs/toolkit'
import { authStateChanged } from '../auth'
import { addRecentChat, removeRecentChat, addRecentClass, removeRecentClass } from './actions'
import { RecentState } from './types'

const initialState: RecentState = {
  userId: '',
  chats: [],
  classes: [],
}

const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(addRecentChat, (state, action) => {
      const { path, name, role } = action.payload

      if (path === undefined || name === undefined || role === undefined) {
        return
      }

      const shift = state.chats.filter((item) => item.path !== path)

      state.chats = [action.payload, ...shift].slice(0, 4)
    })
    .addCase(removeRecentChat, (state, action) => {
      const path = action.payload
      state.chats = state.chats.filter((item) => item.path !== path)
    })
    .addCase(addRecentClass, (state, action) => {
      const { id, name } = action.payload

      if (id === undefined || name === undefined) {
        return
      }

      const shift = state.classes.filter((item) => item.id !== id)

      state.classes = [action.payload, ...shift].slice(0, 4)
    })
    .addCase(removeRecentClass, (state, action) => {
      const id = action.payload
      state.classes = state.classes.filter((item) => item.id !== id)
    })
    .addCase(authStateChanged, (state, action) => {
      const userId = action.payload?.claims.sub
      if (userId !== undefined && state.userId !== userId) {
        return {
          ...initialState,
          userId: userId,
        }
      }

      return state
    }),
)

export { reducer as recentReducer }
