import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { Audio, AVPlaybackStatus } from 'expo-av'
import React, { useEffect, useRef, useState } from 'react'
import { Platform, StyleSheet, TouchableHighlight } from 'react-native'
import { Dialog, Paragraph } from 'react-native-paper'
import { useDispatch, useSelector } from 'react-redux'
import { Text, View } from '~components/Themed'
import { MAX_RECORDING_LENGTH_MS } from '~constants/Settings'
import { ApplicationState } from '~redux'
import { removeChatAudio, setChatAudio, setIsPlaying, setIsRecording } from '~redux/chat/actions'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import { AudioRecordingType } from '~types'
import { AlertConfirmation } from './AlertConfirmation'

export interface AudioRecordingProps {
  type: AudioRecordingType
  index: number
  source?: { uri: string }
  expanded: boolean
  readonly: boolean
}

export default function AudioRecording({
  type,
  index,
  source,
  expanded,
  readonly,
}: AudioRecordingProps): JSX.Element {
  const { chat, isRecording, isPlaying } = useSelector((state: ApplicationState) => state.chat)
  const dispatch = useDispatch()
  const playback = useRef<null | Audio.Sound>(null)
  const recording = useRef<Audio.Recording | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isLocalRecording, setIsLocalRecording] = useState(false)
  const [isLocalPlaying, setIsLocalPlaying] = useState(false)
  const [isLocalPaused, setIsLocalPaused] = useState(false)
  const [recordingDuration, setRecordingDuration] = useState(0)
  const [soundPosition, setSoundPosition] = useState(0)
  const [isPlaybackAllowed, setIsPlaybackAllowed] = useState(false)
  const { theme } = useTheme()
  const [isRecordingPaused, setIsRecordingPaused] = useState(false)
  const styles = getStyles(theme)
  const detailsStyle = type === 'teacher' ? styles.detailsLeft : styles.detailsRight
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  const hasSource = source !== undefined && source !== null

  const recordingSettings = {
    android: {
      extension: '.m4a',
      outputFormat: Audio.RECORDING_OPTION_ANDROID_OUTPUT_FORMAT_MPEG_4,
      audioEncoder: Audio.RECORDING_OPTION_ANDROID_AUDIO_ENCODER_HE_AAC,
      sampleRate: 44100,
      numberOfChannels: 2,
      bitRate: 64000,
    },
    ios: {
      extension: '.m4a',
      outputFormat: Audio.RECORDING_OPTION_IOS_OUTPUT_FORMAT_MPEG4AAC_HE,
      audioQuality: Audio.RECORDING_OPTION_IOS_AUDIO_QUALITY_MIN,
      sampleRate: 44100,
      numberOfChannels: 2,
      bitRate: 64000,
      linearPCMBitDepth: 16,
      linearPCMIsBigEndian: false,
      linearPCMIsFloat: false,
    },
    web: (() => {
      if (Platform.OS === 'web') {
        const mimes = [
          {
            mimeType: 'audio/mp4;codecs="mp4a.40.5"', // MPEG-4 HE-AAC v1
            audioBitsPerSecond: 64000,
            bitsPerSecond: 64000,
            extension: '.m4a',
          },
          {
            mimeType: 'audio/mp4;codecs="mp4a.40.2"', // MPEG-4 AAC LC
            audioBitsPerSecond: 64000,
            bitsPerSecond: 64000,
            extension: '.m4a',
          },
          {
            mimeType: 'audio/webm;codecs="opus"',
            audioBitsPerSecond: 64000,
            bitsPerSecond: 64000,
            extension: '.webm',
          },
          {
            mimeType: 'audio/webm;codecs="vp8"',
            audioBitsPerSecond: 64000,
            bitsPerSecond: 64000,
            extension: '.webm',
          },
          {
            mimeType: 'audio/webm',
            audioBitsPerSecond: 64000,
            bitsPerSecond: 64000,
            extension: '.webm',
          },
          {
            mimeType: 'audio/mpeg', // Support depends on polyfill
            audioBitsPerSecond: 128000,
            bitsPerSecond: 128000,
            extension: '.mp3',
          },
        ]

        for (let index = 0; index < mimes.length; index++) {
          const mime = mimes[index]

          if ((window as any).MediaRecorder?.isTypeSupported(mime.mimeType)) {
            return mime
          }
        }
      }

      return undefined
    })(),
  }

  const onRecordPressed = async () => {
    if (!isRecording && !isPlaying) {
      if (Platform.OS === 'web' && recordingSettings.web === undefined) {
        alert(
          'Browser is not compatible with audio recording, please ensure you are using Chrome, Firefox, Edge or Safari.',
        )
        return
      }

      const permissionResult = await Audio.requestPermissionsAsync()

      if (permissionResult.granted === false) {
        alert('Permission to record audio is required!')
        return
      }
    }

    if (isLocalRecording && !isRecordingPaused) {
      pauseRecording()
    } else if (isRecordingPaused) {
      resumeRecording()
    } else if (!isRecording) {
      stopPlaybackAndBeginRecording()
    }
  }

  const onPlayPausePressed = async () => {
    if (playback.current) {
      if (isLocalPlaying) {
        setIsLocalPaused(true)
        await playback.current.pauseAsync()
      } else {
        await preparePlayback()
        playback.current.setOnPlaybackStatusUpdate(updateScreenForSoundStatus)
        await playback.current.playAsync()
      }
    }
  }

  const onStopPressed = async () => {
    if (isLocalRecording || isRecordingPaused) {
      await stopRecordingAndEnablePlayback()
    } else if (playback.current) {
      await stopPlayback()
    }
  }

  const trashAction = () => {
    dispatch(
      removeChatAudio({
        chat: { id: chat.id, path: chat.path },
        index,
        type,
      }),
    )
  }

  const preparePlayback = async () => {
    await Audio.setAudioModeAsync({
      allowsRecordingIOS: false,
      interruptionModeIOS: Audio.INTERRUPTION_MODE_IOS_DO_NOT_MIX,
      playsInSilentModeIOS: true,
      shouldDuckAndroid: true,
      interruptionModeAndroid: Audio.INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
      playThroughEarpieceAndroid: false,
      staysActiveInBackground: false,
    })
  }

  const prepareRecording = async () => {
    await Audio.setAudioModeAsync({
      allowsRecordingIOS: true,
      interruptionModeIOS: Audio.INTERRUPTION_MODE_IOS_DO_NOT_MIX,
      playsInSilentModeIOS: true,
      shouldDuckAndroid: true,
      interruptionModeAndroid: Audio.INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
      playThroughEarpieceAndroid: false,
      staysActiveInBackground: false,
    })
  }

  const stopPlaybackAndBeginRecording = async () => {
    setIsLoading(true)

    if (playback.current) {
      await playback.current.unloadAsync()
      playback.current.setOnPlaybackStatusUpdate(null)
      playback.current = null
    }

    if (recording.current) {
      recording.current = null
    }

    await prepareRecording()

    try {
      const newRecording = new Audio.Recording()
      const status = await newRecording.prepareToRecordAsync(recordingSettings)
      if (status.canRecord) {
        newRecording.setOnRecordingStatusUpdate(updateScreenForRecordingStatus)
        recording.current = newRecording

        await newRecording.startAsync() // Will call updateScreenForRecordingStatus to update the screen.
      }
    } catch (e) {
      console.warn(e)
    }

    setIsLoading(false)
  }

  const stopRecordingAndEnablePlayback = async () => {
    if (recording.current) {
      setIsLoading(true)

      try {
        await recording.current.stopAndUnloadAsync()
      } catch (error) {
        // Do nothing -- we are already unloaded.
      }

      setIsLoading(false)

      const uri = recording.current.getURI()
      if (uri) {
        await processAudio(uri)
      }
    }
  }

  /**
   * Abandon the recording
   */
  const stopRecording = async () => {
    if (recording.current) {
      try {
        recording.current.setOnRecordingStatusUpdate(null)
        await recording.current.stopAndUnloadAsync()
      } catch (error) {
        // Do nothing -- we are already unloaded.
      }
    }
  }

  const stopPlayback = async () => {
    if (playback.current) {
      try {
        await playback.current.stopAsync()
        playback.current.setOnPlaybackStatusUpdate(null)
      } catch (error) {
        // Do nothing -- we are already unloaded.
      }
    }
  }

  const pauseRecording = async () => {
    setIsLoading(true)

    if (recording.current) {
      try {
        await recording.current.pauseAsync()
        setIsRecordingPaused(true)
      } catch (error) {
        setIsRecordingPaused(false)
      }
    }

    setIsLoading(false)
  }

  const resumeRecording = async () => {
    setIsLoading(true)

    if (recording.current) {
      try {
        await recording.current.startAsync()
      } catch (error) {
        // Ignore
      }
      setIsRecordingPaused(false)
    }

    setIsLoading(false)
  }

  const processAudio = async (uri: string) => {
    const extension =
      Platform.OS === 'web'
        ? recordingSettings.web.extension
        : Platform.OS === 'android'
        ? recordingSettings.android.extension
        : recordingSettings.ios.extension

    dispatch(
      setChatAudio({
        chat: { id: chat.id, path: chat.path },
        index,
        uri,
        extension,
        type,
      }),
    )
  }

  const updateScreenForSoundStatus = React.useCallback(
    (status: AVPlaybackStatus) => {
      if (!playback.current) {
        return
      }

      if (status.isLoaded) {
        setSoundPosition(status.positionMillis)
        setIsLocalPlaying(status.isPlaying)
        dispatch(setIsPlaying(status.isPlaying))
        setIsPlaybackAllowed(true)
        if (status.didJustFinish) {
          setSoundPosition(0)
          setIsLocalPaused(false)
          playback.current?.stopAsync()
        }
      } else {
        setSoundPosition(0)
        setIsPlaybackAllowed(false)

        if (status.error) {
          console.log(`FATAL PLAYER ERROR: ${status.error}`)
        }
      }
    },
    [dispatch],
  )

  const updateScreenForRecordingStatus = (status: Audio.RecordingStatus) => {
    if (!recording.current) {
      return
    }

    if (status.canRecord) {
      setIsLocalRecording(status.isRecording || status.durationMillis > 0)
      dispatch(setIsRecording(status.isRecording || status.durationMillis > 0))
      setRecordingDuration(status.durationMillis)

      // Enforce max recording length
      if (status.durationMillis >= MAX_RECORDING_LENGTH_MS && status.isRecording) {
        stopRecordingAndEnablePlayback()
      }
    } else if (status.isDoneRecording) {
      setIsLocalRecording(false)
      dispatch(setIsRecording(false))
      setRecordingDuration(status.durationMillis)
    }
  }

  const getMMSSFromMillis = (millis: number) => {
    const totalSeconds = millis / 1000
    const seconds = Math.floor(totalSeconds % 60)
    const minutes = Math.floor(totalSeconds / 60)

    const padWithZero = (number: number) => {
      const string = number.toString()
      if (number < 10) {
        return `0${string}`
      }
      return string
    }
    return `${padWithZero(minutes)}:${padWithZero(seconds)}`
  }

  const getRecordingTimestamp = () => {
    if (recordingDuration) {
      return `${getMMSSFromMillis(recordingDuration)}`
    }
    return `${getMMSSFromMillis(0)}`
  }

  const getSoundTimestamp = () => {
    if (soundPosition) {
      return `${getMMSSFromMillis(soundPosition)}`
    }
    return `${getMMSSFromMillis(0)}`
  }

  useEffect(() => {
    let didCancel = false

    async function load() {
      if (!didCancel) {
        setIsLoading(true)
      }

      if (playback.current) {
        playback.current.setOnPlaybackStatusUpdate(null)
        await playback.current.unloadAsync()
        playback.current = null
        if (!didCancel) {
          setSoundPosition(0)
          setIsPlaybackAllowed(false)
        }
      }

      if (source && !didCancel) {
        try {
          const { sound, status } = await Audio.Sound.createAsync(
            { uri: source.uri },
            {},
            updateScreenForSoundStatus,
            false,
          )
          if (status.isLoaded) {
            if (didCancel) {
              sound.setOnPlaybackStatusUpdate(null)
              await sound.unloadAsync()
            } else {
              playback.current = sound
            }
          }
        } catch (error) {
          // An error occurred!
          console.warn(error)
        }
      }

      if (!didCancel) {
        setIsLoading(false)
      }
    }

    // Prepare the sound for playback
    load()

    return () => {
      didCancel = true
    }
  }, [source, updateScreenForSoundStatus])

  useEffect(() => {
    return () => {
      // Stop any recordings
      if (recording.current) {
        stopRecording()
      }

      // Stop playback?
      if (playback.current) {
        playback.current.setOnPlaybackStatusUpdate(null)
        stopPlayback()
      }
    }
  }, [recording, playback])

  if (!expanded) {
    return <View />
  }

  return (
    <View style={styles.containerDetails}>
      <View style={detailsStyle}>
        {/* TODO: reuse same button */}
        {!(isPlaybackAllowed || hasSource) && (
          <TouchableHighlight
            onPress={onRecordPressed}
            style={styles.button}
            disabled={
              readonly ||
              isLoading ||
              isPlaying ||
              !(isLocalRecording || !isRecording || isRecordingPaused)
            }>
            <View style={styles.buttonBackground}>
              <FontAwesomeIcon
                color={
                  readonly || isPlaying || !(isLocalRecording || !isRecording || isRecordingPaused)
                    ? theme[300]
                    : theme.primary
                }
                size={22}
                icon={
                  isLocalRecording && !isRecordingPaused ? ['fas', 'pause'] : ['fas', 'microphone']
                }
              />
            </View>
          </TouchableHighlight>
        )}
        {(isPlaybackAllowed || hasSource) && (
          <TouchableHighlight
            onPress={onPlayPausePressed}
            style={styles.button}
            disabled={isLoading || isRecording || !(isLocalPlaying || !isPlaying)}>
            <View style={styles.buttonBackground}>
              <FontAwesomeIcon
                color={isRecording || !(isLocalPlaying || !isPlaying) ? theme[300] : theme.primary}
                size={22}
                icon={isLocalPlaying ? ['fas', 'pause'] : ['fas', 'play']}
              />
            </View>
          </TouchableHighlight>
        )}
        {(isLocalRecording || isPlaybackAllowed || hasSource || isRecordingPaused) && (
          <Text style={styles.timestamp}>
            {isLocalPlaying || isLocalPaused ? getSoundTimestamp() : getRecordingTimestamp()}
          </Text>
        )}
        {(isLocalRecording || isPlaybackAllowed || hasSource || isRecordingPaused) && (
          <TouchableHighlight
            onPress={onStopPressed}
            style={styles.button}
            disabled={isLoading || !(isLocalPlaying || isLocalPaused || isLocalRecording)}>
            <View style={styles.buttonBackground}>
              <FontAwesomeIcon
                color={
                  !(isLocalPlaying || isLocalPaused || isLocalRecording)
                    ? theme[300]
                    : theme.primary
                }
                size={22}
                icon={['fas', 'stop']}
              />
            </View>
          </TouchableHighlight>
        )}
        {(isLocalRecording || isPlaybackAllowed || hasSource || isRecordingPaused) && (
          <TouchableHighlight
            onPress={() => setShowDeleteConfirmation(true)}
            style={[styles.button, { marginLeft: 8 }]}
            disabled={
              readonly || isLoading || isPlaying || isRecording || !(isPlaybackAllowed || hasSource)
            }>
            <View style={styles.buttonBackground}>
              <FontAwesomeIcon
                color={
                  readonly || isPlaying || isRecording || !(isPlaybackAllowed || hasSource)
                    ? theme[300]
                    : theme.primary
                }
                size={22}
                icon={['far', 'trash-alt']}
              />
            </View>
          </TouchableHighlight>
        )}
        {showDeleteConfirmation && (
          <AlertConfirmation
            visible={showDeleteConfirmation}
            setVisible={setShowDeleteConfirmation}
            confirmAction={trashAction}>
            <Dialog.Title>Delete audio recording?</Dialog.Title>
            <Dialog.Content>
              <Paragraph>Are you sure you want to delete this audio recording?</Paragraph>
            </Dialog.Content>
          </AlertConfirmation>
        )}
      </View>
    </View>
  )
}

export const avatarSize = 64

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    detailsLeft: {
      borderWidth: 1,
      borderColor: theme[200],
      paddingHorizontal: 8,
      marginLeft: avatarSize / 2,
      paddingLeft: avatarSize / 2 + 8,
      height: avatarSize,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderTopRightRadius: avatarSize / 3,
      borderBottomRightRadius: avatarSize / 3,
      flexDirection: 'row',
      backgroundColor: theme[100],
    },
    detailsRight: {
      borderWidth: 1,
      borderColor: theme[200],
      paddingHorizontal: 8,
      marginRight: avatarSize / 2,
      paddingRight: avatarSize / 2 + 8,
      height: avatarSize,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderTopLeftRadius: avatarSize / 3,
      borderBottomLeftRadius: avatarSize / 3,
      flexDirection: 'row',
      backgroundColor: theme[100],
    },
    containerDetails: {
      backgroundColor: 'transparent',
    },
    button: {
      borderRadius: 24,
    },
    buttonBackground: {
      borderRadius: 24,
      borderWidth: 1,
      padding: 8,
      borderColor: theme[300],
      backgroundColor: theme[200],
    },
    timestamp: {
      fontVariant: ['tabular-nums'],
      paddingHorizontal: 8,
    },
  })
