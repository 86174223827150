import * as React from 'react'

import { s, ScaledSheet } from 'react-native-size-matters'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import { TextInput, TextInputProps } from '~components/Themed'
import { View, Text } from 'react-native'

export interface ProgressProps extends TextInputProps {
  labels: string[]
  step?: number
}

export default function Progress({ labels, step }: ProgressProps): JSX.Element {
  const { theme } = useTheme()
  const styles = getStyles(theme)

  const percentage = Math.min(Math.max(step ?? 0, 0), labels.length - 1) / (labels.length - 1)

  return (
    <View>
      <View style={styles.labelsContainer}>
        {labels.map((label, index) => (
          <Text
            key={`progress-${index}`}
            style={[
              styles.label,
              index === 0 ? styles.labelFirst : index >= labels.length - 1 ? styles.labelLast : {},
            ]}>
            {label}
          </Text>
        ))}
      </View>
      <View style={styles.track}>
        <View style={[styles.trackFilled, { flex: percentage }]} />
        <View style={[styles.trackNotFilled, { flex: 1 - percentage }]} />
        <View style={[styles.circle, { left: `${percentage * 100}%` }]} />
      </View>
    </View>
  )
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    labelsContainer: {
      flexDirection: 'row',
      width: '100%',
    },
    label: {
      flex: 1,
      fontWeight: '300',
      color: theme[800],
      fontSize: '8@ms0.5',
      textAlign: 'center',
    },
    labelFirst: {
      textAlign: 'left',
    },
    labelLast: {
      textAlign: 'right',
    },
    track: {
      position: 'relative',
      alignItems: 'center',
      flexDirection: 'row',
      height: '10@s',
      marginHorizontal: '5@s',
    },
    trackFilled: {
      height: '4@mvs',
      backgroundColor: theme.pink['700'],
      borderTopLeftRadius: '2@mvs',
      borderBottomLeftRadius: '2@mvs',
    },
    trackNotFilled: {
      height: '4@mvs',
      backgroundColor: theme[200],
      borderTopRightRadius: '2@mvs',
      borderBottomRightRadius: '2@mvs',
    },
    circle: {
      position: 'absolute',
      width: '10@s',
      height: '10@s',
      marginLeft: '-5@s',
      borderRadius: '16@s',
      backgroundColor: theme.pink['700'],
    },
  })
