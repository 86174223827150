import * as React from 'react'

import { ms, ScaledSheet } from 'react-native-size-matters'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import { Text, View } from 'react-native'
import { FormInput, FormInputProps } from '~components/FormInput'
import { FormButton, FormButtonProps } from '~components/FormButton'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export interface FormInputButtonProps {
  input: FormInputProps
  button: Omit<FormButtonProps, 'icon'> & {
    icon?: IconProp
  }
  error?: (() => React.ReactNode) | string | string[]
}

export function FormInputButton({ input, button, error }: FormInputButtonProps): JSX.Element {
  const { mode, theme } = useTheme()
  const styles = getStyles(theme)

  const { label, style: inputStyle, ...inputOther } = input
  const { icon, style: buttonStyle, textStyle: buttonTextStyle, ...buttonOther } = button

  const labelNode = React.useMemo(
    () =>
      label ? (
        typeof label === 'string' ? (
          <Text style={styles.label}>{label}</Text>
        ) : (
          label()
        )
      ) : null,
    [label, styles.label],
  )

  const errorNode = React.useMemo(() => {
    return error ? (
      typeof error === 'string' || Array.isArray(error) ? (
        <Text style={styles.error}>{[error].flat().join('\n')}</Text>
      ) : (
        error()
      )
    ) : null
  }, [error, styles.error])

  const iconNode = () =>
    icon ? (
      <FontAwesomeIcon
        size={ms(12)}
        icon={icon}
        style={{
          color: button.disabled ? theme.white : mode === 'dark' ? theme.text : theme[100],
          marginRight: ms(4),
        }}
      />
    ) : null

  return (
    <>
      {labelNode}
      <View style={[styles.form, error ? styles.formError : null]}>
        <FormInput style={[styles.formInput, inputStyle]} {...inputOther} />
        <FormButton
          icon={iconNode}
          style={[styles.formButton, buttonStyle]}
          textStyle={[styles.formButtonText, buttonTextStyle]}
          {...buttonOther}
        />
      </View>
      {errorNode}
    </>
  )
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    form: {
      flexDirection: 'row',
      marginBottom: '16@mvs',
    },
    formInput: {
      flexGrow: 1,
      flexShrink: 1,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      marginBottom: 0,
    },
    formButton: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      marginBottom: 0,
    },
    formButtonText: {
      flexShrink: 0,
      fontSize: '12@ms',
      fontWeight: '500',
    },
    label: {
      fontSize: '10@ms0.3',
      fontWeight: '500',
      marginBottom: '3@mvs',
      color: theme[500],
    },
    formError: {
      marginBottom: '1@mvs',
    },
    error: {
      fontSize: '10@ms0.3',
      fontWeight: '500',
      marginBottom: '4@mvs',
      color: theme.pink[400],
    },
  })
