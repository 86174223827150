import { AxiosResponse } from 'axios'

export class ForbiddenError extends Error {
  public readonly name = 'ForbiddenError'

  constructor(public response: AxiosResponse<any>) {
    super(response.data.message)
    Object.setPrototypeOf(this, ForbiddenError.prototype)
  }
}
