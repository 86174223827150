import { AnyAction, createAction, createReducer } from '@reduxjs/toolkit'
import React from 'react'

export interface State {
  classId: string
  name: string
}

export const initialState = {
  classId: '',
  name: '',
}

export const setName = createAction<string, 'setName'>('setName')

export const reducer = createReducer(initialState, (builder) =>
  builder.addCase(setName, (state, action) => {
    state.name = action.payload
  }),
)

const store = React.createContext<[State, React.Dispatch<AnyAction>]>([initialState, () => {}])
export const { Provider } = store

export const useClassTabsContext = (): [State, React.Dispatch<AnyAction>] => React.useContext(store)
